import { useState, useEffect } from "react";
import { Form, Input, Modal, Button, message, Row, Select, InputNumber, Col, Switch } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addCompanyAsync } from "../../reducers/companies/companiesSlice";
import config from "../../config/config";
import { getBaseSettingAsync } from "../../reducers/setting/settingSlice";
import { getAllCompaniesAsync } from "../../reducers/companies/companiesSlice";
import { selectUser } from "../../reducers/account/accountSlice";
import { useTranslation } from "react-i18next";
import mobileCountryCodes from "../../config/mobileCountryCodes";
import AddressSelect from "../Components/AddressSelect";

import "./Common.scss";
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const { Option } = Select;

const CompanyModal = (props: propsObject) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [useInventory, setUseInventory] = useState(false);
  const [publicPlans, setPublicPlans] = useState(false);
  const [customerNotifications, setCustomerNotifications] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [type, setType] = useState("");
  const [dbs, setDbs] = useState([]);
  const [form] = Form.useForm();
  const [address, setAddress] = useState(null as any);
  const [center, setCenter] = useState({
    lat: -33.851923390266165,
    lng: 151.21838655370252,
  });
  const [mobileCountryCode, setMobileCountryCode] = useState("");

  const selectBefore = (
    <Select value={mobileCountryCode} onChange={(value: string) => setMobileCountryCode(value)} className="select-before">
      {mobileCountryCodes.map((code: any) => (
        <Option key={code.code} value={code.code}>
          {code.code}
        </Option>
      ))}
    </Select>
  );

  const closeModal = () => {
    props.setVisible(false);
    props.getList();
  };

  const fetchList = async () => {
    setLoading(true);
    const result = await dispatch(getAllCompaniesAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setCompanies(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        values.id = props.record._id;
        if (address) {
          values.address = address.label;
        }
        values.useInventory = values.useInventory || useInventory;
        values.publicPlans = values.publicPlans || publicPlans;
        values.customerNotifications = values.customerNotifications || customerNotifications;
        values.owner_mobileCountryCode = mobileCountryCode;
        values.location = center;
        if (!address || !address.label) {
          if (props.record.address) {
            values.address = props.record.address;
          } else {
            return message.error("Please select address");
          }
        }
        const result = await dispatch(addCompanyAsync(values));
        setLoading(false);
        const { payload } = result;
        if (payload) {
          const { code, msg } = payload;
          if (code === 0) {
            message.success("Successful operation");
            closeModal();
          } else if (msg) {
            Modal.error({
              title: "Error",
              content: msg,
            });
          }
        }
        if (typeof payload === "string") {
          Modal.error({
            title: "Error",
            content: payload,
          });
        }
        /*
         */
      })
      .catch((errorInfo) => {});
  };
  const fetchSetting = async () => {
    setLoading(true);
    const result = await dispatch(getBaseSettingAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setDbs(data.dburls);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  useEffect(() => {
    form.resetFields();
    setUseInventory(true);
    setPublicPlans(true);
    setCustomerNotifications(true);
    setAddress("Search address");
    setCenter({
      lat: -33.851923390266165,
      lng: 151.21838655370252,
    });
    if (user.type !== "admin" && user.companyId) {
      form.setFieldsValue({
        parentCompanyId: user.companyId,
        databaseUrl: user.company.databaseUrl,
      });
      if (user.useInventory === false) {
        setUseInventory(false);
        form.setFieldsValue({
          useInventory: false,
        });
      }
      if (user.publicPlans === false) {
        setPublicPlans(false);
        form.setFieldsValue({
          publicPlans: false,
        });
      }
      if (user.customerNotifications === false) {
        setCustomerNotifications(false);
        form.setFieldsValue({
          customerNotifications: false,
        });
      }
      config.countries.map((cou: any) => {
        if (cou.key === user.company.country) {
          form.setFieldValue("owner_mobileCountryCode", cou.mobileCountryCode);
        }
      });
    }
    if (props.record._id) {
      setType(props.record.type || "");
      setMobileCountryCode(props.record.owner_mobileCountryCode || "");
      setUseInventory(props.record.useInventory || false);
      setPublicPlans(props.record.publicPlans || false);
      setCustomerNotifications(props.record.customerNotifications || false);
      form.setFieldsValue(props.record);
      props.record.location && setCenter(props.record.location);
      props.record.address && setAddress(props.record.address);
    }
    fetchList();
    fetchSetting();
  }, [props.record._id, props.visible]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width="1200px"
          title={props.record._id ? t("work.Edit_company") : t("work.Create_company")}
          onCancel={closeModal}
          footer={[
            <Button
              htmlType="submit"
              key="submit"
              disabled={user.userType !== "admin" && user.type !== "admin"}
              loading={loading}
              type="primary"
              onClick={onFinish}
            >
              {t("work.Save")}
            </Button>,
            <Button key="back" onClick={closeModal}>
              {t("work.Cancel")}
            </Button>,
          ]}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item rules={[{ required: true, message: t("work.Country_Text") }]} name="country" label={t("work.Country")}>
                    <Select
                      onChange={(value) => {
                        config.countries.map((cou: any) => {
                          if (cou.key === value) {
                            // form.setFieldValue('owner_number', cou.mobileCountryCode)
                            setMobileCountryCode(cou.mobileCountryCode);
                          }
                        });
                      }}
                    >
                      {config.countries.map((cou: any) => (
                        <Option key={cou.key} value={cou.key}>
                          {cou.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="register_number" label={t("work.Register_NumberABNACN")}>
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    name="type"
                    label={t("work.Company_Type")}
                    rules={[
                      {
                        required: true,
                        message: t("work.Company_Type_Text"),
                      },
                    ]}
                  >
                    <Select placeholder="" onChange={(v: string) => setType(v)}>
                      <Option value="Distributor">Distributor</Option>
                      <Option value="Clinic">Clinic</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="prepaid_subscriptions"
                    label={t("work.PrepaidSubscriptions")}
                    rules={[
                      {
                        pattern: new RegExp(/^[0-9]*$/),
                        message: t("work.OnlyDigits"),
                      },
                    ]}
                    hidden={(type !== "Distributor" && type == "") || user.type !== "admin"}
                  >
                    <Input placeholder="" defaultValue={0} />
                  </Form.Item>
                  {props.record._id ? (
                    <Form.Item name="company_number" label={t("work.Company_Number")}>
                      <Input placeholder="" disabled />
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  <Form.Item
                    name="name"
                    label={t("work.Company_Name")}
                    rules={[
                      {
                        required: true,
                        message: t("work.Company_Name_Text"),
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    name="company_custom_id"
                    label={t("work.Company_Custom_ID")}
                    rules={[
                      {
                        message: t("work.Company_Custom_ID_Text"),
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  {props.record._id && user.companyId === props.record._id ? (
                    ""
                  ) : (
                    <Form.Item name="parentCompanyId" label={t("work.Parent_Company")}>
                      <Select
                        placeholder=""
                        optionFilterProp="label"
                        showSearch
                        onChange={(value) => {
                          companies.map((co: any) => {
                            if (value == co._id) {
                              form.setFieldValue("databaseUrl", co.databaseUrl);
                              form.setFieldValue("diviendPercentage", co.diviendPercentage);
                              form.setFieldValue("taxPercentage", co.taxPercentage);
                            }
                          });
                        }}
                        disabled={user.type !== "admin"}
                      >
                        <Option key="none" label="None" value="">
                          None
                        </Option>
                        {companies
                          .filter((co: any) => {
                            return props.record._id !== co._id;
                          })
                          .map((co: any) => {
                            return (
                              <Option key={co._id} label={co.name} value={co._id}>
                                {co.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item name="databaseUrl" label={t("work.databaseUrl")} rules={[{ required: true, message: t("work.databaseUrl_Text") }]}>
                    <Select placeholder="" disabled={user.type !== "admin"}>
                      {dbs.map((co: any) => {
                        return (
                          <Option key={co.key} value={co.key}>
                            {co.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("work.Owner")}
                    name="owner_name"
                    rules={[
                      {
                        required: true,
                        message: t("work.Owner_Text"),
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item
                    label={t("work.Contact_Number")}
                    name="owner_number"
                    rules={[
                      {
                        required: true,
                        message: t("work.Contact_Number_Text"),
                      },
                      {
                        pattern: new RegExp(/^[0-9]*$/),
                        message: t("work.OnlyDigits"),
                      },
                    ]}
                  >
                    <Input placeholder="" addonBefore={selectBefore} />
                  </Form.Item>
                  <Form.Item
                    label={t("work.Contact_Email")}
                    name="owner_email"
                    rules={[
                      {
                        required: true,
                        message: t("work.Contact_Email_Text"),
                      },
                      {
                        type: "email",
                        message: t("work.EmailType"),
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                  <Form.Item name="publicPlans" label={t("work.publicPlans")}>
                    <Switch
                      disabled={user.type !== "admin" && user.publicPlans === false}
                      defaultChecked={publicPlans}
                      onChange={(value) => setPublicPlans(value)}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                  <Form.Item name="customerNotifications" label={t("work.customerNotifications")}>
                    <Switch
                      disabled={user.type !== "admin" && user.customerNotifications === false}
                      defaultChecked={customerNotifications}
                      onChange={(value) => setCustomerNotifications(value)}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                  <Form.Item name="useInventory" label={t("work.useInventory")}>
                    <Switch
                      disabled={user.type !== "admin" && user.useInventory === false}
                      defaultChecked={useInventory}
                      onChange={(value) => setUseInventory(value)}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {props.visible ? (
              <Col span={12}>
                {user.type !== "admin" ? (
                  ""
                ) : (
                  <Form.Item
                    label={t("work.DividendPercentage")}
                    name="diviendPercentage"
                    rules={[
                      {
                        required: true,
                        message: t("work.DividendPercentage_Text"),
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                  >
                    <InputNumber disabled={user.type !== "admin"} addonAfter={<span>%</span>} style={{ width: "100%" }} min={0} max={100} />
                  </Form.Item>
                )}
                {user.type !== "admin" ? (
                  ""
                ) : (
                  <Form.Item
                    label={t("work.TaxPercentage")}
                    name="taxPercentage"
                    rules={[
                      {
                        required: true,
                        message: t("work.TaxPercentage_Text"),
                      },
                    ]}
                    wrapperCol={{ span: 24 }}
                  >
                    <InputNumber disabled={user.type !== "admin"} addonAfter={<span>%</span>} style={{ width: "100%" }} min={0} max={100} />
                  </Form.Item>
                )}
                <Form.Item
                  name="zip"
                  label={t("work.Zip")}
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]*$/),
                      message: t("work.OnlyDigits"),
                    },
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
                <AddressSelect center={center} address={address} setCenter={setCenter} setAddress={setAddress} />
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default CompanyModal;
