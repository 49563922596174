
import { Menu, Modal, Dropdown, Space } from 'antd';
import { useLocation, useHistory } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { useEffect, useState } from 'react'
import { ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import {
  signout,
  selectUser
} from '../../reducers/account/accountSlice';
import { useTranslation } from 'react-i18next';
import SupporModal from '../Components/SupportModal'

const TopBar = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [supportModal, setSupportModal] = useState(false);
  const { t } = useTranslation();

  const signOut = () => {
    Modal.confirm({
      title: t('work.Confirm_to_log_out'),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        if (user.type == 'customer') {
          return history.push('/login')
        }
        history.push('/')
      },
      onCancel() {
      },
    })
  }

  const menu = (
    <Menu
      items={[
        user.type === 'normal' ?
          {
            key: '1',
            label: (
              <div
                onClick={() => setSupportModal(true)}
                key="support">
                {t('work.Support')}
              </div>
            ),
          } : null,
        user.type === 'customer' ? null : {
          key: '2',
          label: (
            <div onClick={() => {
              if (user.type === 'admin') {
                history.push('/admin/preference')
              } else {
                history.push('/work/preference')
              }
            }}>
              {t('work.Language')}
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div onClick={signOut}>
              {t('work.Logout')}
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="horizontal"
      >
        <Menu.Item
          style={{ float: 'right' }} key="logout">
          <Dropdown overlay={menu} placement="bottomLeft">
            <Space>
              {user.fullName}
              <DownOutlined />
            </Space>
          </Dropdown>
        </Menu.Item>
        {
          user.type === 'customer' ?
            <span

              style={{ float: 'right' }} key="company">
              {user.company && user.company.name}
            </span> : ''
        }
      </Menu>
      <SupporModal visible={supportModal} setVisible={setSupportModal} />
    </>
  )
}

export default TopBar;