import { Form, Input, Button, Row, Select, Col, Upload, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Column } from '@ant-design/plots';
import {
  selectUser,
} from '../../reducers/account/accountSlice';
import axios from 'axios'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { UploadOutlined , FormOutlined, LogoutOutlined} from '@ant-design/icons';
import {
  postAWSSignurlAsync,
  userPostAWSSignurlAsync
} from '../../reducers/aws/awsSlice'
import s3utils from '../../utils/s3.js'
import {
  sendStaffEmailAsync,
} from '../../reducers/portal/portalSlice';
import {
  sendAdminStaffEmailAsync,
} from '../../reducers/users/usersSlice';
const Option = Select.Option
const SupporModal = (props: any) => {
  const history = useHistory()
  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState([] as any)
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const [reasons, setReasons] = useState('')
  const [type, setType] = useState('')
  const [isRefund, setIsRefund] = useState(false)
  const [files, setFiles] = useState([])
  const [form] = Form.useForm()
  const { t, i18n } = useTranslation()

  const sendStaffEmail = (reason: String) => async() => {
    if (!type) {
      return message.error(t('work.Select_the_type_of_support_you_need'))
    }
    if (!reason) {
      return message.error(t('work.Please_tell_us_the_details_that_you_need_help_with'))
    }
    console.log(user)
    const parmas = {
      isSupport: true,
      reason: reason,
      type: type,
      id: user.device && user.device._id,
      fileList: fileList.filter((file: any) => file.status === 'success').map((file: any) => {
        return {
          name: file.name,
          url: file.publicUrl
        }
      })
    } as any
    setLoading(true)
    let result = {} as any
    if (user.type !== 'customer') {
      result = await dispatch(sendAdminStaffEmailAsync(parmas))
    } else {
      result = await dispatch(sendStaffEmailAsync(parmas))
    }
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg, data } = payload
      if (code === 0) {
        message.success('Notify staff of success');
        props.setVisible(false)
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  useEffect(() => {
    setType('')
    setReasons('')
    setFileList([])
  }, [])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const uploadProps = {
    onRemove: (del: any) => {
      setFileList(fileList.filter((file: any) => file !== del))
    },
    customRequest:  async (option: any) => {
      console.log(option)
      option.method = 'PUT'
      option.filename = option.file.fileName
      option.data = option.file.fields
      option.onSuccess = async () => {
        option.file.status = 'success'
        const fileListTemp = fileList.map((fi: any) => {
          if (fi.uid == option.file.uid) {
            return option.file
          }
          return fi
        })
        setFileList(fileListTemp)
        setLoading(false)
        console.log(fileListTemp)
        console.log('1234')

      }
      await s3utils.postToS3(option)
    },
    beforeUpload: async (file: any, list: any) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return isJpgOrPng
      }
      const params = {
        fileName: file.uid,
        fileType: file.type
      }
      setLoading(true)
      const result = await dispatch(userPostAWSSignurlAsync(params))
      const { payload } = result
      if (payload) {
        const { code, data, msg } = payload
        if (code === 0) {
          file.fileName = data.fileName
          file.signUrl = data.signUrl
          file.publicUrl = data.publicUrl
          file.fields = data.fields
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
      return true;
    },
    onChange: (option: any) => {
      setFileList(option.fileList)
    },
    fileList,
  };
  return (
    <>
      {
        <Modal title={t('work.Support')}
          open={props.visible}
          width={'800px'}
          confirmLoading={loading}
          onOk={sendStaffEmail(reasons)}
          onCancel={() => props.setVisible(false)}>
          <Row gutter={20}>
            <Col md={24} xs={24}>
              <Select
                style={{
                  width: '100%'
                }}
                onChange={(v: any) => setType(v)}
                placeholder={t('work.Select_the_type_of_support_you_need')} >
                <Option value="Device">{t('work.Device')}</Option>
                <Option value="Online_portal">{t('work.Onlineportal')}</Option>
                <Option value="Payment">{t('work.Payment')}</Option>
                <Option value="Others">{t('work.Others')}</Option>
              </Select>
            </Col>
            <Col md={24} xs={24} style={{margin: '10px 0'}}>
              <Input.TextArea
                onChange={(v: any) => setReasons(v.target.value)} placeholder={t('work.Please_tell_us_the_details_that_you_need_help_with')} />
            </Col>
          <Col span={12}>
            {/* <Upload {...uploadProps}>
              <Button disabled={fileList.length > 5} icon={<UploadOutlined />}>{t('work.Select_File')}</Button>
            </Upload> */}
            <Upload
              accept='.jpg,.jpeg,.png,.txt,.doc,.word'
              showUploadList={true}
              {...uploadProps}>

              <Button disabled={fileList.length > 4} icon={<UploadOutlined />}>{t('work.Select_File')}</Button>
            </Upload>
          </Col>
          </Row>
        </Modal>
      }
    </>
  );
}

export default SupporModal
