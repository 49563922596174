import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getSetting,
  addSetting,
  checkDatabase,
  getBaseSetting
} from './settingAPI';
const initialState = {}

export const getSettingsAsync = createApiAsyncThunk<any>('setting/getSetting', getSetting)
export const getBaseSettingAsync = createApiAsyncThunk<any>('setting/getBaseSetting', getBaseSetting)
export const addSettingAsync = createApiAsyncThunk<any>('setting/addSetting', addSetting)
export const checkDatabaseAsync = createApiAsyncThunk<any>('setting/checkDatabase', checkDatabase)

export const logsSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {}
})

export default logsSlice.reducer;
