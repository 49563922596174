import { useState, useEffect, useCallback } from 'react'
import { Form, Input, Modal, Button, message, Row, Select, Col, Divider } from 'antd'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  addUserAsync,
} from '../../reducers/users/usersSlice'
import {
  getAllCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import {
  selectUser
} from '../../reducers/account/accountSlice';
import { TimeZoneSelectDialog } from 'react-timezone-map-select';
import { useTranslation } from 'react-i18next';
import PhotoUploader from '../Components/PhotoUploader'
import mobileCountryCodes from '../../config/mobileCountryCodes'
import config from '../../config/config'
import './Common.scss'
import md5 from 'js-md5'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const { Option } = Select;
const AddUserModal = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [companies, setCompanies] = useState([])
  const [utype, setType] = useState('normal')
  const [loading, setLoading] = useState(false)
  const user = useAppSelector(selectUser)
  const [imageUrl, setImageUrl] = useState('')
  const [timezoneName, setTimezoneName] = useState('America/Los_Angeles');

  const [mobileCountryCode, setMobileCountryCode] = useState('')
  const selectBefore = (
    <Select value={mobileCountryCode} onChange={(value: string) => setMobileCountryCode(value)} className="select-before">
      {
        mobileCountryCodes.map((code: any) =>
          <Option key={code.code} value={code.code}>{code.code}</Option>)
      }
    </Select>
  );
  /** Set true when you open TimeZoneSelectDialog. */
  const [open, setOpen] = useState(false);

  /** Called when you press "Open Dialog" button. */
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  /** Called when TimeZoneSelectDialog is closed. */
  const handleClose = useCallback((newTimeZoneName: string) => {
    console.log(newTimeZoneName)
    setTimezoneName(newTimeZoneName);
    setOpen(false);
  }, []);
  const { t, i18n } = useTranslation()
  const [resetPassword, setResetPassword] = useState(false)
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    form.setFieldValue('password', '')
    setResetPassword(false)
    props.getList()
  }
  const fetchList = async () => {
    setLoading(true)
    const result = await dispatch(getAllCompaniesAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCompanies(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const onFinish = async () => {
    form.validateFields()
      .then(async values => {
        if (!timezoneName) {
          return message.error(t('work.SelctTimezone'))
        }
        values.type = values.type || utype
        values.timezone = timezoneName
        values.id = props.record._id
        values.mobileCountryCode = mobileCountryCode
        values.imageUrl = imageUrl || ''
        values.resetPassword = resetPassword
        try {
          if (values.password) {
            values.password = md5(values.password)
          }
        } catch (err) {
          console.log(err)
        }
        console.log(values)
        const result = await dispatch(addUserAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            if (values.id === user._id) {
              return window.location.reload()
            }
            message.success(t('work.Successful_operation'));
            closeModal()
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  useEffect(() => {
    form.resetFields()
    setTimezoneName(props.record.timezone || '')
    setImageUrl('')
    form.setFieldsValue({
      type: 'normal',
    })
    if (user.type !== 'admin' && user.companyId) {
      config.countries.map((cou: any) => {
        if (cou.key === user.company.country) {
          setMobileCountryCode(cou.mobileCountryCode)
        }
      })
    }
    if (props.record._id) {
      setMobileCountryCode(props.record.mobileCountryCode || '')
      setImageUrl(props.record.imageUrl)
      form.setFieldsValue(props.record)
      setType(props.record.type)
    }
    fetchList()
  }, [props.record._id, props.visible])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width='900px'
          title={props.record._id ? t('work.EditUser') : t('work.CreateUser')}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading}
              disabled={user.userType !== 'admin' && user.type !== 'admin' && user._id !== props.record._id} type="primary" onClick={onFinish}>
              Save
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name="fullName" label={t('work.Name')} rules={[{ required: true, message: t('work.NameIsRequired') }]}>
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label={t('work.Email')} name="email"
                rules={[{
                  required: true,
                  message: t('work.NameIsRequired')
                }, {
                  type: 'email', message: t('work.EmailType')
                }]}>
                <Input placeholder="" disabled={props.record._id && user.type !== 'admin'} />
              </Form.Item>

              {

                props.record._id ?
                  (resetPassword ?
                    <>
                      <Form.Item
                        name="password" label={t('work.Password')}
                        rules={[
                          {
                            required: true,
                            message: t('work.password_message'),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && value.length >= 8) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(t('work.passwordlengthText')));
                            },
                          }),
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
                              if (reg.test(value)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(t('work.passwordText')));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item
                        label={t('work.Confirmpassword')}
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: t('work.confirm_message'),
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(t('work.The_two_passwords_message')));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </> :
                    <Form.Item name="password" label={t('work.Password')} labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} >
                      <Button onClick={() => setResetPassword(true)} >{t("work.Reset_Password")}</Button>
                    </Form.Item>)
                  : ''

              }{
                user.type === 'admin' ?
                  <Form.Item
                    name="type"
                    label={t('work.Authority')}
                    rules={[{ required: true, message: t('work.Please_select_authority') }]}
                  >
                    <Select placeholder=""
                      onChange={(v) => setType(v)}
                      disabled={props.record._id || user.type !== 'admin'}
                    >
                      <Option value="normal">User</Option>
                      {
                        user.type === 'admin' ?
                          <Option value="admin">Admin</Option> : ''
                      }
                    </Select>
                  </Form.Item> : ''
              }
              {
                utype === 'normal' ?
                  <Form.Item
                    name="userType"
                    label={t('work.UserType')}
                    rules={[{ required: true, message: t('work.Please_select_type') }]}
                  >
                    <Select placeholder=""
                      disabled={user.userType !== 'admin' && user.type !== 'admin'}>
                      <Option value="admin">Administrator</Option>
                      <Option value="general">Support staff</Option>
                      <Option value="doctor">Clinician</Option>
                    </Select>
                  </Form.Item> : ''
              }
              {
                utype === 'normal' ? (
                  <Form.Item
                    name="companyId"
                    label={`${t('work.Company')} (${t('work.DistributorClinic')})`}
                    rules={[{ required: true, message: t('work.Please_select_company') }]}
                  >
                    <Select placeholder="" showSearch optionFilterProp="label"
                      onChange={(value) => {

                        let company = {} as any
                        companies.map((co: any) => {
                          if (co._id === value) {
                            company = co
                          }
                        })
                        console.log(company)
                        config.countries.map((cou: any) => {
                          if (cou.key === company.country) {
                            setMobileCountryCode(cou.mobileCountryCode)
                            setTimezoneName(cou.timezone)
                          }
                        })
                      }}

                    >

                      {
                        companies.map((co: any) => {
                          return (
                            <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                        })
                      }
                    </Select>
                  </Form.Item>) : ''
              }
              <Form.Item label={t('work.Timezone')} name="timezone" required>
                <Input.Group compact>
                  <Input disabled style={{ width: 'calc(100% - 80px)' }} placeholder="" value={timezoneName} />
                  <Button type="primary" onClick={handleOpen} style={{ float: "right" }} >
                    {t('work.Edit')}
                  </Button>
                </Input.Group>
                <TimeZoneSelectDialog
                  open={open}
                  title={t('work.Timezone')}
                  buttonLabelOk={t('work.Submit')}
                  buttonLabelCancel={t('work.Cancel')}
                  description={t('work.timezoneDescription')}
                  timeZoneName={timezoneName}
                  onClose={handleClose}
                />
              </Form.Item>
              <Form.Item label={`${t('work.Phone')}`} name="phone"
                rules={[{ required: true, message: t('signup.Please_input_your_phone') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && value.length >= 8) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('work.phoneLengthText')));
                  },
                }),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    var reg = new RegExp(/^\d+$/);
                    if (reg.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('work.phoneNumberText')));
                  },
                })]}>
                <Input placeholder="" addonBefore={selectBefore} />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
            <PhotoUploader
            defaultUrl={imageUrl} uploadedCb={(url: string) => setImageUrl(url)}
            ></PhotoUploader>
          </Col> */}
          </Row>
        </Modal>
      </Form>
    </>
  );
}

export default AddUserModal
