
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { useEffect, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  selectUser
} from '../../reducers/account/accountSlice';
import { useTranslation } from 'react-i18next';
const MobileTabs = (props: any) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const { t, i18n } = useTranslation();
  useEffect(() => {
  }, [])
  return (
    <>
      <Tabs onChange={(key) => history.push(key)}>
        <Tabs.Tab title={t("work.MyDevice")} key='/index/device'>
        </Tabs.Tab>
        <Tabs.Tab title={t("work.MyDetails")} key='/index/details'>
        </Tabs.Tab>
        <Tabs.Tab title={t("work.Patients")} key='/index/accounts'>
        </Tabs.Tab>
        {/* <Tabs.Tab title={t("work.Notification")}  key='/index/notification'>
        </Tabs.Tab> */}
      </Tabs>
    </>
  )
}

export default MobileTabs;