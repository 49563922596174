import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getCatalogs,
  deleteCatalog,
  exportCatalogs,
  addCatalog,
  getAllCatalogs
} from './catalogsAPI';
const initialState = {}

export const deleteCatalogAsync = createApiAsyncThunk<any>('catalogs/deleteCatalog', deleteCatalog)


export const addCatalogAsync = createApiAsyncThunk<any>('catalogs/addCatalog', addCatalog)

export const getCatalogsAsync = createApiAsyncThunk<any>('catalogs/getCatalogs', getCatalogs)
export const exportCatalogsASync = createApiAsyncThunk<any>('catalogs/exportCatalogs', exportCatalogs)


export const getAllCatalogsAsync = createApiAsyncThunk<any>('catalogs/getAllCatalogs', getAllCatalogs)

export const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {}
})

export default catalogsSlice.reducer;
