import { Button, Row, Table, message, Space } from 'antd'
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import moment from 'moment'
import { useAppDispatch } from '../../app/hooks'
import config from '../../config/config'
import { getPaymentsAsync } from '../../reducers/customers/customersSlice'

interface propsObject {
  setVisible: Function;
  record: any;
  form: any;
  setRecord: Function;
  products: any;
  getList: Function;
  db: string;
}

const Payment = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [records, setRecords] = useState([])

  const fetchList = async () => {
    setLoading(true)
    const result = await dispatch(getPaymentsAsync({
      userId: props.record._id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setRecords(data.list.filter((d: any) => d.status === 'succeeded'))
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  useEffect(() => {
    if (props.record._id) {
      fetchList()
    }
  }, [props.record._id])

  const columns = [
    {
      title: t('work.Date'),
      dataIndex: 'createdAt',
      sorter: true,
      width: '200px',
      render: (createdAt: string) => (
        <>
          <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
        </>
      ),
    },
    {
      title: t('work.PatientName'),
      dataIndex: 'accountName',
      width: '200px'
    },
    {
      title: t('work.Plan'),
      dataIndex: 'planName',
      width: '200px'
    },
    {
      title: t('work.Status'),
      dataIndex: 'status',
      sorter: true,
      width: '150px',
    },
    {
      title: t('work.Amount'),
      dataIndex: 'planName',
      width: '200px',
      render: (newExpiredDate: string, row: any) => (
        <>
          <span>{row.currency.toUpperCase()} {row.amount}</span>
        </>
      ),
    },
    {
      title: t('work.PlanExpiryDate'),
      dataIndex: 'newExpiredDate',
      width: '200px',
      render: (newExpiredDate: string) => (
        <>
          <span>{newExpiredDate && moment(newExpiredDate).format('DD/MM/YYYY')}</span>
        </>
      ),
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      width: '300px',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          {record.status == 'succeeded' ? <Button type='primary' size='small' onClick={() => window.open(`${config.portalDomain}/receipt?id=${record._id}`)} key="1">
            {t('work.Receipt')}
          </Button> : ''}
        </Space>
      ),
    },
  ];
  return (
    <>
      <Row style={{ width: '100%', marginBottom: 50 }}>
        <Table
          bordered
          columns={columns}
          showSorterTooltip={false}
          rowKey={(record: any) => record._id}
          dataSource={records}
          loading={loading}
          pagination={false}
        />
      </Row>
    </>
  );
}

export default Payment
