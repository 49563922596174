
import { Menu, Modal, message, Dropdown, Space } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import logo from '../../assets/logo2.png'
import './WorkSpace.scss'
import { useEffect, useState } from 'react'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  signout,
  selectUser
} from '../../reducers/account/accountSlice';
import SupporModal from '../Components/SupportModal'
import { useTranslation } from 'react-i18next';
const AdminSlider = (props: any) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [supportModal, setSupportModal] = useState(false);
  const user = useAppSelector(selectUser)
  const { t, i18n } = useTranslation();
  const signOut = () => {
    Modal.confirm({
      title: t('work.Confirm_to_log_out'),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        if (user.type == 'customer') {
          return history.push('/login')
        }
        history.push('/')

      },
      onCancel() {
      },
    })
  }
  useEffect(() => {
  }, [])
  return (
    <>
      <Menu
        className='portal-slider'
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
      >
        <Menu.Item disabled className="sider-title" key="title" style={{
          margin: '10px 0',
          cursor: 'auto'
        }}>
          <img style={{ maxHeight: '35px' }} src={logo} alt="" />
        </Menu.Item>
        <Menu.Item key="/index/device" ><Link to="/index/device"> {t("work.MyDevice")}</Link></Menu.Item>
        <Menu.Item key="/index/details" ><Link to="/index/details"> {t("work.MyDetails")}</Link></Menu.Item>
        <Menu.Item key="/index/accounts" ><Link to="/index/accounts"> {t("work.Patients")}</Link></Menu.Item>
        <Menu.Item
          style={{
            position: 'absolute',
            bottom: '10px'
          }}
          className='logout'
          onClick={signOut}
          key="logout">
          {t('work.Logout')}
        </Menu.Item>
        <Menu.Item
          onClick={() => setSupportModal(true)}
          style={{
            position: 'absolute',
            bottom: '60px'
          }}
          className='support'
          key="support">
          {t('work.Support')}
        </Menu.Item>
      </Menu>
      <SupporModal visible={supportModal} setVisible={setSupportModal} />
    </>
  )
}

export default AdminSlider;