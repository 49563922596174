import { Tree, Row, Col, Radio, Card, message, Select, Popover } from 'antd'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Bar, Pie } from '@ant-design/plots';
import {
  DownOutlined,
  ApartmentOutlined,
  EnvironmentFilled
} from '@ant-design/icons';
import {
  selectUser
} from '../../reducers/account/accountSlice';
import {
  getDashboardDataAsync
} from '../../reducers/companies/companiesSlice'
import { getBaseSettingAsync } from '../../reducers/setting/settingSlice'
import _ from 'lodash'
import GoogleMapReact from 'google-map-react';
import moment from 'moment'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const Dashboard = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser)
  const [companies, setCompanies] = useState([])
  const [keys, setKeys] = useState([] as any)
  const [db, setDb] = useState('all')
  const [dbs, setDbs] = useState([])
  const { t, i18n } = useTranslation();
  const [companiesCount, setCompaniesCount] = useState(0)
  const [customersCount, setCustomersCount] = useState(0)
  const [devicesCount, setDevicesCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [companiesData, setCompaniesData] = useState([])
  const [customerFilter, setCustomerFilter] = useState('')
  const [devicesFilter, setDevicesFilter] = useState('')

  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const fetchDashboard = async (params: any) => {
    setLoading(true)
    const options = {} as any
    options.db = params.db || db
    const result = await dispatch(getDashboardDataAsync(options))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        let keys: String[] = []
        const mapData = (obj: any) => {
          obj.title = obj.name
          obj.key = obj._id
          obj.icon = (<ApartmentOutlined />)
          keys.push(obj.key)
          if (obj.children) {
            obj.children.map((ob: any) => mapData(ob))
          }
        }
        data.companies.map((obj: any) => mapData(obj))
        setCompanies(data.companies)
        setCustomersCount(data.customersCount)
        setCompaniesCount(data.companiesCount)
        setDevicesCount(data.devicesCount)
        data.companiesDetail.map((co: any) => {
          co.customerCount = co.customers.filter((cu: any) => {
            if (customerFilter) {
              if (customerFilter === 'last7') {
                return moment(cu.registerDate).isAfter(moment().subtract(7, 'day'))
              } else if (customerFilter === 'last30') {
                return moment(cu.registerDate).isAfter(moment().subtract(30, 'day'))
              }
              return true
            }
            return true
          }).length
          co.devicesCount = co.devices.filter((de: any) => {
            if (devicesFilter) {
              return de.status === devicesFilter
            }
            return true
          }).length
        })
        setCompaniesData(data.companiesDetail)
        setKeys(keys)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  useEffect(() => {
    fetchDashboard({})
    fetchSetting()
  }, [])
  const defaultProps = {
    center: {
      lat: -33.851923390266165,
      lng: 151.21838655370252
    },
    zoom: 1
  };
  const AnyReactComponent = (props: any) => <div
    style={{ width: 200, fontSize: 20, fontWeight: 800, position: 'relative' }}
    className='marker' {...props}>
    <Popover
      title={''} content={
        <span style={{ color: '#333' }}>{props.company.name} ({props.company.type})</span>}>
      <EnvironmentFilled style={{ color: props.company.type === 'Distributor' ? '#01357D' : 'red', position: 'absolute', left: -5, top: -20 }} />
    </Popover>
  </div>;

  const customerBarconfig = {
    data: companiesData,
    xField: 'customerCount',
    yField: 'name',
    seriesField: 'name',
    legend: {
    },
  };
  const customerPieConfig = {
    appendPadding: 10,
    data: companiesData,
    angleField: 'customerCount',
    colorField: 'name',
    radius: 0.9,
    label: {
      type: 'outer',
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  const devicesBarConfig = {
    data: companiesData,
    xField: 'devicesCount',
    yField: 'name',
    seriesField: 'name',
    legend: {
    },
  };
  const devicesPieConfig = {
    appendPadding: 10,
    data: companiesData,
    angleField: 'devicesCount',
    colorField: 'name',
    radius: 0.9,
    label: {
      type: 'outer',
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  const onChangeCustomer = (e: any) => {
    let filter = e.target.value
    let data = _.cloneDeep(companiesData)
    data.map((co: any) => {
      co.customerCount = co.customers.filter((cu: any) => {
        if (filter) {
          if (filter === 'last7') {
            return moment(cu.registerDate).isAfter(moment().subtract(7, 'day'))
          } else if (filter === 'last30') {
            return moment(cu.registerDate).isAfter(moment().subtract(30, 'day'))
          }
          return true
        }
        return true
      }).length
      co.devicesCount = co.devices.filter((de: any) => {
        if (devicesFilter) {
          return de.status === devicesFilter
        }
        return true
      }).length
    })
    setCustomerFilter(filter)
    setCompaniesData(data)
  };
  const onChangeDevices = (e: any) => {
    let filter = e.target.value
    let data = _.cloneDeep(companiesData)
    data.map((co: any) => {
      co.customerCount = co.customers.filter((cu: any) => {
        if (customerFilter) {
          if (customerFilter === 'last7') {
            return moment(cu.registerDate).isAfter(moment().subtract(7, 'day'))
          } else if (customerFilter === 'last30') {
            return moment(cu.registerDate).isAfter(moment().subtract(30, 'day'))
          }
          return true
        }
        return true
      }).length
      co.devicesCount = co.devices.filter((de: any) => {
        if (filter) {
          return de.status === filter
        }
        return true
      }).length
    })
    setDevicesFilter(filter)
    setCompaniesData(data)
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setDb(value)
    fetchDashboard({
      db: value
    })
  };

  return (
    <div className="dashboard" style={{ paddingLeft: 0 }}>
      {
        user.type === 'admin' ? (
          <>
            <span>Database: </span>
            <Select
              style={{ margin: '10px 10px', width: 100 }}
              defaultValue={db} placeholder="" onChange={handleChange}>

              <Option value="all">All</Option>

              {
                dbs.map((co: any) => {
                  return (
                    <Option key={co.key} value={co.key}>{co.name}</Option>)
                })
              }
            </Select>
          </>) : ''
      }
      <Row gutter={30}>
        <Col span={8}>
          <Card hoverable title={t("work.Companies")} style={{ width: '100%', height: '100%' }}>
            <Tree
              showIcon
              defaultExpandAll={true}
              autoExpandParent={true}
              switcherIcon={<DownOutlined />}
              treeData={companies}
            />
            <span style={{ position: 'absolute', bottom: 10 }}>
              {t("work.Total")}: {companiesCount}
            </span>
          </Card>
        </Col>
        <Col span={16}>
          <Card>
            <div style={{ height: '600px', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDZoOJSt6Aadoo5aZN7Es5ASc04zBiqVTU" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                {
                  companiesData.filter((co: any) => co.location).map((co: any) => {
                    return (<AnyReactComponent
                      key={co._id}
                      company={co}
                      text={co.name}
                      lat={co.location.lat}
                      lng={co.location.lng}
                    />)
                  })
                }
              </GoogleMapReact>
            </div>
          </Card>
        </Col>
      </Row>
      <Card title={t('work.Devices')} style={{ marginTop: 40 }}>
        <div>
          <Radio.Group style={{ float: 'right', marginRight: 50 }} onChange={onChangeDevices}>
            <Radio.Button value="">{t("work.All")}</Radio.Button>
            <Radio.Button value="In use">{t("work.In_use")}</Radio.Button>
            <Radio.Button value="Repairing">{t("work.Repair")}</Radio.Button>
            <Radio.Button value="Recalled">{t("work.Recall")}</Radio.Button>
          </Radio.Group>
        </div>
        <Row gutter={60} >
          <Col span={12}>
            <Bar {...devicesBarConfig} />
          </Col>
          <Col span={12}>
            <Pie {...devicesPieConfig}></Pie>
          </Col>
        </Row>
        <span style={{ position: 'absolute', bottom: 10 }}>
          {t("work.Total")}: {devicesCount}
        </span>
      </Card>
      <Card title={t('work.Customers')} style={{ marginTop: 40 }}>
        <div>
          <Radio.Group style={{ float: 'right', marginRight: 50 }} onChange={onChangeCustomer}>
            <Radio.Button value="">{t("work.All")}</Radio.Button>
            <Radio.Button value="last7">{t("work.Last_7_days")}</Radio.Button>
            <Radio.Button value="last30">{t("work.Last_30_days")}</Radio.Button>
          </Radio.Group>
        </div>
        <Row gutter={60} >
          <Col span={12}>
            <Bar {...customerBarconfig} />
          </Col>
          <Col span={12}>
            <Pie {...customerPieConfig}></Pie>
          </Col>
        </Row>
        <span style={{ position: 'absolute', bottom: 10 }}>
          {t("work.Total")}: {customersCount}
        </span>
      </Card>
    </div>
  )
}

export default Dashboard;
