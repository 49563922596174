import { useState, useEffect } from "react";
import { Button, Skeleton, Card, Divider } from 'antd';
import { loadAirwallex, confirmPaymentIntent, createElement, createPaymentConsent } from 'airwallex-payment-elements';
import config from '../../../config/config'
import { useTranslation } from 'react-i18next';
import './airwallex.scss'

import {
  selectUser,
} from '../../../reducers/account/accountSlice';
import { useAppSelector } from '../../../app/hooks'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

loadAirwallex({
  env: config.aw_env, // Setup which Airwallex env('demo' | 'prod') to integrate with
  origin: window.location.origin, // Set up your event target to receive the browser events message
});

const Airwallex = (props) => {
  const user = useAppSelector(selectUser)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);

  let returnUrl
  switch (user.type) {
    case 'admin':
      returnUrl = `${config.domain}/admin/payment-result`
      break
    case 'normal':
      returnUrl = `${config.domain}/work/payment-result`
      break
    case 'customer':
      returnUrl = `${config.portalDomain}/index/payment-result`
      break
  }

  useEffect(() => {
    const cardNumber = createElement('cardNumber');
    const expiry = createElement('expiry');
    const cvc = createElement('cvc');

    // STEP #5: Mount split card elements
    const domElement = cardNumber.mount('cardNumber');
    expiry.mount('expiry');
    cvc.mount('cvc');

    // STEP #6a: Add a button handler to trigger the payment request
    if (document.getElementById('submit')) {
      document.getElementById('submit').addEventListener('click', () => {
        setPaying(true)
        const metadata = {
          db: props.db,
          accountId: props.account._id,
          type: props.payType,
          userId: props.account.userId,
          planId: props.plan._id
        }
        if (props.auto_renew === 'Yes') {
          createPaymentConsent({
            customer_id: props.customerId,
            client_secret: props.clientSecret,
            currency: props.currency,
            metadata,
            element: cardNumber,  // either the card element or card number element depends on the element you integrate,
            next_triggered_by: 'merchant' // 'merchant' for MIT and 'customer' for CIT
          }).then((response) => {
            setPaying(false)
            // STEP #6b: Listen to the request response
            /* handle create consent response in your business flow */
            window.location.href = returnUrl
          })
            .catch((response) => {
              setPaying(false)
              // STEP #6c: Listen to the error response
              /* handle error response*/
              alert(response.message)
              console.log('createPaymentConsent error', response);
            })
        } else {
          confirmPaymentIntent({
            metadata,
            element: cardNumber,
            intent_id: props.intentId,
            customer_id: props.customerId,
            client_secret: props.clientSecret,
          })
            .then((response) => {
              setPaying(false)
              // STEP #6b: Listen to the request response
              /* handle confirm response*/
              window.location.href = returnUrl
            })
            .catch((response) => {
              setPaying(false)
              // STEP #6c: Listen to the error response
              /* handle error response*/
              alert(response.message)
              console.log('confirmPaymentIntent error', response);
            });
        }
      });
    }


    // STEP #7: Add an event listener to ensure the element is mounted
    if (domElement) {
      domElement.addEventListener('onReady', (event) => {
        /*
        ... Handle event
         */
        setLoading(false)
      });
    }


    // Create PaymentIntent as soon as the page loads
  }, [props.clientSecret]);

  return (
    <div className="Elements">
      <Skeleton loading={loading} active >
      </Skeleton>
      <div style={
        {
          visibility: loading ? 'hidden' : 'visible'
        }
      } id="element">
        <div className="pay-input">
          <div>{t('work.CardNumber')}</div>
          <div id="cardNumber"></div>
        </div>
        <div className="pay-input">
          <div>{t('work.Expiry')}</div>
          <div id="expiry"></div>
        </div>
        <div className="pay-input">
          <div>Cvc</div>
          <div id="cvc"></div>
        </div>
        {
          props.payType !== 'change' ?
            <Card className="pay-card" style={{ padding: '0px 0px', marginBottom: 20 }}>
              <div >
                <p style={{ margin: '5px 0' }}>
                  Deposit fee: {props.plan && (props.plan.deposit || 0)} {props.plan && props.plan.currency.toUpperCase()}
                </p>
                {
                  props.plan && (props.plan.purchase > 0) ?
                    <p style={{ margin: '5px 0' }}>
                      Purchase Fee: {props.plan && (props.plan.purchase || 0)} {props.plan && props.plan.currency.toUpperCase()}
                    </p> : ''
                }
                <p style={{ margin: '5px 0' }}>
                  Subscription fee: {props.plan && Math.round((props.plan.price * (1 - (props.company.taxPercentage || 0) * 0.01) + Number.EPSILON) * 100) / 100} {props.plan && props.plan.currency.toUpperCase()}
                </p>
                <p style={{ margin: '5px 0' }}>
                  GST/VAT: {props.plan && Math.round((props.plan.price * (props.company.taxPercentage || 0) * 0.01 + Number.EPSILON) * 100) / 100} {props.plan && props.plan.currency.toUpperCase()}
                </p>
                <Divider style={{ borderColor: '#333', margin: '10px 0' }} dashed></Divider>
                <p style={{ margin: '5px 0' }}>
                  <strong>
                    Total amount: {props.plan && Math.round((props.plan.price + (props.plan.deposit || 0) + (props.plan.purchase || 0) + Number.EPSILON) * 100) / 100} {props.plan && props.plan.currency.toUpperCase()}</strong>
                </p>
              </div>
            </Card> : ""
        }
        <Button loading={paying} type="primary" style={{ width: '100%', height: 45 }} id="submit">{t('work.Submit')}</Button>
      </div>
    </div>
  );
}
export default Airwallex