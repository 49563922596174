import { useEffect } from 'react'
import { Form, Input, Modal, DatePicker, Button, message, Row, Select, Col, Empty } from 'antd'
import JSONPretty from 'react-json-pretty';
import moment from 'moment'
import { useTranslation } from 'react-i18next';

const JSONPrettyMon = require('react-json-pretty/dist/monikai');

interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}

const PaymentModal = (props: propsObject) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const closeModal = () => {
    props.setVisible(false)
  }

  useEffect(() => {
    form.resetFields()
    if (props.record._id) {
      form.setFieldsValue({
        createdAt: moment(props.record.createdAt),
        // email: props.record.customer.email,
        account: props.record.account && props.record.account.username,
        // company: props.record.company.name,
        product: props.record.catalog.name,
        plan: props.record.plan.name,
        price: (props.record.currency || '') + ' ' + (props.record.amount || ''),
      })
    }
  }, [props.record._id, props.visible])

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width='1200px'
          title={props.record._id ? t('work.ViewPayment') : t('work.ViewPayment')}
          onCancel={closeModal}
          footer={[
            <Button key="back" onClick={closeModal}>
              {t("work.Close")}
            </Button>
          ]}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label={t('work.Date')} name="createdAt" >
                <DatePicker showTime disabled style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="email"
                label={t("work.Username_email")}>
                <Input placeholder="" disabled />
              </Form.Item>
              <Form.Item name="account"
                label={t("work.Patient")}>
                <Input placeholder="" disabled />
              </Form.Item>
              <Form.Item name="company"
                label={t("work.Company")}>
                <Input placeholder="" disabled />
              </Form.Item>
              <Form.Item name="product"
                label={t("work.Product")}>
                <Input placeholder="" disabled />
              </Form.Item>

              <Form.Item name="plan"
                label={t("work.Plan")}>
                <Input placeholder="" disabled />
              </Form.Item>

              <Form.Item name="price"
                label={t("work.Price")}>
                <Input placeholder="" disabled />
              </Form.Item>
              <Form.Item name="status"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
                label={t("work.Status")}>
                {props.record.status}
              </Form.Item>
              {
                props.record.receipt_url ?
                  <a target="_blank" href={props.record.receipt_url}> view invoice</a> : ''
              }
            </Col>
            <Col span={12}>
              <Form.Item
                name="API Response"
              >
                {
                  props.record.event ?
                    <JSONPretty data={props.record.event} theme={JSONPrettyMon}></JSONPretty> :
                    <Empty />
                }
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
}

export default PaymentModal
