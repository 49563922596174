import { Layout, Space, Button, Select, Table, Input, message, Row, Col, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getPlansAsync, exportPlansAsync, deletePlanAsync } from "../../reducers/plans/plansSlice";
import { PlusCircleOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import ExportButton from "../Components/exportButton";
import PlanModal from "./PlanModal";
import { selectUser } from "../../reducers/account/accountSlice";
import { getBaseSettingAsync } from "../../reducers/setting/settingSlice";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const Plans = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [dbs, setDbs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [database, setDatabase] = useState("");
  const [type, setType] = useState("");
  const { t, i18n } = useTranslation();
  let [modelVisible, setModelVisible] = useState(false);
  const [searchStr, setsearchStr] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any);
  const [record, setRecord] = useState({});

  const setCompany = (record: any) => {
    setRecord(record);
    setModelVisible(true);
  };

  useEffect(() => {
    fetchList(pagination);
    fetchSetting();
  }, []);

  const onSearch = async (value: string) => {
    setsearchStr(value);
    if (!value) {
      value = "NONE_STR";
    }
    fetchList(pagination, value);
  };

  const fetchSetting = async () => {
    setLoading(true);
    const result = await dispatch(getBaseSettingAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setDbs(data.dburls);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchList = async (params: any, search?: string) => {
    setLoading(true);
    params = params || pagination;
    params.searchStr = search || searchStr;
    params.type = params.type || type;
    params.database = params.database || database;

    !params.order && delete params.order;
    !params.sort && delete params.sort;
    const result = await dispatch(getPlansAsync(params));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        console.log("data", data.list);
        setData(data.list);
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total,
        });
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const deleteItem = async (id: string) => {
    setLoading(true);
    console.log("id", id);
    const result = await dispatch(
      deletePlanAsync({
        id,
      })
    );
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        fetchList({ ...pagination });
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field;
    changePagination.order = sorter.order;
    fetchList({ ...changePagination });
  };

  const columns = [
    {
      title: t("work.Name"),
      dataIndex: "name",
      sorter: true,
      width: "200px",
    },
    {
      title: t("work.Type"),
      dataIndex: "billing_type",
      width: "200px",
    },
    {
      title: t("work.Price"),
      dataIndex: "price",
      sorter: true,
      width: "200px",
      render: (price: string, row: any) => (
        <>
          <span>
            {row.price} {row.currency ? t(`currencies.${row.currency}`) : null}{" "}
          </span>
        </>
      ),
    },
    {
      title: t("work.Plan_duration"),
      dataIndex: "valid_months",
      sorter: true,
      width: "200px",
      render: (valid_months: string, row: any) => (
        <>
          <span>
            {row.valid_months + row.experience_months} {t("time.months")} {row.valid_days + row.experience_days} {t("time.days")}{" "}
          </span>
        </>
      ),
    },
    {
      title: t("work.Actions"),
      key: "actions",
      fixed: "right" as "right",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type="primary" size="small" onClick={() => setCompany(record)} key="1">
            {user.type === "admin" ? t("work.Edit") : t("work.View")}
          </Button>
        </Space>
      ),
    },
  ];
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setType(value);
    setDatabase("");
    fetchList({
      ...pagination,
      database: "all",
      type: value,
    });
  };

  const handleDatabaseChange = (value: string) => {
    console.log(`selected ${value}`);
    setDatabase(value);
    fetchList({
      ...pagination,
      database: value,
    });
  };

  return (
    <>
      <div className="eyerising-page-header">
        <div className="header-content">
          <Row justify="space-between">
            <Col span={4}>
              <Space>
                <Search placeholder={t("work.SearchName")} onSearch={onSearch} style={{ width: 200 }} />
                {user.type === "admin" ? (
                  <Select placeholder={t("work.FilterByPlanType")} style={{ width: 250 }} onChange={handleChange}>
                    <Option value="all">All</Option>
                    <Option value="company">Company</Option>
                    <Option value="database">Database</Option>
                  </Select>
                ) : (
                  ""
                )}
                {user.type === "admin" && type == "database" ? (
                  <Select placeholder={t("FilterByDatabase")} onChange={handleDatabaseChange} style={{ width: 200 }}>
                    <Option value="all">All</Option>
                    {dbs.map((co: any) => {
                      return (
                        <Option key={co.key} value={co.key}>
                          {co.name}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  ""
                )}
                <Button
                  className="table-btn"
                  key="2"
                  onClick={() =>
                    fetchList({
                      ...pagination,
                    })
                  }
                >
                  {t("work.Refresh")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space style={{ float: "right", marginRight: 10 }}>
                {user.type === "admin" ? (
                  <Button icon={<PlusCircleOutlined />} onClick={() => setCompany({})} type="primary">
                    {t("work.Create")}
                  </Button>
                ) : (
                  ""
                )}
                <ExportButton
                  exportApi={exportPlansAsync}
                  exportQuery={{
                    searchStr: searchStr,
                    type,
                  }}
                />
              </Space>
            </Col>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ["bottomLeft"],
            ...pagination,
            showTotal: (total) => {
              return `${t("work.Total")}: ${total}`;
            },
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <PlanModal record={record} setRecord={setCompany} visible={modelVisible} setVisible={setModelVisible} getList={() => fetchList(pagination)} />
    </>
  );
};

export default Plans;
