// A mock function to mimic making an async request for data
import api from '../../utils/api'

export function exportPayments(payload: any) {
  return api('/api/admin/payments/export', 'GET', payload)
}
export function exportPaymentsReport(payload: any) {
  return api('/api/admin/payments/report/export', 'GET', payload)
}
export function getPayments(payload: any) {
  return api('/api/admin/payments/list', 'GET', payload)
}

export function getPaymethodsAdmin(payload: any) {
  return api('/api/admin/paymethods', 'GET', payload)
}

export function refund(payload: any) {
  return api('/api/admin/payments', 'DELETE', payload)
}

export function getPayment(payload: any) {
  return api('/api/payment', 'GET', payload)
}

export function createPaymentAdmin(payload: any) {
  return api('/api/admin/payments', 'POST', payload)
}

export function createPayment(payload: any) {
  return api('/api/admin/payments', 'POST', payload)
}
