import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getPayments,
  exportPayments,
  exportPaymentsReport,
  getPayment,
  refund,
  createPaymentAdmin,
  createPayment,
  getPaymethodsAdmin
} from './paymentsAPI';
const initialState = {}



export const createPaymentAdminAsync = createApiAsyncThunk<any>('payments/createPaymentAdmin', createPaymentAdmin)
export const createPaymentAsync = createApiAsyncThunk<any>('customers/createPaymentLink', createPayment)
export const getPaymentAsync = createApiAsyncThunk<any>('payments/getPayment', getPayment)
export const getPaymentsAsync = createApiAsyncThunk<any>('payments/getPayments', getPayments)
export const getPaymethodsAdminAsync = createApiAsyncThunk<any>('payments/getPaymethodsAdmin', getPaymethodsAdmin)
export const exportPaymentsASync = createApiAsyncThunk<any>('payments/exportPayments', exportPayments)
export const exportPaymentsReportAsync = createApiAsyncThunk<any>('payments/exportPaymentsReport', exportPaymentsReport)


export const refundAsync = createApiAsyncThunk<any>('payments/refund', refund)



export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {}
})

export default paymentsSlice.reducer;
