import { useState, useEffect } from 'react'
import { Form, Input, Modal, Button, message, Row, Select, Col, InputNumber } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import {
  addCatalogAsync,
} from '../../reducers/catalogs/catalogsSlice'
import {
  getAllReleasesAsync,
} from '../../reducers/versions/versionsSlice'

import PhotoUploader from '../Components/PhotoUploader'
import './Common.scss'
import { useTranslation } from 'react-i18next';
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const { Option } = Select;
const CatalogModal = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [form] = Form.useForm()
  const { t, i18n } = useTranslation()
  const [releases, setReleases] = useState([])
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }
  const fetchAllVersions = async() => {
    setLoading(true)
    const result = await dispatch(getAllReleasesAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setReleases(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const onFinish = async() => {
    form.validateFields()
      .then(async values => {
        values.id = props.record._id
        values.imageUrl = imageUrl
        const result = await dispatch(addCatalogAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            message.success('Successful operation');
            closeModal()
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  useEffect(() => {
    form.resetFields()
    setImageUrl('')
    fetchAllVersions()
    if (props.record._id) {
      let record = props.record
      setImageUrl(record.imageUrl)
      form.setFieldsValue(record)
    }
  }, [props.record._id, props.visible])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width='900px'
          title={props.record._id ? t('work.Edit_product_catalog') : t('work.Create_product_catalog')}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              {t("work.Save")}
            </Button>,
            <Button key="back" onClick={closeModal}>
              {t("work.Cancel")}
            </Button>
          ]}
        >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item name="name"  label={t('work.Product_Name')} rules={[{ required: true, message: t('work.Product_Name_Text') }]}>
              <Input placeholder="" />
            </Form.Item>
            <Form.Item label={t('work.Internal_Reference')} name="reference" rules={[{ required: true, message: t('work.Internal_Reference_Text') }]}>
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              name="versionId"
              label={t('work.Version')}
              rules={[{ required: true, message: 'Please select version!' }]}
            >
              <Select placeholder="" showSearch optionFilterProp="label">

                {
                  releases.map((co: any) => {
                    return (
                      <Option key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label={t('work.Width')}  name="width">
              <Input placeholder="" />
            </Form.Item>
            <Form.Item label={t('work.Height')}  name="height" >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <PhotoUploader
            defaultUrl={imageUrl} uploadedCb={(url: string) => setImageUrl(url)}
            ></PhotoUploader> */}
            <Form.Item name="duration"
              label={t('work.Duration')}
              rules={[{
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
               }]}
              >
              <InputNumber placeholder="" />
            </Form.Item>
            <Form.Item name="intervals"
              label={t('work.Intervals')}
              rules={[{
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
               }]}
              >
              <InputNumber placeholder="" />
            </Form.Item>
            <Form.Item name="timesPerDay"
              label={t('work.TimesperDay')}
              rules={[{
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
               }]}
              >
              <InputNumber placeholder="" />
            </Form.Item>
            <Form.Item name="timesPerWeek"
              label={t('work.TimesperWeek')}
              rules={[ {
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
               }]}
              >
              <InputNumber placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        </Modal>
      </Form>
    </>
  );
}

export default CatalogModal
