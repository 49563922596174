import { Form, Input, Button, message, Modal, Card } from 'antd';
import md5 from 'js-md5'
import { useAppDispatch } from '../../app/hooks'
import { useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useQuery } from '../../utils/query';
import { useTranslation } from 'react-i18next';
import { setForgotPasswordAsync } from '../../reducers/portal/portalSlice'
const Setting = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const query:any = useQuery()
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      setLoading(true)
      const params: any = {
        password: md5(values.newPassword)
      }
      params.key = query.key
      params.db = query.db
      const result = await dispatch(setForgotPasswordAsync(params))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, msg } = payload
        if (code === 0) {
          message.success('Successful operation')
          setTimeout(() => {
            history.push('/login')
          }, 500)
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='portal-signin'>
    <Form
      form={form}
      className="signin-form"
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 15,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="logo-box">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>
          {t('work.Resetpassword')}
        </h1>
      </div>
      <Form.Item
        label={t('work.newPassword')}
        name="newPassword"
        rules={[
          {
            required: true,
            message: t('work.password_message')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && value.length >= 8) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('work.passwordlengthText')));
            },
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
              if (reg.test(value)){
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('work.passwordText')));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={t('work.Confirmpassword')}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: t('work.confirm_message')

          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('work.The_two_passwords_message')));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 24,
        }}
      >
        <Button loading={loading} style={{width: '100%'}} className="sign-submit" type="primary" htmlType="submit">
          {t('work.Submit')}
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
};

export default Setting