import { Form, Input, Button, message, Modal, Select, Row, Col, Tag, Card, Table } from 'antd';
import { useAppDispatch } from '../../app/hooks'
import type { ColumnsType } from 'antd/es/table';
import type { CustomTagProps, } from 'rc-select/lib/BaseSelect';
import config from '../../config/config'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { addSettingAsync, getSettingsAsync, checkDatabaseAsync } from '../../reducers/setting/settingSlice'
const Setting = () => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  useEffect(() => {
    checkDatabase()
    fetchList()
  }, [])
  const options = config.currencies
  const onFinish = async () => {
    form.validateFields().then(async (values: any) => {
      setLoading(true)
      const params: any = values
      const result = await dispatch(addSettingAsync(params))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, msg } = payload
        if (code === 0) {
          message.success('Successful operation')
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }

  const checkDatabase = async () => {
    setLoading(true)
    const result = await dispatch(checkDatabaseAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.dburls)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const fetchList = async () => {
    setLoading(true)
    const result = await dispatch(getSettingsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        form.setFieldsValue(data.setting)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };



  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Database Name',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => {
        return status === 'success' ? <span style={{
          color: 'green'
        }}>
          <CheckCircleOutlined />
        </span> : <span style={{
          color: 'red'
        }}>
          <CloseCircleOutlined />
        </span>
      }
    },
  ];


  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 12,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

      <Row>
        <Col span={11}>
          <Card size="small" title="Currencies" style={{ width: '100%' }}>
            <Form.Item
              label=""
              name="currencies"
              rules={[
                {
                  required: true,
                  message: 'Please input your old password!',
                },
              ]}
            >
              <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                style={{ width: '100%' }}
                options={options}
              />
            </Form.Item>
          </Card>
          <Card size="small" title="Auto email" style={{ marginTop: 40, width: '100%' }}>
            <Form.Item
              label="Name"
              name="emailName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t(`work.Email_address`)}
              name="email"
              rules={[{ required: true }, {
                type: 'email', message: t('work.EmailType')
              }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t(`work.Email_password`)}
              name="emailPassword"
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t(`work.Connection_host`)}
              name="emailHost"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={11} offset={1}>
          <Button style={{ float: 'right', marginBottom: 10 }} onClick={checkDatabase}>Database healthcheck</Button>
          <Table columns={columns} dataSource={data} size="middle"
            pagination={false} />
        </Col>

      </Row>
      <Form.Item
        style={{
          marginTop: 20
        }}
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <Button
          style={{
            width: 200
          }} loading={loading} type="primary" htmlType="submit">
          {
            t('work.Save')
          }
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Setting