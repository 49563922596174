import { Layout, Space, Button, Select, Table, Input, message, Row, Col, DatePicker, Modal } from 'antd';
import { useEffect, useState } from 'react';
import config from '../../config/config'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getPaymentsAsync,
  refundAsync,
  exportPaymentsReportAsync,
  exportPaymentsASync
} from '../../reducers/payments/paymentsSlice'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import ExportButton from '../Components/exportButton'
import PaymentModal from './PaymentModal'
import { getBaseSettingAsync } from '../../reducers/setting/settingSlice'
import {
  selectUser
} from '../../reducers/account/accountSlice';
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Payments = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { t, i18n } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dbs, setDbs] = useState([])
  const [db, setDb] = useState('jp')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [amount, setAmount] = useState(0)
  const [status, setStatus] = useState('all')
  let [modelVisible, setModelVisible] = useState(false)
  const [searchStr, setsearchStr] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [record, setRecord] = useState({} as any)

  const setCompany = (record: any) => {
    setRecord(record)
    setModelVisible(true)
  }
  useEffect(() => {
    fetchSetting()
    fetchList(pagination)
  }, [])


  const onSearch = async (value: string) => {
    setsearchStr(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList(pagination, value)
  }

  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const refund = async () => {
    setLoading(true)
    const result = await dispatch(refundAsync({
      id: record._id,
      amount: amount
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({
          ...pagination
        })
        setIsModalOpen(false)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setDb(value)
    fetchList({
      ...pagination,
      db: value
    })
  }
  const handleRangeChange = (value: any) => {
    setStartDate(value[0].toDate())
    setEndDate(value[1].toDate())
    fetchList({
      ...pagination,
      startDate: value[0].toDate(),
      endDate: value[1].toDate(),
    })
  }
  const handleStatusChange = (value: string) => {
    console.log(`selected ${value}`);
    setStatus(value)
    fetchList({
      ...pagination,
      status: value
    })
  };

  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    params.db = params.db || db
    params.startDate = params.startDate || startDate
    params.endDate = params.endDate || endDate
    params.status = params.status || status
    !params.order && delete params.order
    !params.sort && delete params.sort
    for (let key in params) {
      if (params[key] === 'NONE_STR') {
        params[key] = ''
      }
    }

    const result = await dispatch(getPaymentsAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }
  const columns = [
    {
      title: t('work.Date'),
      dataIndex: 'createdAt',
      width: '200px',
      sorter: true,
      render: (createdAt: string) => (
        <>
          <span>{moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
        </>
      ),
    },
    {
      title: t('work.Username'),
      dataIndex: 'customer.name',
      width: '200px',
      render: (item: string, row: any) => (
        <>
          <span>{row.customer && row.customer.email}</span>
        </>
      ),
    },
    {
      title: t('work.DeviceLoginUsername'),
      dataIndex: 'customer.name',
      width: '200px',
      render: (item: string, row: any) => (
        <>
          <span>{row.account && row.account.username}</span>
        </>
      ),
    },
    {
      title: t('work.Company'),
      dataIndex: 'company.name',
      width: '200px',
      render: (item: string, row: any) => (
        <>
          <span>{row.company && row.company.name}</span>
        </>
      ),
    },
    {
      title: t('work.Plan'),
      dataIndex: 'planName',
      width: '200px',
      render: (item: string, row: any) => (
        <>
          <span>{row.plan && row.plan.name}</span>
        </>
      ),
    },
    {
      title: t('work.Price'),
      dataIndex: 'Price',
      width: '200px',
      render: (item: string, row: any) => (<span>{row.currency || ''} {row.amount || ''}</span>),
    },
    {
      title: t('work.Status'),
      dataIndex: 'status',
      sorter: true,
      width: '150px',
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type='primary' size='small' onClick={() => setCompany(record)} key="1">
            {t('work.View')}
          </Button>
          {record.status == 'succeeded' ?
            <Button type='primary' size='small' onClick={() => window.open(`${config.portalDomain}/receipt?id=${record._id}`)} key="1">
              {t('work.Receipt')}
            </Button> : ''}
          {
            record.status == 'succeeded' && user.type === 'admin' ?
              <Button size='small' onClick={() => {
                setRecord(record)
                setIsModalOpen(true)
                setAmount(record.amount)
              }

              } key="refund">
                {t('work.Refund')}
              </Button> : ''
          }
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className='eyerising-page-header'>
        <Modal title="Bind device" open={isModalOpen} onOk={refund} onCancel={() => setIsModalOpen(false)}>
          <Input onChange={(v: any) => setAmount(v.target.value)} value={amount} placeholder={t('work.Amount')} />
        </Modal>
        <div className='header-content' >
          <Row justify="space-between">
            <Col span={10}>
              <Space>
                {user.type === 'admin' ?
                  <Select defaultValue={db} style={{ width: 200 }} placeholder="" onChange={handleChange}>

                    {
                      dbs.map((co: any) => {
                        return (
                          <Option key={co.key} value={co.key}>{co.name}</Option>)
                      })
                    }
                  </Select> : ''}
                <RangePicker onChange={handleRangeChange} style={{ width: 250 }} />
                <Select defaultValue={status} style={{ width: 200 }} placeholder="" onChange={handleStatusChange}>
                  <Option value="all">All status</Option>
                  <Option value="succeeded">succeeded</Option>
                  <Option value="refunded">refunded</Option>
                </Select>
                <Search placeholder={t('work.SearchUserEmail')} onSearch={onSearch} style={{ width: 300 }} />
                <Button className='table-btn' key="2" onClick={() =>
                  fetchList({
                    ...pagination
                  })}> {t('work.Refresh')}</Button>
              </Space>
            </Col>
            <Col span={4}>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }} justify="space-between">
            <Col span={4}>
              <Space >
                <ExportButton exportApi={exportPaymentsASync} exportQuery={{
                  searchStr: searchStr,
                  db: db,
                  status: status,
                  startDate: startDate,
                  endDate: endDate,
                }} />
                <ExportButton exportApi={exportPaymentsReportAsync}

                  text="Export report"
                  exportQuery={{
                    startDate: startDate,
                    endDate: endDate,
                    searchStr: searchStr,
                    db: db
                  }} />
              </Space>

            </Col>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <PaymentModal
        record={record}
        setRecord={setCompany}
        visible={modelVisible}
        setVisible={setModelVisible}
        getList={() => fetchList(pagination)}
      />
    </>
  )
}


export default Payments;