import { useState, useEffect } from 'react'
import { Form, Input, Modal, Button, message, Row, Select, Col, DatePicker, Checkbox } from 'antd'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import {
  signout,
  selectUser
} from '../../reducers/account/accountSlice';
import {
  updateDeviceStatusAsync,
} from '../../reducers/devices/devicesSlice'
import {
  getAllCatalogsAsync
} from '../../reducers/catalogs/catalogsSlice'
import {
  getAllCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import {
  getAllUsersAsync
} from '../../reducers/users/usersSlice'
import { useTranslation } from 'react-i18next';
import { getBaseSettingAsync } from '../../reducers/setting/settingSlice'
import moment from 'moment'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
  action: string;
}
const { Option } = Select;
const InventoryModal = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const user = useAppSelector(selectUser)
  const [form] = Form.useForm()
  const [dbs, setDbs] = useState([])
  const { t, i18n } = useTranslation()
  const [db, setDb] = useState('jp')
  const [companies, setCompanies] = useState([])
  const [customers, setCustomers] = useState([])
  const [products, setProducts] = useState([])
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }
  const fetchList = async () => {
    setLoading(true)
    const result = await dispatch(getAllCompaniesAsync({
      other: true
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCompanies(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const fetchCustomersList = async (dbs?: string) => {
    let obj = {
      companyId: form.getFieldValue('companyId')
    }

    setLoading(true)
    const result = await dispatch(getAllUsersAsync(obj))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCustomers(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
        // form.setFieldsValue(data.setting)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const fetchAllProducts = async () => {
    setLoading(true)
    const result = await dispatch(getAllCatalogsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setProducts(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const onFinish = async () => {
    form.validateFields()
      .then(async values => {
        values.id = props.record._id
        values.actionDate = values.actionDate.toDate()
        values.action = props.action
        const result = await dispatch(updateDeviceStatusAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            message.success('Successful operation');
            closeModal()
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  useEffect(() => {
    form.resetFields()
    fetchSetting()
    const record = props.record
    if (props.record._id) {
      record.actionDate = moment()
      form.setFieldsValue(props.record)
    }
    // if (['recall'].indexOf(props.action) > -1) {
    //   form.setFieldValue('companyId', null)
    // }
    if (['distribute', 'transfer', 'receive', 'repair', 'recall', 'accept', 'service', 'reload', 'discard'].indexOf(props.action) > -1) {
      form.setFieldValue('customerId', null)
    }
    if (['distribute', 'collect', 'suspend', 'resume', 'transfer', 'service', 'reload', 'lose', 'find', 'discard'].indexOf(props.action) > -1) {
      form.setFieldValue('companyId', props.record.companyId)
    }
    // if (['receive'].indexOf(props.action) > -1) {
    // form.setFieldValue('toCompanyId', user.companyId)
    // }
    if (['discard', 'distribute', 'collect', 'recall', 'suspend', 'resume', 'transfer', 'repair', 'accept', 'service', 'reload', 'lose', 'find', 'discard'].indexOf(props.action) > -1) {
      form.setFieldValue('toCompanyId', null)
    }
    if (['transfer', 'receive'].indexOf(props.action) > -1) {
      form.setFieldValue('logging', ['Company', 'Tocompany'])
    }
    if (['repair', 'accept', 'service', 'refresh', 'discard', 'recall'].indexOf(props.action) > -1) {
      form.setFieldValue('logging', ['Company'])
    }
    if (['lose', 'find', 'distribute', 'collect'].indexOf(props.action) > -1) {
      form.setFieldValue('logging', ['Customer', 'Company'])
    }
    if (['suspend', 'resume'].indexOf(props.action) > -1) {
      form.setFieldValue('logging', ['Customer'])
    }
    fetchAllProducts()
    fetchList()
    fetchCustomersList()
  }, [props.record._id, props.action, props.visible])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setDb(value)
    fetchCustomersList(value)
  };

  const customerDisabled = ['distribute'].indexOf(props.action) == -1
  const companyIdDisabled = (props.action === 'recall' || ([''].indexOf(props.action) == -1))
  const toCompanyIdDisabled = (['transfer'].indexOf(props.action) == -1)
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width='450px'
          title={`Inventory: ${props.action}`}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              {t('work.Save')}
            </Button>,
            <Button key="back" onClick={closeModal}>
              {t('work.Cancel')}
            </Button>
          ]}
        >
          <Row gutter={0}>
            <Col span={24}>
              <Form.Item
                name="productId"
                label={t('work.Product')}
              >

                <Select placeholder="" disabled>

                  {
                    products.map((co: any) => {
                      return (
                        <Option key={co._id} value={co._id}>{co.reference}</Option>)
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item name="serial_number" label="Serial Number">
                <Input disabled placeholder="" />
              </Form.Item>
              <Form.Item
                name="companyId"
                label={t('work.Company')}
                rules={[{ required: !companyIdDisabled, message: t('work.Please_select_company') }]}
              >
                <Select placeholder="" showSearch optionFilterProp="label"
                  onChange={fetchCustomersList}
                  disabled={
                    companyIdDisabled
                  }>

                  {
                    companies.map((co: any) => {
                      return (
                        <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="toCompanyId"
                label={t('work.Tocompany')}
                rules={[{ required: !toCompanyIdDisabled, message: t('work.Please_select_company') }]}

              >
                <Select placeholder="" showSearch
                  optionFilterProp="label"
                  disabled={
                    toCompanyIdDisabled
                  }>

                  {
                    companies.map((co: any) => {
                      return (
                        <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="customerId"
                label={t('work.Customer')}
                rules={[{ required: !customerDisabled, message: t('work.Please_select_customer') }]}
              >
                <Select placeholder=""
                  disabled={customerDisabled}
                  optionFilterProp="label"
                  showSearch>

                  {
                    customers.map((co: any) => {
                      return (
                        <Option key={co._id} value={co._id}>{co.firstName} {co.middleName} {co.lastName}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item label={t('work.Date')} name="actionDate" >
                <DatePicker showTime disabled style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label={t('work.Logging')} name="logging">
                <Checkbox.Group disabled style={{ width: '100%' }}>
                  <Row>
                    <Checkbox value="Customer">{t("work.Customer")}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="Company">{t("work.Company")}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="Tocompany">{t("work.Tocompany")}</Checkbox>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item name="notes" label={t('work.Notes')}>
                <Input.TextArea placeholder="" />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
}

export default InventoryModal
