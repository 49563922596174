import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import accountSlice from '../reducers/account/accountSlice'
import usersSlice from '../reducers/users/usersSlice'
import companiesSlice from '../reducers/companies/companiesSlice'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { History, createBrowserHistory } from 'history'
export const history = createBrowserHistory()

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  account: accountSlice,
  // admin
  users: usersSlice,
  companies: companiesSlice
})

export const store = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    routerMiddleware(history),
  ])
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
