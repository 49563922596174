// A mock function to mimic making an async request for data
import api from '../../utils/api'


export function updateCustomerInfo(payload: any) {
  return api('/api/portal/user', 'PUT', payload)
}
export function updateCustomerAddress(payload: any) {
  return api('/api/portal/user/address', 'PUT', payload)
}

export function getNotifications(payload: any) {
  return api('/api/portal/notifications', 'GET', payload)
}
export function deleteNotifications(payload: any) {
  return api('/api/portal/notifications', 'DELETE', payload)
}
export function readNotifications(payload: any) {
  return api('/api/portal/notifications', 'PUT', payload)
}

export function getAccountsList(payload: any) {
  return api('/api/portal/accounts', 'GET', payload)
}
export function addAccount(payload: any) {
  return api('/api/portal/accounts', 'POST', payload)
}

export function deleteAccount(payload: any) {
  return api('/api/portal/accounts', 'DELETE', payload)
}


export function getOrders(payload: any) {
  return api('/api/portal/orders', 'GET', payload)
}

export function getPlans(payload: any) {
  return api('/api/portal/plans', 'get', payload)
}

export function getDoctors(payload: any) {
  return api('/api/portal/doctors', 'get', payload)
}

export function turnOffRenew(payload: any) {
  return api('/api/portal/subscrition', 'DELETE', payload)
}


export function getPayments(payload: any) {
  return api('/api/portal/payments', 'get', payload)
}

export function exportPayments(payload: any) {
  return api('/api/portal/payments/export', 'get', payload)
}

export function bindDevice(payload: any) {
  return api('/api/portal/device', 'post', payload)
}

export function lostDevice(payload: any) {
  return api('/api/portal/device', 'PUT', payload)
}
export function foundDevice(payload: any) {
  return api('/api/portal/device/find', 'PUT', payload)
}

export function getRecords(payload: any) {
  return api('/api/portal/records', 'GET', payload)
}

export function sendStaffEmail(payload: any) {
  return api('/api/portal/device/reasons', 'post', payload)
}


export function getPaymethods(payload: any) {
  return api('/api/portal/paymethods', 'GET', payload)
}


export function createPayment(payload: any) {
  return api('/api/portal/payments', 'POST', payload)
}

export interface passwordPayload {
  newPassword: string;
  oldPassord: string
}
export function resetPassword(payload: passwordPayload) {
  return api('/api/portal/user/password', 'PUT', payload)
}
export function forgotPassword(payload: any) {
  return api('/api/portal/user/forgotpassword', 'POST', payload)
}
export function setForgotPassword(payload: any) {
  return api('/api/portal/user/forgotpassword', 'PUT', payload)
}