import logo from '../../../assets/logo2.png'
import './SignIn.scss'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Modal, Select, message } from 'antd'
import { useAppDispatch } from '../../../app/hooks'

import { fetchPortalAccountAysnc, fetchPortalAccountByTokenAsync, restore, restoreUser } from '../../../reducers/account/accountSlice';
import ReCAPTCHA from "react-google-recaptcha";
import { getBaseSettingAsync } from '../../../reducers/setting/settingSlice'

import { useQuery } from '../../../utils/query';
import md5 from 'js-md5'
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const SignIn = () => {
  let siteKey = '6LcxbsIdAAAAAAzKDEM6QpIVAC1RzCUY9ahYeqoo'
  const dispatch = useAppDispatch();
  const history = useHistory()
  const query: any = useQuery()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();
  const [form] = Form.useForm()
  const recaptchaRef = useRef<any>()
  const [dbs, setDbs] = useState([])
  const { Option } = Select;

  const onFinish = async (values: any) => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    values.password = md5(values.password)
    values['g-recaptcha-response'] = recaptchaValue
    values.type = 'portal'
    setLoading(true)
    const result = await dispatch(fetchPortalAccountAysnc({
      ...values,
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { data, code, msg } = payload
      if (code === 0) {
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString(),
          db: values.db
        }))
        dispatch(restoreUser({ user: data.user }))
        localStorage.setItem('token', data.token)
        localStorage.setItem('db', values.db)
        localStorage.setItem('tokenExpiredAt', data.tokenExpiredAt.getTime())
        history.push('/index/device')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const signInByToken = async () => {
    const token = localStorage.getItem('token')
    if (token) {
      const result = await dispatch(fetchPortalAccountByTokenAsync({}))
      const { payload } = result
      if (payload) {
        const { data, code, msg } = payload
        if (code === 0) {
          dispatch(restore({
            token: data.token,
            tokenExpiredAt: data.tokenExpiredAt.toString()
          }))
          dispatch(restoreUser({ user: data.user }))
          history.push('/admin')
        } else {
        }
      } else {
      }
    } else {
    }
  }

  useEffect(() => {
    form.setFieldValue('db', t('work.SelectCountry'))
    if (query.db) {
      form.setFieldValue('db', query.db)
    }
    fetchSetting()
    signInByToken()
  }, [])

  return (
    <div className='portal-signin'>

      <Form
        className="signin-form"
        name="basic"
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="logo-box">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>
            {t('sign.PortalLogin')}
          </h1>
        </div>
        <Form.Item
          className="sign-input"
          label={t('work.Country')}
          name="db"
          rules={[{ required: true }]}
        >
          <Select placeholder="" >
            {
              dbs.map((co: any) => {
                return (
                  <Option key={co.key} value={co.key}>{co.name}</Option>)
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          className="sign-input"
          label={t('sign.Email')}
          name="email"
          rules={[{ required: true, message: t('signup.Please_input_your_email') }, {
            type: 'email', message: t('work.EmailType')
          }]}
        >
          <Input />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Link to='/login/forgotpassword' type="link" style={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}>
            {t('sign.Forgot_password')}
          </Link>
          <Form.Item
            className="sign-input"
            label={t('sign.Password')}
            name="password"
            rules={[{ required: true, message: t('sign.Please_input_your_password') }]}
          >
            <Input.Password />
          </Form.Item>
        </div>
        {
          process.env.NODE_ENV === 'production' && !loading ? (
            <Form.Item
              className="sign-input sign-captch"
              label="Captcha"
              // name="captcha"
              rules={[{ required: true, message: t('sign.Please_input_captcha') }]}
            >
              <ReCAPTCHA
                sitekey={siteKey}
                ref={recaptchaRef}
              />
            </Form.Item>) : ''
        }
        <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          <Button style={{ width: '100%', height: '50px' }} loading={loading} type="primary" htmlType="submit">
            {t('sign.Login')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SignIn;
