import PatientModalForm from "./PatientModalForm";
import Requests from "./Requests";
import Usage from "./Usage";
import Accounts from "./Accounts";
import AccountModalForm from "../Portal/AccountModalForm";
import Payment from "./Payment";
import { Form, Tabs, Modal, Button, message, Row, Divider, Col, Collapse, Space, Popconfirm } from "antd";
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import ReportChart from "./ReportChart";
import moment from "moment";
import md5 from "js-md5";
import { getAllCatalogsAsync } from "../../reducers/catalogs/catalogsSlice";
import { getAccountsAsync } from "../../reducers/customers/customersSlice";
import { updateCustomerAsync, deleteAccountAsync } from "../../reducers/customers/customersSlice";
import { getAllCompaniesAsync } from "../../reducers/companies/companiesSlice";
import { getAllPlansAsync } from "../../reducers/plans/plansSlice";
import { getAllDoctorsAsync } from "../../reducers/users/usersSlice";
import _ from "lodash";

interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  db: string;
  mkey: string;
  setKey: Function;
  getList: Function;
}
const { Panel } = Collapse;

const PatientModal = (props: propsObject) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState([]);
  const [plans, setPlans] = useState([]);
  const [newPatient, setNewPatient] = useState({});
  const [chartVisible, setChartVisible] = useState(false);
  const [mobileCountryCode, setMobileCountryCode] = useState("81");
  const [doctors, setDoctors] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [companies, setCompanies] = useState([]);
  const [accounts, setAccounts] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [newPatientVisible, setPatientVisible] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [form] = Form.useForm();
  const [record, setRecord] = useState({});
  const [address, setAddress] = useState(null as any);
  const [open, setOpen] = useState(false);

  const onChange = (key: string) => {
    props.setKey(key);
  };

  const closeModal = () => {
    props.setVisible(false);
    props.getList();
  };
  const [timezoneName, setTimezoneName] = useState("America/Los_Angeles");

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback((newTimeZoneName: string) => {
    console.log(newTimeZoneName);
    setTimezoneName(newTimeZoneName);
    setOpen(false);
  }, []);

  const [center, setCenter] = useState({
    lat: -33.851923390266165,
    lng: 151.21838655370252,
  });

  const fetchList = async () => {
    setLoading(true);
    const result = await dispatch(getAllCompaniesAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setCompanies(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const addPatient = async () => {
    setPatientVisible(true);
  };

  const fetchAccounts = async () => {
    setLoading(true);
    const params = {
      id: props.record._id,
      db: props.db,
    };
    const result = await dispatch(getAccountsAsync(params));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setAccounts(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchDoctors = async (companyId?: string) => {
    setLoading(true);
    const parmas = {
      companyId: companyId ? companyId : props.record.companyId,
    };
    const result = await dispatch(getAllDoctorsAsync(parmas));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setDoctors(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchAllProducts = async () => {
    setLoading(true);
    const result = await dispatch(getAllCatalogsAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setProducts(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchAllPlans = async () => {
    setLoading(true);
    const result = await dispatch(getAllPlansAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setPlans(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };
  const deleteItem = async (id: string) => {
    setLoading(true);
    const result = await dispatch(
      deleteAccountAsync({
        id,
        db: props.db,
      })
    );
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        fetchAccounts();
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        if (!timezoneName) {
          return message.error(t("work.SelctTimezone"));
        }
        values.timezone = timezoneName;
        values.id = props.record._id;
        values.mobileCountryCode = mobileCountryCode;
        values.resetPassword = resetPassword;
        values.db = props.db;
        if (address) {
          values.address = address.label ? address.label : "";
          values.location = center;
        }

        if (values.needRequired) {
          if (!address || !address.label) {
            if (props.record.address) {
              values.address = props.record.address;
            } else {
              return message.error("Please select address");
            }
          }
        }
        try {
          if (values.password) {
            values.password = md5(values.password);
          }
        } catch (err) {
          console.log(err);
        }
        const parmas = Object.assign({}, props.record, values);
        const result = await dispatch(updateCustomerAsync(parmas));
        setLoading(false);
        const { payload } = result;
        if (payload) {
          const { code, msg, data } = payload;
          if (code === 0) {
            message.success("Successful operation");
            closeModal();
            if (!values.id) {
              props.setRecord(data.user);
              props.setVisible(true);
            }
          } else if (msg) {
            Modal.error({
              title: "Error",
              content: msg,
            });
          }
        }
        if (typeof payload === "string") {
          Modal.error({
            title: "Error",
            content: payload,
          });
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldValue("password", "");
    setResetPassword(false);
    setAddress("Search address");
    setTimezoneName(props.record.timezone || "");
    setCenter({
      lat: -33.851923390266165,
      lng: 151.21838655370252,
    });
    if (props.record._id) {
      let record = props.record;
      fetchAccounts();
      fetchDoctors();
      record.birthDate = moment(record.birthDate);
      record.expiredDate = moment(record.expiredDate);
      record.registerDate = moment(record.registerDate);
      props.record.location && setCenter(props.record.location);
      props.record.address && setAddress(props.record.address);
      record.auto_renew = record.auto_renew ? "Yes" : "No";
      setMobileCountryCode(props.record.mobileCountryCode || "81");
      form.setFieldsValue(props.record);
    }
    fetchList();
    fetchAllProducts();
    fetchAllPlans();
  }, [props.record._id, props.visible]);

  const items = [
    {
      label: t("work.Customer"),
      key: "1",
      children: (
        <>
          <PatientModalForm
            form={form}
            db={props.db}
            open={open}
            timezoneName={timezoneName}
            setTimezoneName={setTimezoneName}
            handleClose={handleClose}
            handleOpen={handleOpen}
            mobileCountryCode={mobileCountryCode}
            setMobileCountryCode={setMobileCountryCode}
            getDoctors={fetchDoctors}
            companies={companies}
            doctors={doctors}
            resetPassword={resetPassword}
            setResetPassword={setResetPassword}
            record={props.record}
            setRecord={props.setRecord}
            setVisible={props.setVisible}
            getList={props.getList}
            center={center}
            address={address}
            setCenter={setCenter}
            setAddress={setAddress}
          />
          {props.record._id ? (
            <>
              <Divider orientation="left">{t("work.Patients")}</Divider>
              <Button htmlType="submit" style={{ marginBottom: 20 }} key="submit" loading={loading} onClick={addPatient}>
                {t("work.AddPatient")}
              </Button>
              <ReportChart record={record} setRecord={setRecord} visible={chartVisible} setVisible={setChartVisible} companies={companies} />
              <Collapse
                accordion
                onChange={(value: any) => {
                  setActiveKey(value);
                }}
                activeKey={activeKey}
              >
                {accounts &&
                  accounts.map((account: any) => (
                    <Panel
                      header={account._id ? `${account.firstName || ""} ${account.lastName || ""}` : t("work.NewPatient")}
                      key={account._id || "new_patient"}
                    >
                      <AccountModalForm
                        from="admin"
                        db={props.db}
                        companyId={props.record.companyId}
                        customerId={props.record._id}
                        record={account}
                        doctors={doctors}
                        plans={plans}
                        fetchAccounts={fetchAccounts}
                        visible={props.visible}
                        deleteItem={deleteItem}
                        companies={companies}
                      />
                    </Panel>
                  ))}
              </Collapse>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  if (props.record._id) {
    items.push(
      {
        label: t("work.Payments"),
        key: "2",
        children: (
          <Payment
            products={products}
            form={form}
            record={props.record}
            setRecord={props.setRecord}
            setVisible={props.setVisible}
            getList={props.getList}
            db={props.db}
          />
        ),
      },
      {
        label: t("work.Usage"),
        key: "3",
        children: (
          <Usage
            products={products}
            plans={plans}
            form={form}
            record={props.record}
            setRecord={props.setRecord}
            setVisible={props.setVisible}
            getList={props.getList}
          />
        ),
      },
      {
        label: t("work.Details"),
        key: "4",
        children: (
          <Accounts
            form={form}
            record={props.record}
            setRecord={props.setRecord}
            accounts={accounts}
            setVisible={props.setVisible}
            getList={props.getList}
            db={props.db}
          />
        ),
      },
      {
        label: t("work.Request"),
        key: "5",
        children: (
          <Requests
            products={products}
            plans={plans}
            form={form}
            record={props.record}
            setRecord={props.setRecord}
            setVisible={props.setVisible}
            getList={props.getList}
          />
        ),
      }
    );
  }

  return (
    <>
      <Modal
        className="add-user-modal"
        visible={newPatientVisible}
        width="900px"
        title={t("work.CreateAccount")}
        onCancel={() => setPatientVisible(false)}
        footer={[]}
      >
        <AccountModalForm
          from="admin"
          db={props.db}
          companyId={props.record.companyId}
          customerId={props.record._id}
          record={newPatient}
          closeModal={() => {
            setNewPatient({});
            setPatientVisible(false);
          }}
          doctors={doctors}
          plans={plans}
          fetchAccounts={fetchAccounts}
          visible={newPatientVisible}
        />
      </Modal>
      <Modal
        className="add-user-modal"
        open={props.visible}
        width="1400px"
        footer={null}
        title={props.record._id ? t("work.EditCustomer") : t("work.CreateCustomer")}
        onCancel={closeModal}
      >
        <Tabs onChange={onChange} items={items} activeKey={props.mkey} type="card" />
        <Row style={{ marginTop: 30 }}>
          <Space>
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              {t("work.Save")}
            </Button>
            <Button key="back" onClick={() => closeModal()}>
              {t("work.Cancel")}
            </Button>
          </Space>
        </Row>
      </Modal>
    </>
  );
};

export default PatientModal;
