import { Layout, Space, Button, Popconfirm, Table, Tag, Input, message, Row, Col, Select, Popover } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllCompaniesAsync } from "../../reducers/companies/companiesSlice";
import ReportChart from "./ReportChart";
import {
  deleteCustomerAsync,
  exportCustomersAsync,
  lockCustomerAsync,
  unlockCustomerAsync,
  getCustomersAsync,
  debugCustomerAsync,
  closeRquestAsync,
  closeDebugCustomerAsync,
  getAllDoctorsAsync,
} from "../../reducers/users/usersSlice";
import { selectUser } from "../../reducers/account/accountSlice";
import { useTranslation } from "react-i18next";
import { getBaseSettingAsync } from "../../reducers/setting/settingSlice";
import { PlusCircleOutlined } from "@ant-design/icons";
import ExportButton from "../Components/exportButton";
import PatientModal from "./PatientModal";
import "./Common.scss";
import moment from "moment";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const Patients = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [chartVisible, setChartVisible] = useState(false);
  const [account, setAccount] = useState({});
  const [data, setData] = useState([]);
  const user = useAppSelector(selectUser);
  const [patientModalVisible, setPatientModalVisible] = useState(false);
  const [companiesMap, setCompaniesMap] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [dbs, setDbs] = useState([]);
  const [db, setDb] = useState((user.company && user.company.databaseUrl) || "au");
  const [mkey, setKey] = useState("1");
  const [companyId, setCompanyId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [patientName, setPatientName] = useState("");
  const [requesting, setRequesting] = useState("all");
  const [searchStr, setsearchStr] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any);
  const [record, setRecord] = useState({});

  const setCompany = (record: any) => {
    delete record.password;
    setRecord(record);
    setPatientModalVisible(true);
  };

  const setRequest = (record: any) => {
    delete record.password;
    setRecord(record);
    setKey("Requests");
    setPatientModalVisible(true);
  };

  useEffect(() => {
    fetchCompniesList();
    fetchSetting();
    if (user.type === "normal" && user.companyId) {
      if (user.company.type === "Clinic") {
        setCompanyId(user.companyId);
        fetchList({
          ...pagination,
          companyId: user.companyId,
        });
        fetchDoctors(user.companyId);
      } else {
        fetchList(pagination);
      }
    } else {
      fetchList(pagination);
    }
  }, []);

  const handleChange = (value: string) => {
    setDb(value);
    fetchCompniesList(value);
    fetchList({
      ...pagination,
      db: value,
    });
  };

  const viewsetRecord = (rec: any) => {
    setAccount(rec);
    setChartVisible(true);
  };

  const handleCompanyChange = (value: string) => {
    setCompanyId(value);
    fetchList({
      ...pagination,
      companyId: value,
    });
  };

  const onSearch = async (value: string) => {
    setsearchStr(value);
    if (!value) {
      value = "NONE_STR";
    }
    fetchList(pagination, value);
  };
  const onSearchPatientId = async (value: string) => {
    if (!value) {
      value = "NONE_STR";
    }
    setPatientId(value);
    fetchList({
      ...pagination,
      patientId: value,
    });
  };
  const onSearchParentName = async (value: string) => {
    if (!value) {
      value = "NONE_STR";
    }
    setPatientName(value);
    fetchList({
      ...pagination,
      patientName: value,
    });
  };

  const fetchSetting = async () => {
    setLoading(true);
    const result = await dispatch(getBaseSettingAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setDbs(data.dburls);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchList = async (params: any, search?: string) => {
    setLoading(true);
    params = params || pagination;
    params.type = "customer";
    params.db = params.db || db;
    params.patientId = params.patientId || patientId;
    params.patientName = params.patientName || patientName;
    params.companyId = params.companyId || companyId;
    params.doctorId = params.doctorId || doctorId;
    params.requesting = params.requesting || requesting;
    params.searchStr = search || searchStr;
    for (let key in params) {
      if (params[key] === "NONE_STR") {
        params[key] = "";
      }
    }
    !params.order && delete params.order;
    !params.sort && delete params.sort;
    const result = await dispatch(getCustomersAsync(params));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setData(data.list);
        setKey("1");
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total,
        });
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchDoctors = async (companyId?: string) => {
    if (!companyId || companyId === "all") {
      return;
    }
    setLoading(true);
    const parmas = {
      companyId: companyId,
    };
    const result = await dispatch(getAllDoctorsAsync(parmas));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setDoctors(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchCompniesList = async (value?: string) => {
    setLoading(true);
    const result = await dispatch(
      getAllCompaniesAsync({
        db: value || db,
      })
    );
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        let map = {} as any;
        data.list.map((co: any) => {
          map[co._id] = co.name;
        });
        setCompanies(data.list);
        setCompaniesMap(map);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };
  const deleteUser = async (id: string) => {
    setLoading(true);
    const result = await dispatch(
      deleteCustomerAsync({
        id,
        db: db,
      })
    );
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        fetchList({});
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const lockUser = async (id: string, isLocked: boolean) => {
    setLoading(true);
    let result = null;
    if (isLocked) {
      result = await dispatch(
        unlockCustomerAsync({
          id,
          db: db,
        })
      );
    } else {
      result = await dispatch(
        lockCustomerAsync({
          id,
          db: db,
        })
      );
    }
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        fetchList({});
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const debug = async (id: string, isDebug: boolean) => {
    setLoading(true);
    console.log(isDebug);
    let result = null;
    if (!isDebug) {
      result = await dispatch(
        debugCustomerAsync({
          id,
          db: db,
        })
      );
    } else {
      result = await dispatch(
        closeDebugCustomerAsync({
          id,
          db: db,
        })
      );
    }
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        fetchList({});
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const closeRquest = async (id: string) => {
    setLoading(true);
    const result = await dispatch(
      closeRquestAsync({
        id,
        db: db,
      })
    );
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        fetchList({});
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field;
    changePagination.order = sorter.order;
    fetchList({ ...changePagination });
  };
  const columns = [
    {
      title: t("work.ParentName"),
      dataIndex: "fullName",
      sorter: true,
      width: "200px",
      render: (companyId: any, record: any) => (
        <>
          <span>{`${record.firstName || ""}${record.middleName ? ` ${record.middleName}` : ""} ${record.lastName || ""}`}</span>
        </>
      ),
    },
    {
      title: t("work.PatientsInformation"),
      dataIndex: "accounts",
      render: (accounts: any) => (
        <>
          {accounts.map((account: any) => (
            <p style={{ marginBottom: 0 }} key={account._id}>
              <Popover
                title={""}
                content={
                  <Button style={{ marginLeft: 0 }} size="small" onClick={() => viewsetRecord(account)} key="turnoff">
                    {t("work.ComplianceRate")}
                  </Button>
                }
              >
                {account.firstName || ""} {account.lastName || ""} (ID: {account.patientId || ""})
              </Popover>
            </p>
          ))}
        </>
      ),
    },
    {
      title: t("work.Clinician_Name"),
      dataIndex: "accounts",
      width: "200px",
      render: (accounts: any) => (
        <>
          {accounts.map((account: any) => (
            <p style={{ marginBottom: 0, height: 22 }} key={account._id}>
              {account.doctorName || ""}
            </p>
          ))}
        </>
      ),
    },
    {
      title: t("work.Username"),
      dataIndex: "email",
      sorter: true,
      width: "200px",
    },
    {
      title: `${t("work.Clinic")}`,
      dataIndex: "companyId",
      sorter: true,
      width: "150px",
      render: (companyId: any) => {
        return (
          <>
            <span>{companiesMap[companyId]}</span>
          </>
        );
      },
    },
    {
      title: t("work.Phone"),
      dataIndex: "phone",
      sorter: true,
      width: "200px",
    },
    {
      title: t("work.Register_Date"),
      dataIndex: "registerDate",
      render: (registerDate: string) => (
        <>
          <span>{moment(registerDate).format("DD/MM/YYYY")}</span>
        </>
      ),
    },
    {
      title: t("work.Last_login"),
      dataIndex: "lastLoginAt",
      render: (lastLoginAt: string) => (
        <>
          <span>{lastLoginAt ? moment(lastLoginAt).format("DD/MM/YYYY") : ""}</span>
        </>
      ),
    },
    {
      title: t("work.Status"),
      dataIndex: "lock",
      render: (lock: string) => (
        <>
          <span>{lock ? "Locked" : "Active"}</span>
        </>
      ),
    },
    {
      title: t("work.Request"),
      sorter: true,
      dataIndex: "requesting",
      render: (requesting: string, record: any) => (
        <>
          <Space size="middle">
            {requesting ? (
              <a onClick={() => setRequest(record)}>
                <Tag color="green">Requesting</Tag>
              </a>
            ) : (
              ""
            )}
            {record.requesting ? (
              <Popconfirm title={t("work.closeRequestText")} onConfirm={() => closeRquest(record._id)} okText="Yes" cancelText="No">
                <Button danger size="small" key="3">
                  {t("work.Close")}
                </Button>
              </Popconfirm>
            ) : (
              ""
            )}
          </Space>
        </>
      ),
    },
    {
      title: t("work.Actions"),
      key: "actions",
      width: "300px",
      fixed: "right" as "right",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type="primary" size="small" onClick={() => setCompany(record)} key="1">
            {t("work.View")}
          </Button>
          <Popconfirm
            title={t(record.lock ? "work.Are_you_sure_to_unlock_this_user" : "work.Are_you_sure_to_lock_this_user")}
            onConfirm={() => lockUser(record._id, record.lock)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger size="small" key="3">
              {t(!record.lock ? "work.Lock" : "work.Unlock")}
            </Button>
          </Popconfirm>
          <Popconfirm title={t("work.Are_you_sure_to_delete_this_user")} onConfirm={() => deleteUser(record._id)} okText="Yes" cancelText="No">
            <Button danger size="small" key="3">
              {t("work.Delete")}
            </Button>
          </Popconfirm>
          {user.type === "admin" ? (
            <Popconfirm
              title={t(record.debug ? "work.revoketestUserText" : "work.testUserText")}
              onConfirm={() => debug(record._id, record.debug)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" key="3">
                {t(!record.debug ? "work.Test" : "work.CloseTest")}
              </Button>
            </Popconfirm>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="eyerising-page-header">
        <div className="header-content">
          <Row justify="space-between">
            <Col span={10}>
              <Space>
                {user.type === "admin" ? (
                  <Select defaultValue={db} style={{ width: 200 }} placeholder="" onChange={handleChange}>
                    {dbs.map((co: any) => (
                      <Option key={co.key} value={co.key}>
                        {co.name}
                      </Option>
                    ))}
                  </Select>
                ) : null}
                <Search placeholder={t("work.SearchUserName")} onSearch={onSearch} style={{ width: 250 }} />
                <Search placeholder={t("work.SearchParentName")} onSearch={onSearchParentName} style={{ width: 250 }} />
                <Search placeholder={t("work.SearchpatientID")} onSearch={onSearchPatientId} style={{ width: 250 }} />
                <Button className="table-btn" key="2" onClick={() => fetchList({ ...pagination })}>
                  {" "}
                  {t("work.Refresh")}
                </Button>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Space style={{ float: "right", marginRight: 10 }}>
              <Select
                value={companyId || null}
                optionFilterProp="label"
                placeholder={t("work.searchByCompany")}
                showSearch
                style={{ width: 350 }}
                onChange={(value: string) => {
                  handleCompanyChange(value);
                  fetchDoctors(value);
                }}
              >
                <Option key="all" label={t("work.All")} value="all">
                  {t("work.All")}
                </Option>
                {companies.map((co: any) => (
                  <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>
                    {co.name}
                  </Option>
                ))}
              </Select>
              <Button icon={<PlusCircleOutlined />} onClick={() => setCompany({})} type="primary">
                {t("work.Create")}
              </Button>
              <ExportButton
                exportApi={exportCustomersAsync}
                exportQuery={{
                  searchStr: searchStr,
                  type: "customer",
                  db: db,
                  companyId,
                  doctorId,
                  patientId,
                  patientName,
                  requesting,
                }}
              />
            </Space>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ["bottomLeft"],
            ...pagination,
            showTotal: (total) => {
              return `${t("work.Total")}: ${total}`;
            },
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <PatientModal
        record={record}
        setRecord={setCompany}
        mkey={mkey}
        setKey={setKey}
        db={db}
        visible={patientModalVisible}
        setVisible={setPatientModalVisible}
        getList={() => fetchList(pagination)}
      />
      <ReportChart record={account} setRecord={setAccount} visible={chartVisible} setVisible={setChartVisible} companies={companies} />
    </>
  );
};

export default Patients;
