import { useState, useEffect } from 'react'
import { Form, Input, Modal, Button, message, Row, Select, Col, Upload } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import type { UploadChangeParam } from 'antd/es/upload';
import axios from 'axios'
import {
  addReleaseAsync,
} from '../../reducers/versions/versionsSlice'

import {
  selectUser
} from '../../reducers/account/accountSlice';
import {
  postAWSSignurlAsync
} from '../../reducers/aws/awsSlice'
import s3utils from '../../utils/s3.js'
import { useTranslation } from 'react-i18next';
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const { Option } = Select;
const PlanModal = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }

  const onFinish = async () => {
    form.validateFields()
      .then(async values => {
        values.id = props.record._id
        const result = await dispatch(addReleaseAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            message.success('Successful operation');
            closeModal()
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  useEffect(() => {
    form.resetFields()
    setFileName('')
    if (props.record._id) {
      form.setFieldsValue(props.record)
    }
  }, [props.record._id, props.visible])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  // const [imageUrl, setImageUrl] = useState<string>();
  const [fileList, setFileList] = useState([] as any)

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setLoading(false);
      // getBase64(info.file.originFileObj as RcFile, url => {
      //   setLoading(false);
      // });
    }
  };

  const uploadButton = (
    <div>
      {fileName ? <Input style={{ width: 300 }} value={fileName} placeholder="" disabled /> : ''}
      <Button disabled={loading} icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>Upload</Button>
    </div>
  );

  const uploadProps = {
    onRemove: (del: any) => {
      setFileList(fileList.filter((file: any) => file !== del))
    },
    customRequest: async (option: any) => {
      option.method = 'PUT'
      option.filename = option.file.fileName
      option.data = option.file.fields
      option.onSuccess = async () => {
        // props.uploadedCb(option.file.publicUrl)
        const zipResult = await axios({
          method: 'head',
          url: option.file.publicUrl
        })
        const md5 = zipResult.headers.etag.replaceAll('\"', '').replaceAll('\\', '')
        form.setFieldValue('md5', md5)
        form.setFieldValue('url', option.file.publicUrl)
        setLoading(false)

      }
      await s3utils.postToS3(option)



    },
    beforeUpload: async (file: any, list: any) => {
      // const isZip = file.type === 'application/zip'
      // if (!isZip) {
      //   message.error('You can only upload Zip file!');
      //   return isZip
      // }
      const params = {
        fileName: file.uid,
        fileType: file.type
      }
      setLoading(true)
      setFileName(file.name)
      const result = await dispatch(postAWSSignurlAsync(params))
      const { payload } = result
      if (payload) {
        const { code, data, msg } = payload
        if (code === 0) {
          file.fileName = data.fileName
          file.signUrl = data.signUrl
          file.publicUrl = data.publicUrl
          file.fields = data.fields
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
      return true;
    },
    onChange: (option: any) => {
      setFileList(option.fileList)
    },
    fileList,
  };
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width='900px'
          title={props.record._id ? t('work.Edit_version_info') : t('work.Create_version')}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              {t("work.Save")}
            </Button>,
            <Button key="back" onClick={closeModal}>
              {t("work.Cancel")}
            </Button>
          ]}
        >
          <Row gutter={20}>
            <Col span={20}>
              <Form.Item name="name"
                label={t("work.Name")}
                rules={[{ required: true, message: t('work.Please_input_name') }]}>
                <Input placeholder="" />
              </Form.Item>
              <Form.Item name="description"
                label={t("work.Description")}>
                <Input.TextArea placeholder="" />
              </Form.Item>
              <Form.Item name="file"
                label={t("work.File")}>
                <Upload
                  accept='.zip'
                  showUploadList={false}
                  {...uploadProps}>

                  {uploadButton}
                </Upload>
              </Form.Item>
              <Form.Item name="md5"
                label={t("work.MD5")}>
                <Input placeholder="" />
              </Form.Item>
              <Form.Item name="url"
                label={t("work.URL")}>
                <Input placeholder="" />
              </Form.Item>
              {/* <Form.Item
              name="status"
              required
              label={t('work.Status')}
            >
              <Select placeholder="" showSearch>
                <Option key="enabled" value='enabled'>{t("work.Enabled")}</Option>
                <Option key="disabled" value='disabled'>{t("work.Disabled")}</Option>
              </Select>
            </Form.Item> */}
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
}

export default PlanModal
