import { Input, Space, Button, Row, Card, Col, Select, message, Modal, Menu, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone'
import { useQuery } from '../utils/query';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks'
import html2canvas from 'html2canvas'
import { DownOutlined, EyeInvisibleOutlined, RollbackOutlined } from '@ant-design/icons';
import { jsPDF } from "jspdf";
import { Column } from '@ant-design/plots';
import logo2 from '../assets/logo2.png'

import {
  getPaymentAsync
} from '../reducers/payments/paymentsSlice';
const Option = Select.Option
const Report = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({} as any)
  const [imgData, setImgData] = useState('')
  const { t, i18n } = useTranslation()
  const query = useQuery()
  const savePdf = async (ispdf?: boolean) => {
    const width = 210.28
    const height = 841.89
    window.scrollTo(0, 0)
    setTimeout(() => {
      const target = document.getElementById('target') as any
      console.log(target.offsetWidth)
      html2canvas(target, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        scale: 2, // 处理模糊问题
        width: target.offsetWidth,
        height: target.offsetHeight
      }).then((canvas) => {
        setLoading(false)
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        var imgWidth = width
        var imgHeight = width / contentWidth * contentHeight;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        setImgData(pageData)
        var pdf = new jsPDF();
        ispdf && pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        ispdf && pdf.save('receipt.pdf');
      })
    }, 1000)
  }
  const getData = async (accountId?: string) => {
    setLoading(true)
    const result = await dispatch(getPaymentAsync({
      id: query.id
    }))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.item)
        savePdf()
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }
  useEffect(() => {
    // form.setFieldsValue(user)
    getData()
    console.log()
  }, [])




  return (
    <>
      {
        <div style={{ width: '100%' }}>
          <div>
            {
              loading ? '' :
                <Button style={{ position: 'absolute', left: 150, width: 150, top: 20, height: 50 }} type='primary' onClick={() => savePdf(true)} loading={loading}>
                  <Space>
                    Export pdf
                  </Space>
                </Button>
            }
            <div id="target-content">
              <div id="target">
                <div style={{ height: 60 }}>
                  <img style={{ float: 'right', height: 40 }} src={logo2} alt="" />
                </div>
                <p>
                  {t('receipt.Date')}: {data.createdAt && moment(data.createdAt).format('DD/MM/YYYY HH:mm')}
                </p>
                <p style={{ marginBottom: 20 }}>
                  {t('receipt.ReferenceNumber')}: {data._id}
                </p>
                <p style={{ marginBottom: 20 }}>
                  {data.customer && `${data.customer.firstName} ${data.customer.middleName || ''} ${data.customer.lastName}`},
                </p>
                <p style={{ margin: '5px 0' }}>
                  {t('receipt.WeAreWriting')}:
                </p>
                <p style={{ margin: '5px 0 5px 20px' }}>
                  · {data.plan && data.plan.name} in the amount of {data.plan && (data.plan.price + (data.plan.deposit || 0) + (data.plan.purchase || 0))} {data.plan && data.plan.currency.toUpperCase()}.
                </p>
                <p style={{ marginBottom: 20 }}>

                  {t('receipt.PaymentProcessed')} {data.paidAt && moment(data.paidAt).format('DD/MM/YYYY HH:mm')} through Airwallex.
                </p>
                <p style={{ marginBottom: 20 }}>
                  {t('receipt.PaymentDetails')}:
                </p>
                <p style={{ margin: '5px 0' }}>
                  {t('receipt.TotalAmount')}: {data.plan && (data.plan.price + (data.plan.deposit || 0) + (data.plan.purchase || 0))} {data.plan && data.plan.currency.toUpperCase()}
                </p>
                <div style={{ marginLeft: 20 }}>

                  <p style={{ margin: '5px 0' }}>
                    · {t('receipt.DepositFee')}: {data.plan && (data.plan.deposit || 0)} {data.plan && data.plan.currency.toUpperCase()}
                  </p>
                  {
                    data.plan && (data.plan.purchase > 0) ?
                      <p style={{ margin: '5px 0' }}>
                        {t('receipt.DevicePurchaseFee')}: {data.plan && (data.plan.purchase || 0)} {data.plan && data.plan.currency.toUpperCase()}
                      </p> : ''
                  }
                  <p style={{ margin: '5px 0' }}>
                    · {t('receipt.SubscriptionFee')}: {data.plan && (data.plan.price * (1 - data.taxPercentage * 0.01))} {data.plan && data.plan.currency.toUpperCase()}
                  </p>
                  <p style={{ margin: '5px 0' }}>
                    · GST/VAT: {data.plan && (data.plan.price * data.taxPercentage * 0.01)} {data.plan && data.plan.currency.toUpperCase()}
                  </p>
                </div>
                <p>
                  Invoice/Order Number: {data._id}
                </p>
                <p>
                  {t('receipt.PaymentDate')}: {data.createdAt && moment(data.createdAt).format('DD/MM/YYYY HH:mm')}
                </p>
                <p>
                  {t('receipt.PaymentMethod')}: {data.paymentMethod || 'card'}
                </p>
                <p style={{ marginBottom: 20 }}>
                  Please note that the payment has been applied to your account, and any outstanding balances or obligations have been cleared.
                </p>
                <p style={{ marginBottom: 20 }}>
                  If you have any questions or concerns regarding this payment confirmation, please do not hesitate to contact our customer service team at support@eyerisinginternational.com and we will be more than happy to assist you.
                </p>
                <p style={{ marginBottom: 20 }}>
                  Thank you for your prompt payment and for choosing Eyerising International. We value your decision and look forward to serving you again in the future.
                </p>
                <p>
                  Sincerely,
                </p>
                <p>
                  Customer Service Team
                </p>
                <p>
                  Eyerising International Pty Ltd
                </p>
                <p>
                  Suite 2.05 9/11 Claremont St, South Yarra VIC 3141
                </p>

                <p>
                  support@eyerisinginternational.com
                </p>
                <footer>
                  <p style={{ marginBottom: 0 }}>
                    Eyerising International Pty Ltd (ABN: 98 641 925 206)
                  </p>
                  <p>
                    Suite 2.05 9/11 Claremont St, South Yarra VIC 3141
                  </p>
                </footer>
              </div>
            </div>
            <Skeleton loading={loading} active >
              <div style={{
                width: '100%',
                background: '#ccc',
                textAlign: 'center'
              }}>

                <img src={imgData} alt="" />

              </div>
            </Skeleton>
          </div>
        </div>
      }
    </>
  );
}

export default Report
