// A mock function to mimic making an async request for data
import api from '../../utils/api'

export interface accountPayload {
  email: string;
  password: string;
  'g-recaptcha-response': string;
}

export function fetchAccount(payload: accountPayload) {
  return api('/api/signin', 'POST', payload)
}


export function fetchAccountByToken(payload: any) {
  return api('/api/signin/token', 'POST', payload)
}


export function fetchPortalAccount(payload: accountPayload) {
  return api('/api/portal/signin', 'POST', payload)
}


export function fetchPortalAccountByToken(payload: any) {
  return api('/api/portal/signin/token', 'POST', payload)
}

export function fetchCaptcha() {
  return api('/api/captcha', 'GET')
}
