import { Layout, Space, Button, Popconfirm, Table, Input, message, Row, Col, Image, Select } from 'antd';
import { useEffect, useState } from 'react';
import config from '../../config/config'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getCompaniesAsync,
  putCompanyAsync,
  deleteCompanyAsync,
  exportCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import {
  selectUser
} from '../../reducers/account/accountSlice';
import { PlusCircleOutlined } from '@ant-design/icons';

import qr from 'qr-image'
import ExportButton from '../Components/exportButton'
import CompanyModal from './CompanyModal'
import { useTranslation } from 'react-i18next';
import './Common.scss'
import { getBaseSettingAsync } from '../../reducers/setting/settingSlice';
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const Companies = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const user = useAppSelector(selectUser)
  const [dbs, setDbs] = useState([])
  const [db, setDb] = useState('all')
  let [modelVisible, setModelVisible] = useState(false)
  const [searchStr, setsearchStr] = useState('')
  const [companyNumber, setCompanyNumber] = useState('')
  const [companyCustomID, setCompanyCustomID] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [record, setRecord] = useState({})
  const setCompany = (record: any) => {
    setRecord(record)
    setModelVisible(true)
  }
  useEffect(() => {
    fetchSetting()
    fetchList(pagination)
  }, [])

  const getQrCode = (url: string) => {
    if (!url) return ''
    let qrCode = qr.imageSync(url, 'L').toString('base64')
    return `data:image/png;base64,${qrCode}`
  }
  const onSearch = async (value: string) => {
    setsearchStr(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList(pagination, value)
  }
  const onSearchCompanyNumber = async (value: string) => {
    setCompanyNumber(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      companyNumber: value
    })
  }

  const onSearchCompanyCustomID = async (value: string) => {
    setCompanyCustomID(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      companyCustomID: value
    })
  }

  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    params.companyNumber = params.companyNumber || companyNumber
    params.companyCustomID = params.companyCustomID || companyCustomID
    params.db = params.db || db
    !params.order && delete params.order
    !params.sort && delete params.sort
    for (let key in params) {
      if (params[key] === 'NONE_STR') {
        params[key] = ''
      }
    }
    const result = await dispatch(getCompaniesAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const putCompany = async (id: string, disabled: boolean) => {
    setLoading(true)
    const result = await dispatch(putCompanyAsync({
      disabled,
      id: id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({})
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const deleteCompany = async (id: string,) => {
    setLoading(true)
    const result = await dispatch(deleteCompanyAsync({
      id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({})
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }
  const columns = [
    {
      title: t('work.Company_Name'),
      dataIndex: 'name',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Company_Type'),
      dataIndex: 'type',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.QR_Code'),
      dataIndex: 'QR_Code',
      width: '150px',
      render: (text: string, record: any) => {
        return record.disabled ? '' :
          <Space size="middle">
            <Image
              width={100}
              src={getQrCode(`${config.portalDomain}/signup?id=${record._id}&mcc=${record.owner_mobileCountryCode}`)}
            />
          </Space>
      },
    },
    {
      title: t('work.Company_Number'),
      dataIndex: 'company_number',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Company_Custom_ID'),
      dataIndex: 'company_custom_id',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Country'),
      dataIndex: 'country',
      sorter: true,
      width: '150px',
    },
    {
      title: t('work.Register_Number'),
      dataIndex: 'register_number',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type='primary' size='small' onClick={() => setCompany(record)} key="1">
            {t("work.View")}
          </Button>
          {
            user.userType !== 'admin' && user.type !== 'admin' ? '' : <>
              <Popconfirm
                title={t(`work.Are_you_sure_to_${record.disabled ? 'enable' : 'disable'}_this_company`)}
                onConfirm={() => putCompany(record._id, record.disabled ? false : true)}
                okText="Yes"
                cancelText="No"
              >
                <Button size='small' key="Disable">
                  {
                    record.disabled ? t('work.Enable') : t('work.Disable')
                  }
                </Button>
              </Popconfirm>
              <Popconfirm
                title={t('work.Are_you_sure_to_delete_this_company')}
                onConfirm={() => deleteCompany(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger size='small' key="3">
                  {t("work.Delete")}
                </Button>
              </Popconfirm></>
          }
        </Space>
      ),
    },
  ];

  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
        // form.setFieldsValue(data.setting)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setDb(value)
    fetchList({
      ...pagination,
      db: value
    })
  };

  return (
    <>
      <div className='eyerising-page-header'>
        <div className='header-content' >
          <Row justify="space-between">
            <Col span={4}>
              <Space>
                <Search placeholder={t('work.SearchName')} onSearch={onSearch} style={{ width: 200 }} />
                <Search placeholder={t('work.SearchCompanyCode')} onSearch={onSearchCompanyNumber} style={{ width: 200 }} />
                <Search placeholder={t('work.SearchCompanyCustomID')} onSearch={onSearchCompanyCustomID} style={{ width: 240 }} />
                {
                  user.type === 'admin' ? (

                    <Select defaultValue={db} style={{ width: 80 }} placeholder="" onChange={handleChange}>

                      <Option value="all">{t('work.All')}</Option>

                      {
                        dbs.map((co: any) => {
                          return (
                            <Option key={co.key} value={co.key}>{co.name}</Option>)
                        })
                      }
                    </Select>) : ''
                }
                <Button className='table-btn' key="2" onClick={() =>
                  fetchList({
                    ...pagination
                  })}>
                  {t("work.Refresh")}</Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space style={{ float: 'right', marginRight: 10 }}>
                {
                  user.userType === 'admin' || user.type === 'admin' ?
                    <Button icon={<PlusCircleOutlined />} onClick={() => setCompany({})} type='primary'>
                      {t("work.Create")}
                    </Button> : ''}
                <ExportButton exportApi={exportCompaniesAsync} exportQuery={{
                  searchStr: searchStr,
                  db: db,
                  companyNumber
                }} />
              </Space>

            </Col>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content users-table">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowClassName={(record) => record._id === user.companyId ? 'active' : ""}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')} ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <CompanyModal
        record={record}
        setRecord={setCompany}
        visible={modelVisible}
        setVisible={setModelVisible}
        getList={() => fetchList(pagination)}
      />
    </>
  )
}


export default Companies;