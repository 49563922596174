import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getReleases,
  deleteRelease,
  addRelease,
  getAllReleases
} from './versionsAPI';
const initialState = {}

export const deleteReleaseAsync = createApiAsyncThunk<any>('releases/deleteRelease', deleteRelease)


export const addReleaseAsync = createApiAsyncThunk<any>('releases/addRelease', addRelease)

export const getReleasesAsync = createApiAsyncThunk<any>('releases/getReleases', getReleases)

export const getAllReleasesAsync = createApiAsyncThunk<any>('releases/getAllReleases', getAllReleases)


export const releasesSlice = createSlice({
  name: 'releases',
  initialState,
  reducers: {}
})

export default releasesSlice.reducer;
