// A mock function to mimic making an async request for data
import api from '../../utils/api'
export function addCatalog(payload: any) {
  return api('/api/admin/catalogs', 'POST', payload)
}

export function exportCatalogs(payload: any) {
  return api('/api/admin/catalogs/export', 'GET', payload)
}
export function getCatalogs(payload: any) {
  return api('/api/admin/catalogs', 'GET', payload)
}

export function deleteCatalog(payload: any) {
  return api('/api/admin/catalogs', 'DELETE', payload)
}
export function getAllCatalogs(payload: any) {
  return api('/api/admin/catalogs/all', 'GET', payload)
}
