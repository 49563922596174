// A mock function to mimic making an async request for data
import api from '../../utils/api'
export function addDevice(payload: any) {
  return api('/api/admin/devices', 'POST', payload)
}

export function exportDevices(payload: any) {
  return api('/api/admin/devices/export', 'GET', payload)
}
export function getDevices(payload: any) {
  return api('/api/admin/devices', 'GET', payload)
}

export function deleteDevice(payload: any) {
  return api('/api/admin/devices', 'DELETE', payload)
}
export function importDevicesData(payload: any) {
  return api('/api/admin/devices/import', 'POST', payload)
}
export function importDevices(payload: any) {
  return api('/api/admin/devices/import/list', 'POST', payload, {}, true, 30 * 60 * 1000)
}

export function importList(payload: any) {
  return api('/api/admin/inventory/import', 'POST', payload, {}, true, 30 * 60 * 1000)
}

export function updateManyStatus(payload: any) {
  return api('/api/admin/inventory/status', 'POST', payload)
}

export function updateDevice(payload: any) {
  return api('/api/admin/devices/info', 'POST', payload)
}

export function updateDeviceStatus(payload: any) {
  return api('/api/admin/devices/status', 'POST', payload)
}
export function getBarcodes(payload: any) {
  return api('/api/admin/barcode', 'GET', payload)
}

export function getDeviceRecords(payload: any) {
  return api('/api/admin/devices/records', 'GET', payload)
}