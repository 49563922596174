import { useState, useEffect } from 'react'
import { Form, Alert, Upload, Modal, Button, Space, message, Select, Table, Tag } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../app/hooks'
import config from '../../config/config'
import {
  importDevicesAsync,
  importDevicesDataAsync
} from '../../reducers/devices/devicesSlice'
import {
  getAllCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import {
  getAllCatalogsAsync
} from '../../reducers/catalogs/catalogsSlice'
import { useTranslation } from 'react-i18next';
interface propsObject {
  visible: boolean;
  setVisible: Function;
  getList: Function;
}
const ImportDevicesModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [companies, setCompanies] = useState([])
  const [tableData, setTableData] = useState([])
  const [products, setProducts] = useState([])
  const [uploaded, setUploaded] = useState(false)
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()
  const closeModal = () => {
    props.setVisible(false)
    form.resetFields()
    setUploaded(false)
    setTableData([])
    setFileList([])
  }

  const fetchAllProducts = async () => {
    setLoading(true)
    const result = await dispatch(getAllCatalogsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setProducts(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const onFinish = async () => {
    form.validateFields().then(async (values: any) => {
      const data = {
        'companyId': values.id,
        productId: values.productId,
        ownership: values.ownership,
        list: tableData.filter((de: any) => de.batchStatus === 'waiting')
      }
      setLoading(true)
      const result = await dispatch(importDevicesDataAsync(data))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, data, msg } = payload
        if (code === 0) {
          message.success('Successful operation');
          setUploaded(true)
          setTableData(data.list)
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
      if (typeof payload === 'string') {
        Modal.error({
          title: 'Error',
          content: payload
        })
      }
    })
  }

  useEffect(() => {
    setTotal(0)
    setTableData([])
    fetchAllProducts()
    setUploaded(false)
    fetchList()
  }, [])
  const columns = [
    {
      title: t('work.Status'),
      dataIndex: 'batchStatus',
      render: (batchStatus: string, row: any) => (
        <>
          <span>
            {batchStatus === 'waiting' ? <Tag color="yellow">Waiting</Tag> : ''
            }
            {
              batchStatus === 'failed' ? <Tag color="red">Failed</Tag> : ''
            }
            {
              batchStatus === 'succeeded' ? (<Tag color="green">Successed</Tag>) : ''
            }
          </span>
        </>
      ),
    },
    {
      title: t('work.Type'),
      dataIndex: 'type',
    },
    {
      title: t('work.UDI_Number'),
      dataIndex: 'udi',
    },
    {
      title: t('work.Order_Number'),
      dataIndex: 'order_number',
    },
    {
      title: t('work.QR_Code'),
      dataIndex: 'qrcode',
    },
    {
      title: t('work.Serial_Number'),
      dataIndex: 'serial_number',
    },
    {
      title: t('work.Message'),
      dataIndex: 'msg',
    },
  ];
  const fetchList = async () => {
    setLoading(true)
    const result = await dispatch(getAllCompaniesAsync({
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCompanies(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const onChangeName = (e: any) => {
    form.setFieldsValue({ rename: e })
  };

  const layout = {
    labelCol: { span: 5 },
  }

  const uploadProps = {
    onRemove: (del: any) => {
      setFileList(fileList.filter((file: any) => file !== del))
    },
    beforeUpload: async (file: any) => {
      // let newFileList: Array<any> = [...fileList, file]
      // setFileList(newFileList)
      // return false;

      let newFileList: Array<any> = [...fileList, file]
      setFileList(newFileList)

      const data = {
        file: newFileList[0]
      }
      setLoading(true)
      const result = await dispatch(importDevicesAsync(data))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, data, msg } = payload
        if (code === 0) {
          const list = data.list
          setTableData(list)
          setTotal(data.list.length)
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
        if (typeof payload === 'string') {
          message.error(payload)
        }
      }
      return false;
    },
    fileList,
  };
  return (
    <>
      <Form form={form} {...layout} >
        <Modal
          width={800}
          visible={props.visible}
          title={t('work.Import_data')}
          onCancel={closeModal}
          footer={
            uploaded ?
              [
                <Button key="back" onClick={closeModal}>
                  {t('work.Cancel')}
                </Button>
              ] :
              [
                <Button htmlType="submit" key="submit" onClick={onFinish} disabled={fileList.length === 0} loading={loading} type="primary">
                  {loading ? 'Uploading' : 'Start Upload'}
                </Button>,
                <Button key="back" onClick={closeModal}>
                  {t('work.Cancel')}
                </Button>
              ]}
        >
          <Form.Item
            name="productId"
            label={t('work.Product')}
            rules={[{ required: true, message: 'Please select Product!' }]}
          >
            <Select placeholder="" showSearch optionFilterProp="label">

              {
                products.map((co: any) => {
                  return (
                    <Select.Option key={co._id} label={co.reference} value={co._id}>{co.reference}</Select.Option>)
                })
              }
            </Select>
          </Form.Item>
          <Form.Item name="id" label={t("work.Company")} rules={[{ required: true, message: t('work.Please_select_company') }]}>
            <Select onChange={onChangeName}>
              {
                companies
                  .map((item: any) => <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item name="ownership" label={t("work.Ownership")}
          >
            <Select onChange={onChangeName}>
              {
                companies
                  .map((item: any) => <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>)
              }
            </Select>
          </Form.Item>

          <>
            <Upload {...uploadProps}>
              <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>{t('work.Select_File')}</Button>
            </Upload>
          </>
          <Alert
            style={{ margin: '10px 0 10px' }}
            showIcon
            message="Note:"
            description={t('work.import_note')}
            type="info"
            action={
              <Space direction="vertical">

                <a href={`${config.domain}/static/devices_template.csv`} target="view_window">
                  template.csv
                </a>
              </Space>
            }
          />
          <Alert
            style={{ margin: '10px 0 10px' }}
            message={`Total: ${total}`}
            type="success"
          />
          <Table
            rowClassName={(record) => record.batchStatus} columns={columns} dataSource={tableData} size="middle"
            rowKey="serial_number"
            pagination={false} />
        </Modal>
      </Form>
    </>
  );
}

export default ImportDevicesModal
