import { useEffect } from 'react'
import { Modal } from 'antd'
import AccountModalForm from './AccountModalForm'
import { useTranslation } from 'react-i18next';
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const AccountModal = (props: propsObject) => {
  const { t, i18n } = useTranslation()
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }
  useEffect(() => {
  }, [])
  return (
    <>
      <Modal
        className="add-user-modal"
        visible={props.visible}
        width='900px'
        title={props.record._id ? t('work.EditAccount') : t('work.CreateAccount')}
        onCancel={closeModal}
        footer={[
        ]}
      >
        <AccountModalForm
          record={props.record}
          setRecord={props.setRecord}
          visible={props.visible}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
}

export default AccountModal
