import { Layout, message, Spin, Breadcrumb } from 'antd';
import './WorkSpace.scss'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import AdminSlider from './Slider'
import TopBar from './TopBar'
import Users from '../Users/Users'
import Products from '../Products/Products'
import Inventory from '../Inventory/Inventory'
import Plans from '../Plans/Plans'
import Versions from '../Versions/Versions'
import Patients from '../Patients/Patients'
import Companies from '../Companies/Companies'
import Setting from '../Setting/Setting'
import Payments from '../Payments/Payments'
import Dashboard from '../Dashboard/Dashboard'
import Preferences from '../Preferences/Preferences'
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useLocation, Link } from "react-router-dom";
import {
  feachAccountByTokenAsync,
  restore,
  signout,
  restoreUser,
  selectUser
} from '../../reducers/account/accountSlice';

import { useEffect, useState } from 'react';
import PaymentResult from '../Payments/PaymentResult';
import InvoiceReports from '../InvoiceReports/InvoiceReports';
const { Header, Sider, Content } = Layout;
const WorkSpace = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { t, i18n } = useTranslation();

  const breadcrumbNameJSON = {
    '/admin': 'Admin',
    '/admin/companies': t('work.Companies'),
    '/admin/users': t('work.Users'),
    '/admin/inventory': t('work.Inventory'),
    '/admin/customers': t('work.Patients'),
    '/admin/plans': t('work.Plans'),
    '/admin/products': t('work.Products'),
    '/admin/payments': t('work.Payments'),
    '/admin/preference': t('work.Profile'),
    '/admin/setting': t('work.Settings'),
    '/admin/versions': t('work.Versions'),
  }

  const user = useAppSelector(selectUser)
  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    const result = await dispatch(feachAccountByTokenAsync({}))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        if (data.user.type !== 'admin') {
          localStorage.removeItem('token')
          localStorage.removeItem('tokenExpiredAt')
          dispatch(signout)
          message.error('User type error')
          return history.push('/')
        }
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        i18n.changeLanguage(data.user.language || 'en')
        setLoading(false)
      } else if (msg) {
        message.error(msg)
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        return history.push('/')
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiredAt')
      dispatch(signout)
      return history.push('/')
    }
  }

  const breadcrumbNameMap: Record<string, string> = breadcrumbNameJSON;
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <span>{breadcrumbNameMap[url]}</span>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <>
      <Layout className='admin-workspace' style={{ minHeight: '100vh' }}>
        {loading ?
          <Layout style={{ padding: '200px 0' }}>
            <Spin size="large" />
          </Layout> : <>
            <Layout style={{
              padding: '0 0'
            }}>
              <Sider className="eyerising-sider">
                <AdminSlider />
              </Sider>
              <Content style={{
                padding: '0 0'
              }}>
                <Header className="eyerising-header">
                  <TopBar />
                </Header>
                <Breadcrumb>{breadcrumbItems}</Breadcrumb>
                <Switch>
                  <Route path="/admin/companies">
                    <Companies />
                  </Route>
                  <Route path="/admin/customers">
                    <Patients />
                  </Route>
                  <Route path="/admin/users">
                    <Users />
                  </Route>
                  <Route path="/admin/plans">
                    <Plans />
                  </Route>
                  <Route path="/admin/versions">
                    <Versions />
                  </Route>
                  <Route path="/admin/payments">
                    <Payments />
                  </Route>
                  <Route path="/admin/inventory">
                    <Inventory />
                  </Route>
                  <Route path="/admin/products">
                    <Products />
                  </Route>
                  <Route path="/admin/setting">
                    <Setting />
                  </Route>
                  <Route path="/admin/preference">
                    <Preferences />
                  </Route>
                  <Route path="/admin/payment-result">
                    <PaymentResult />
                  </Route>
                  <Route path="/admin/invoice-reports">
                    <InvoiceReports />
                  </Route>
                  <Route path="/admin/">
                    <Dashboard />
                  </Route>
                </Switch></Content>
            </Layout></>}
      </Layout>
    </>
  )
}


export default WorkSpace;