import { Layout, Space, Button, Popconfirm, Table, Input, message, Row, Col, Select } from 'antd';
import { useEffect, useState } from 'react';
import config from '../../config/config'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getAllCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import {
  getUsersAsync,
  deleteUserAsync,
  exportUserAsync
} from '../../reducers/users/usersSlice'
import {
  selectUser
} from '../../reducers/account/accountSlice';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import ExportButton from '../Components/exportButton'
import AddUserModal from './AddUserModal'
import './Common.scss'
import moment from 'moment'
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const Companies = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [type, setType] = useState('')
  const { t, i18n } = useTranslation()
  const user = useAppSelector(selectUser)
  let [modelVisible, setModelVisible] = useState(false)
  const [phone, setPhone] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companiesMap, setCompaniesMap] = useState([] as any)
  const [searchStr, setsearchStr] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [record, setRecord] = useState({})
  const setCompany = (record: any) => {
    setRecord(record)
    setModelVisible(true)
  }
  useEffect(() => {
    fetchCompniesList()
    fetchList(pagination)
  }, [])

  const handlePhoneChange = (value: string) => {
    setPhone(value)
    fetchList({
      ...pagination,
      phone: value
    })
  };
  const companyNameChange = (value: string) => {
    setCompanyName(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      companyName: value
    })
  };
  const onSearch = async (value: string) => {
    setsearchStr(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList(pagination, value)
  }

  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    params.type = params.type || type
    params.phone = params.phone || phone
    params.companyName = params.companyName || companyName
    for (let key in params) {
      if (params[key] === 'NONE_STR') {
        params[key] = ''
      }
    }
    !params.order && delete params.order
    !params.sort && delete params.sort
    const result = await dispatch(getUsersAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };


  const fetchCompniesList = async () => {
    setLoading(true)
    const result = await dispatch(getAllCompaniesAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        let map = {} as any
        data.list.map((co: any) => {
          map[co._id] = {
            name: co.name,
            type: co.type
          }
        })
        setCompaniesMap(map)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const deleteUser = async (id: string,) => {
    setLoading(true)
    const result = await dispatch(deleteUserAsync({
      id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({})
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const usertypes = {
    'admin': 'Administrator',
    'general': 'Support staff',
    'doctor': 'Clinician'
  } as any
  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }
  const columns = [

    {
      title: t('work.Name'),
      dataIndex: 'fullName',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Username'),
      dataIndex: 'email',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.UserType'),
      dataIndex: 'userType',
      sorter: true,
      width: '150px',
      render: (userType: string) => {
        return (
          <>
            <span>{usertypes[userType]}</span>
          </>
        )
      }
    },
    {
      title: t('work.Phone'),
      dataIndex: 'phone',
      sorter: true,
      width: '200px',
    },
    {
      title: `${t('work.Company')} (${t('work.DistributorClinic')})`,
      dataIndex: 'companyId',
      sorter: true,
      width: '250px',
      render: (companyId: any, row: any) => {
        return (
          <>
            {row.type === 'normal' ?
              <span>{companiesMap[companyId] && companiesMap[companyId].name} ({companiesMap[companyId] && companiesMap[companyId].type})</span>
              : ""}
          </>
        )
      }
    },
    {
      title: t('work.Last_login'),
      dataIndex: 'lastLoginAt',
      render: (lastLoginAt: string) => (
        <>
          <span>{lastLoginAt ? moment(lastLoginAt).format('DD/MM/YYYY') : ''}</span>
        </>
      ),
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type='primary' size='small' onClick={() => setCompany(record)} key="1">
            {t('work.View')}
          </Button>
          {

            user._id === record._id || (user.userType !== 'admin' && user.type !== 'admin') ? '' :
              <Popconfirm

                title={t('work.Are_you_sure_to_delete_this_user')}
                onConfirm={() => deleteUser(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger size='small' key="3">
                  {t('work.Delete')}
                </Button>
              </Popconfirm>
          }
        </Space>
      ),
    },
  ];
  if (user.type == 'admin') {
    columns.splice(2, 0, {
      title: t('work.Authority'),
      dataIndex: 'type',
      sorter: true,
      width: '150px',
    })
  }
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setType(value)
    fetchList({
      ...pagination,
      type: value
    })
  };

  return (
    <>
      <div className='eyerising-page-header'>
        <div className='header-content' >


          <Row justify="space-between">
            <Col span={12}>
              <Space>
                <Search placeholder={t('work.SearchName')} onSearch={onSearch} style={{ width: 200 }} />
                <Select placeholder={t('work.filterUsertype')} style={{ width: 200 }} onChange={handleChange}>
                  <Option value="all">All User type</Option>
                  <Option value="admin">Administrator</Option>
                  <Option value="general">Support staff</Option>
                  <Option value="doctor">Clinician</Option>
                </Select>
                <Search placeholder={t('work.SearchPhone')} onSearch={handlePhoneChange} style={{ width: 200 }} />
                {user.type === 'admin' ?
                  <Search placeholder={t('work.searchByCompany')} onSearch={companyNameChange} style={{ width: 300 }} />
                  : ''}
                <Button className='table-btn' key="2" onClick={() =>
                  fetchList({
                    ...pagination
                  })}>
                  {t("work.Refresh")}</Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space style={{ float: 'right', marginRight: 10 }}>{
                user.userType === 'admin' || user.type === 'admin' ?
                  <Button icon={<PlusCircleOutlined />} onClick={() => setCompany({})} type='primary'>
                    {t("work.Create")}
                  </Button> : ''}
                <ExportButton exportApi={exportUserAsync} exportQuery={{
                  searchStr: searchStr,
                  type,
                  phone,
                  companyName
                }} />
              </Space>
            </Col>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content users-table">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowClassName={(record) => record._id === user._id ? 'active' : ""}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <AddUserModal
        record={record}
        setRecord={setCompany}
        visible={modelVisible}
        setVisible={setModelVisible}
        getList={() => fetchList(pagination)}
      />
    </>
  )
}


export default Companies;