// A mock function to mimic making an async request for data
import api from '../../utils/api'
export function addPlan(payload: any) {
  return api('/api/admin/plans', 'POST', payload)
}

export function exportPlans(payload: any) {
  return api('/api/admin/plans/export', 'GET', payload)
}
export function getPlans(payload: any) {
  return api('/api/admin/plans', 'GET', payload)
}

export function deletePlan(payload: any) {
  return api('/api/admin/plans', 'DELETE', payload)
}
export function getAllPlans(payload: any) {
  return api('/api/admin/plans/all', 'GET', payload)
}
