// A mock function to mimic making an async request for data
import api from '../../utils/api'

export function addCompany(payload: any) {
  return api('/api/admin/company', 'POST', payload)
}

export function exportCompanies(payload: any) {
  return api('/api/admin/companies', 'POST', payload)
}
export function getCompanies(payload: any) {
  return api('/api/admin/companies', 'GET', payload)
}
export function getAllCompanies(payload: any) {
  return api('/api/admin/companies/all', 'GET', payload)
}


export function getDashboardData(payload: any) {
  return api('/api/admin/dashboard', 'GET', payload)
}

export function putCompany(payload: any) {
  return api('/api/admin/company', 'PUT', payload)
}

export function deleteCompany(payload: any) {
  return api('/api/admin/company', 'DELETE', payload)
}