import { Input, Space, Button, Row, Popconfirm, Col, Select, Alert, message, Dropdown, Modal, Menu } from 'antd'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { useEffect, useState } from 'react';
import { DownOutlined, EyeInvisibleOutlined, RollbackOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Column } from '@ant-design/plots';
import {
  selectUser,
  signout
} from '../../reducers/account/accountSlice';
import SupporModal from '../Components/SupportModal'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { ExclamationCircleOutlined, FormOutlined, LogoutOutlined } from '@ant-design/icons';
import Report from './Report'
import {
  bindDeviceAsync,
  sendStaffEmailAsync,
  foundDeviceAsync,
  lostDeviceAsync
} from '../../reducers/portal/portalSlice';
interface propsObject {
  getUser: Function;
}
const { Option } = Select;
const MyDevice = (props: propsObject) => {
  const history = useHistory()
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser)
  const experiencingAccounts = user.accounts.filter((account: any) => {
    if (account.experiencing) {
      return true
    }
    return false
  })
  const [loading, setLoading] = useState(false)
  const [snr, setSNR] = useState('')
  const [reasons, setReasons] = useState('')
  const [accountId, setAccountId] = useState('')
  const [isRefund, setIsRefund] = useState(false)
  const [refundType, setRefundType] = useState('')
  const [reasonModal, setReasonsModal] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [usageData, setUsageData] = useState([] as any)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation()
  useEffect(() => {
    // form.setFieldsValue(user)
    const dateMap = {} as any
    const accounts: string[] = []
    const dates: string[] = []
    user.device && user.device.records.map((record: any) => {
      if (moment(record.createdAt).isAfter(moment().subtract(7, 'days').startOf('day'))) {
        if (!dateMap[record.accountName]) {
          dateMap[record.accountName] = {}
          accounts.push(record.accountName)
        }
        const dateKey = moment(record.createdAt).format('YYYY/MM/DD')
        if (!dateMap[record.accountName][dateKey]) {
          dateMap[record.accountName][dateKey] = {
            count: 0
          }
          dates.push(dateKey)
        }
        dateMap[record.accountName][dateKey].count++
      }
    })
    const finalDate = [] as any
    let dates7: string[] = []
    for (let d = 6; d >= 0; d--) {
      dates7.push(moment().subtract(d, 'days').startOf('day').format('YYYY/MM/DD'))
    }
    accounts.map((accountName: string) => {
      dates7.map((dt: string) => {
        if (dates.indexOf(dt) > -1) {
          finalDate.push({
            'Name': accountName,
            'Date': dt,
            'Count': dateMap[accountName][dt] ? dateMap[accountName][dt].count : 0
          })
        } else {
          finalDate.push({
            'Name': accountName,
            'Date': dt,
            'Count': 0
          })
        }
      })
    })
    setUsageData(finalDate)
  }, [])
  const showModal = () => {
    setIsModalOpen(true);
  };
  const dataConfig = {
    data: usageData,
    isGroup: true,
    xField: t('work.Date'),
    yField: 'Count',
    yAxis: {
      title: {
        text: t('work.NumberofTimes')
      },
      label: {
        formatter: (v: any) => ['0', '1', '2'].indexOf(v) > -1 ? v : '',
      },
      gridIndex: 0,
      min: 0,
      max: 2,
      interval: 1
    },
    xAxis: {
      title: {
        text: t('work.Date')
      },
    },
    seriesField: 'Name',
    /** 设置颜色 */

    /** 设置间距 */
    // marginRatio: 0.1,
    label: {
      content: (originData: any) => {
        return ''
      }
    },
  }
  const onFinish = async () => {
    setLoading(true)
    if (!snr) {
      return Modal.error({
        title: 'Error',
        content: t('work.Input_Serial_Number'),
      });
    }
    const result = await dispatch(bindDeviceAsync({
      serial_number: snr
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg, data } = payload
      if (code === 0) {
        props.getUser()
        setIsModalOpen(false);
        window.location.reload();
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const lostDevice = async () => {
    setLoading(true)
    const result = await dispatch(lostDeviceAsync({
      id: user.device._id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg, data } = payload
      if (code === 0) {
        props.getUser()
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const findDevice = async () => {
    setLoading(true)
    const result = await dispatch(foundDeviceAsync({
      id: user.device._id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg, data } = payload
      if (code === 0) {
        props.getUser()
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }


  const signOut = () => {
    Modal.confirm({
      title: t('work.Confirm_to_log_out'),
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        if (user.type == 'customer') {
          return history.push('/login')
        }
        history.push('/')

      },
      onCancel() {
      },
    })
  }
  const sendStaffEmail = (reason: String) => async () => {
    if (isRefund && !accountId) {
      return message.error(t('work.RefundAccount'));
    }
    setLoading(true)
    const parmas = {
      type: "Return",
      reason: reason,
      accountId: accountId,
      isRefund: isRefund,
      refundType,
      id: user.device._id
    }
    const result = await dispatch(sendStaffEmailAsync(parmas))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg, data } = payload
      if (code === 0) {
        message.success('Notify staff of success');
        setReasonsModal(false)
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsRefund(false)
  };



  const menu = (
    <Menu>
      <Menu.Item onClick={sendStaffEmail(t('work.ItsBroken'))}> {t('work.ItsBroken')} </Menu.Item>
      <Menu.Item onClick={sendStaffEmail(t('work.Myplanhasexpired'))}> {t('work.Myplanhasexpired')} </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item onClick={() => setReasonsModal(true)}> {t('work.Otherreasons')} </Menu.Item>
      {
        experiencingAccounts.length > 0 ?
          <Menu.Item onClick={() => {
            setIsRefund(true)
            setReasonsModal(true)
            setRefundType('gurantee')
          }}> {t('work.stopsubText')} </Menu.Item> : ''
      }
    </Menu>
  );

  return (
    <>
      {
        user.device ?
          <div style={{ marginTop: 0, background: '#fff', paddingTop: 10 }}>
            <Row>
              <Report />
            </Row>
            <Row gutter={40}>
              <Col md={4}>
              </Col>
              <Col md={10} xs={24}>
                <Row className='device-info-item'>
                  <Col span={10}>
                    {t('work.Device_Type')}
                  </Col>
                  <Col span={14}>{
                    user.device.catalog.reference
                  }</Col>
                </Row>
                <Row className='device-info-item'>
                  <Col span={10}>
                    {t('work.Device_Serial_Number')}
                  </Col>
                  <Col span={14}>{
                    user.device.serial_number
                  }</Col>
                </Row>
                <Row className='device-info-item'>
                  <Col span={10}>
                    {t('work.UDI_Number')}
                  </Col>
                  <Col span={14}>{
                    user.device.udi
                  }</Col>
                </Row>
                <Row className='device-info-item'>
                  <Col span={10}>
                    {t('work.Create_Date')}
                  </Col>
                  <Col span={14}>{
                    user.device.createdDate ? moment(user.device.createdDate).format('DD/MM/YYYY') : ''
                  }</Col>
                </Row>
                <Row className='device-info-item'>
                  <Col span={10}>
                    {t('work.Binding_Date')}
                  </Col>
                  <Col span={14}>{
                    user.device.distributedAt ? moment(user.device.distributedAt).format('DD/MM/YYYY') : ''
                  }</Col>
                </Row>
                <Row className='device-info-item'>
                  <Col span={10}>
                    {t('work.Status')}
                  </Col>
                  <Col span={14}>{
                    user.device.status
                  }</Col>
                </Row>
                <Row className='device-info-item'>
                  <Col span={10}>
                    {t('work.Last_usage')}
                  </Col>
                  <Col span={14}>{
                    user.device.records.length > 0 ? moment(user.device.records[0].createdAt).format('DD/MM/YYYY') : ''
                  }</Col>
                </Row>
              </Col>
              <Col md={10} xs={24}>
                <Column {...dataConfig} />
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }} gutter={0}>
              <Col md={7} xs={24}>
              </Col>
              <Col md={4} xs={24}>
                {
                  user.device && user.device.status === 'Lost' ?
                    <Popconfirm
                      title={t('work.Are_you_sure_device_is_found')}
                      onConfirm={() => findDevice()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type='primary' className="portal-submit">
                        {t('work.Ifoundthisdevice')}
                      </Button>
                    </Popconfirm> :
                    <Popconfirm
                      title={t('work.Are_you_sure_device_is_lost')}
                      onConfirm={() => lostDevice()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type='primary' ghost className="portal-submit">
                        <EyeInvisibleOutlined />
                        {t('work.Ilostthisdevice')}
                      </Button>
                    </Popconfirm>
                }
              </Col>
              <Col xs={24} md={6}>
                <Dropdown overlay={menu}
                  trigger={['click']}
                >
                  <Button type='primary' className="portal-submit">
                    <Space>
                      <RollbackOutlined />
                      {t('work.Ineedreturnthisdevice')}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
              <Col xs={24} md={0}>
                <Button onClick={() => setSupportModal(true)} className="portal-submit"><FormOutlined />
                  {t('work.Support')}
                </Button>
              </Col>
              <Col xs={24} md={0}>
                <Button onClick={signOut} className="portal-submit">
                  <LogoutOutlined />
                  {t('work.Logout')}
                </Button>
              </Col>
            </Row>
            <Modal title={isRefund ? 'Input reasons' : "Other reasons"} open={reasonModal} onOk={sendStaffEmail(reasons)}
              onCancel={() => {
                setIsRefund(false)
                setRefundType('')
                setReasonsModal(false)
              }
              }>
              {
                isRefund ?
                  <Select style={{ width: '100%', marginBottom: 20 }} placeholder={t('work.RefundAccount')} onChange={(v: string) => setAccountId(v)}>
                    {
                      user.accounts.filter((account: any) => {
                        if (refundType === 'gurantee') {
                          if (account.experiencing) {
                            return true
                          }
                          return false
                        }
                        return true
                      }).map((account: any) => <Option value={account._id} key={account._id}>{account.firstName} {account.lastName}</Option>)
                    }
                  </Select> : ""
              }
              <Input.TextArea onChange={(v: any) => setReasons(v.target.value)} placeholder={t('InputReasons')} />
            </Modal>
            <SupporModal visible={supportModal} setVisible={setSupportModal} />
          </div>
          :
          <div style={{ width: '95%' }}>
            <Button className='portal-submit' style={{ float: 'right' }} type="primary" onClick={showModal}>
              {t('work.Bind_a_device')}
            </Button>
            <Modal title={t('work.BindTitle')} open={isModalOpen} onOk={onFinish} onCancel={handleCancel}>
              <Input onChange={(v: any) => setSNR(v.target.value)} placeholder={t('work.Device_Serial_Number')} />
              <Alert
                style={{ margin: '10px 0 10px' }}
                message={t('work.BindInstructions')}
                type="success"
              />
            </Modal>
          </div>
      }
    </>
  );
}

export default MyDevice
