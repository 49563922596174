import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {putAWSSignurl,
  postAWSSignurl,
  userPostAWSSignurl
} from './awsAPI';
const initialState = {}

export const postAWSSignurlAsync = createApiAsyncThunk<any>('aws/postAWSSignurl', postAWSSignurl)
export const userPostAWSSignurlAsync = createApiAsyncThunk<any>('aws/userPostAWSSignurl', userPostAWSSignurl)

export const catalogsSlice = createSlice({
  name: 'aws',
  initialState,
  reducers: {}
})

export default catalogsSlice.reducer;
