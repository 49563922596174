import { Form } from 'antd';
import GoogleMapReact from 'google-map-react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { EnvironmentFilled } from '@ant-design/icons';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

interface propsObject {
  setCenter: Function;
  setAddress: Function;
  title?: string;
  center: any;
  required?: boolean;
  address: string;
}
const AddressSelect = (props: propsObject) => {
  const { t } = useTranslation()

  const defaultProps = {
    center: {
      lat: -33.851923390266165,
      lng: 151.21838655370252
    },
    zoom: 15
  };

  const AnyReactComponent = (props: any) => {
    return (
      <div style={{ width: 200, fontSize: 20, fontWeight: 800 }} className='marker'>
        <EnvironmentFilled style={{ color: 'red' }} />
      </div>
    )
  };

  const setPlace = (v: any) => {
    geocodeByAddress(v.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        props.setCenter({
          lat, lng
        })
        console.log('Successfully got latitude and longitude', { lat, lng })
      });
    props.setAddress(v)
  }

  return (
    <>
      <div style={{ marginBottom: 30 }} className="address-select">
        <Form.Item required={props.required == undefined ? true : props.required} label={props.title ? props.title : t('work.Address')} >
          <GooglePlacesAutocomplete
            selectProps={{
              value: props.address,
              address: props.address,
              onChange: setPlace,
              placeholder: props.address || ''
            }} apiKey={'AIzaSyDZoOJSt6Aadoo5aZN7Es5ASc04zBiqVTU'} />
        </Form.Item>
      </div>
      <div style={{ height: '600px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDZoOJSt6Aadoo5aZN7Es5ASc04zBiqVTU' }}
          defaultCenter={props.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={props.center.lat}
            lng={props.center.lng}
          />
        </GoogleMapReact>
      </div>
    </>
  )
}


export default AddressSelect;