import { Button, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import config from '../../config/config'
import { useAppDispatch } from '../../app/hooks'
import axios from 'axios'
import { ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
interface propsObject {
  exportApi: Function;
  exportQuery: any;
  text?: string;
}
const ExportButton  = (props: propsObject) =>  {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [suffix, setSuffix] = useState('')
  const [link, setLink] = useState('');
  const { t, i18n } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileQueue, setFileQueue] = useState([] as any)
  const [fileName, setFileName] = useState('')
  const [exportLoading, setExportLoading] = useState(false)
  useEffect(() => {
  }, [])

  const getURL = async (url: string) => {
    let result = {} as any
    axios.defaults.timeout = 60 * 1000;
    try {
      result = await axios.head(url)
    } catch (err) {
      console.log(err)
    }
    if (!result.headers || result.headers['content-type'].indexOf('text/html;') > -1) {
      setTimeout(() => getURL(url), 5000)
    } else {
      setLink(url)
      setExportLoading(false)
      setIsModalVisible(true)
    }
  }

  const exportList = async(searchStr?: string) => {
    setExportLoading(true)
    const result = await dispatch(props.exportApi(props.exportQuery))
    setExportLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setSuffix(data.suffix)
        setFileName(data.name)
        let queue = []
        for (let index = 0; index < data.fileCount; index++) {
          queue.push(index + 1)
        }
        setFileQueue(queue)
        let url = `${config.domain}/exports/export-${data.name}-${data.fileCount}.${data.suffix}`
        if (data.suffix === 'txt' || data.suffix === 'sh') {
          url = `${config.domain}/exports/export-${data.name}.${data.suffix}`
        }
        getURL(url)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };


  return (
    <>
      <Button icon={<ExportOutlined />} loading={exportLoading} onClick={() => exportList()} key="3" type='primary' ghost>
        {props.text? props.text : t("Export")}
      </Button>
      <Modal width={800} title={t("work.Export_info")} visible={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)}>
        { suffix === 'txt' || suffix === 'sh' ?
          <p> {t("work.Download_Link")}:  <a target="_blank" href={link}>{link}</a> </p>
        :
          fileQueue.map((index:number, idx: number) => {
            return <p key={idx}>{index}. {t("work.Download_Link")}: <a target="_blank" href={`${config.domain}/exports/export-${fileName}-${index}.${suffix}`}>{`${config.domain}/exports/export-${fileName}-${index}.${suffix}`}</a></p>
          })
        }
      </Modal>
    </>
  )
}


export default ExportButton;