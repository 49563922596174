import { Form, Input, Button, Row, Select, Col, message, Modal, Tooltip, Switch } from 'antd'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { TimeZoneSelectDialog } from 'react-timezone-map-select';
import { Link, useLocation, useHistory } from 'react-router-dom'
import md5 from 'js-md5'
import {
  signout,
  selectUser
} from '../../reducers/account/accountSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  updateCustomerInfoAsync,
  getDoctorsAsync
} from '../../reducers/portal/portalSlice';
import mobileCountryCodes from '../../config/mobileCountryCodes'
import AddressSelect from '../Components/AddressSelect';
import config from '../../config/config';
interface propsObject {
  getUser: Function;
}
const { Option } = Select;
const MyDetail = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const [needRequired, setNeedRequired] = useState(false)
  const [doctors, setDoctors] = useState([])
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [timezoneName, setTimezoneName] = useState('America/Los_Angeles');

  const [address, setAddress] = useState(null as any)
  const [center, setCenter] = useState({
    lat: -33.851923390266165,
    lng: 151.21838655370252
  })
  const [mobileCountryCode, setMobileCountryCode] = useState('81')
  const selectBefore = (
    <Select value={mobileCountryCode} onChange={(value: string) => setMobileCountryCode(value)} className="select-before">
      {
        mobileCountryCodes.map((code: any) =>
          <Option key={code.code} value={code.code}>{code.code}</Option>)
      }
    </Select>
  );
  /** Set true when you open TimeZoneSelectDialog. */
  const [open, setOpen] = useState(false);

  /** Called when you press "Open Dialog" button. */
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  /** Called when TimeZoneSelectDialog is closed. */
  const handleClose = useCallback((newTimeZoneName: string) => {
    console.log(newTimeZoneName)
    setTimezoneName(newTimeZoneName);
    setOpen(false);
  }, []);
  const [resetPassword, setResetPassword] = useState(false)
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  useEffect(() => {
    setAddress('Search address')
    setCenter({
      lat: -33.851923390266165,
      lng: 151.21838655370252
    })
    user.location && setCenter(user.location)
    user.address && setAddress(user.address)
    fetchAllDoctors()
    setTimezoneName(user.timezone || '')
    setMobileCountryCode(user.mobileCountryCode || '81')
    form.setFieldsValue({
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      email: user.email,
      phone: user.phone,
      language: user.language,
      zip: user.zip,
      country: user.country,
      doctorId: user.doctorId || '',
      referenceNumber: user.referenceNumber || '',

    })
    // form.setFieldsValue(user)
  }, [])
  const fetchAllDoctors = async () => {
    setLoading(true)
    const result = await dispatch(getDoctorsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDoctors(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const onFinish = async () => {
    form.validateFields()
      .then(async values => {
        if (!timezoneName) {
          return message.error(t('work.SelctTimezone'))
        }
        if (address) {
          values.address = address.label
        }
        values.location = center
        if (values.needRequired) {
          if (!address || !address.label) {
            if (user.address) {
              values.address = user.address
            } else {
              return message.error(t('work.address_message'))
            }
          }
        }
        values.timezone = timezoneName
        values.resetPassword = resetPassword
        values.mobileCountryCode = mobileCountryCode
        setLoading(true)
        try {
          if (values.password) {
            values.password = md5(values.password)
          }
        } catch (err) {
          console.log(err)
        }
        const result = await dispatch(updateCustomerInfoAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            message.success('Successful operation')
            props.getUser()
            if (values.resetPassword) {
              localStorage.removeItem('token')
              localStorage.removeItem('tokenExpiredAt')
              dispatch(signout)
              if (user.type == 'customer') {
                return history.push('/login')
              }
              history.push('/')
            }
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value)
  }
  return (
    <div style={{ background: '#fff', padding: '10px 20px' }}>
      <Form {...layout} form={form}
        name="control-hooks"
        onFinishFailed={onFinishFailed}

        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col md={12} xs={24}>
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item name="firstName" label={t('work.firstName')} rules={[{ required: true, message: t('work.FirstNameRequiredText') }
                ]}>
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="middleName" label={t('work.middleName')}>
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="lastName" label={t('work.lastName')} rules={[{ required: true, message: t('work.LastNameRequiredText') }]}>
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={t('work.Username_email')} name="email">
              <Input placeholder="" disabled />
            </Form.Item>

            <Form.Item
              rules={[{ required: true, message: t('work.Country_Text') }]}
              name="country" label={t("work.Country")}  >
              <Select placeholder={t("work.Country")}
                onChange={(value) => {
                  config.countries.map((cou: any) => {
                    if (cou.key === value) {
                      console.log(cou)
                      setMobileCountryCode(cou.mobileCountryCode)
                      setTimezoneName(cou.timezone)
                    }
                  })
                }
                }
              >
                {config.countries.map((cou: any) =>
                  <Option key={cou.key} value={cou.key}>{cou.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item label={`${t('work.Phone')} (${t('work.PhoneNumberText')})`} name="phone"
              rules={[{ required: true, message: t('signup.Please_input_your_phone') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value.length >= 8) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('work.phoneLengthText')));
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  var reg = new RegExp(/^\d+$/);
                  if (reg.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('work.phoneNumberText')));
                },
              })]}>
              <Input placeholder="" addonBefore={selectBefore} />
            </Form.Item>
            <Form.Item label={t('work.Timezone')} name="timezone" required>
              <Input.Group compact>
                <Input disabled style={{ width: 'calc(100% - 80px)' }} placeholder="" value={timezoneName} />
                {
                  !user.timezoneUpdateDate || moment(user.timezoneUpdateDate).isBefore(moment()) ?
                    <Button type="primary" onClick={handleOpen} style={{ float: "right" }} >
                      {t('work.Edit')}
                    </Button> :
                    <Tooltip title={`${t('work.editTimezoneText')} ${moment(user.timezoneUpdateDate).format('DD/MM/YYYY')}`}>
                      <Button type="primary" disabled style={{ float: "right" }} >
                        {t('work.Edit')}
                      </Button>
                    </Tooltip>
                }
              </Input.Group>
              <TimeZoneSelectDialog
                open={open}
                title={t('work.Timezone')}
                buttonLabelOk={t('work.Submit')}
                buttonLabelCancel={t('work.Cancel')}
                description={t('work.timezoneDescription')}
                timeZoneName={timezoneName}
                onClose={handleClose}
              />
            </Form.Item>
            <Form.Item label={t('work.Language')} name="language">
              <Select onChange={changeLanguage}>
                <Option value='en'>English</Option>
                <Option value='ja'>日本語</Option>
                <Option value='ms'>Bahasa Melayu</Option>
                <Option value='tr'>Türkçe</Option>
                <Option value='pl'>język polski</Option>
                <Option value='es'>Español</Option>
                <Option value='de'>Deutsch</Option>
                <Option value='fr'>Français</Option>
              </Select>
            </Form.Item>
            {
              (resetPassword ?
                <>
                  <Form.Item
                    name="password" label={t('work.Password')}
                    rules={[
                      {
                        required: true,
                        message: t('work.password_message'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && value.length >= 8) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('work.passwordlengthText')));
                        },
                      }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
                          if (reg.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('work.passwordText')));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label={t('work.Confirmpassword')}
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: t('work.confirm_message'),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('work.The_two_passwords_message')));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
                :
                <Form.Item name="password" label={t('work.Password')} >
                  <Button onClick={() => setResetPassword(true)} >{t('work.Reset_Password')}</Button>
                </Form.Item>)
            }
            <Form.Item name="needRequired" label={t("work.DirectShippingRequired")} >
              <Switch defaultChecked={needRequired} onChange={(value) => setNeedRequired(value)} checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item required={needRequired} name="zip" label={t("work.Zip")} rules={[
              {
                required: needRequired, message: t('work.zipRequired')
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  var reg = new RegExp(/^\d+$/);
                  if (reg.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('work.zipText')));
                },
              })]}>
              <Input placeholder={t("work.Zip")} />
            </Form.Item>
            <AddressSelect
              title={t(`work.Billing_address`)}
              center={center}
              address={address}
              required={needRequired}
              setCenter={setCenter}
              setAddress={setAddress}
            />
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label={t('work.MyClinicianName')} >
              <Input placeholder="" disabled value={user.company && user.company.name} />
            </Form.Item>

            {user.company.databaseUrl !== 'jp'
              ? <Form.Item label={t('work.MyClinicianEmail')}>
                <Input placeholder="" disabled value={user.company && user.company.owner_email} />
              </Form.Item>
              : <Form.Item label={t('work.MyClinicianPhone')}>
                <Input placeholder="" disabled value={`+${user.company.owner_mobileCountryCode} ${user.company.owner_number}`} />
              </Form.Item>}
            <Form.Item label={t('work.MyClinicianAddress')}  >
              <Input placeholder="" disabled value={user.company && user.company.address} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button className='portal-submit' loading={loading} type="primary" htmlType="submit">
            {t('work.Save')}
          </Button>
        </Form.Item>

      </Form>
    </div >
  );
}

export default MyDetail
