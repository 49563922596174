import logo2 from '../../assets/logo2.png'

import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { selectUser } from '../../reducers/account/accountSlice';
import { useTranslation } from 'react-i18next';

const AdminSlider = () => {
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const { t } = useTranslation();

  return (
    <Menu
      defaultSelectedKeys={[location.pathname]}
      mode="inline"
    >
      <Menu.Item disabled className="sider-title" key="title" style={{
        width: 300,
        cursor: 'auto'
      }}>
        <img style={{ maxHeight: '35px' }} src={logo2} alt="" /> ----
      </Menu.Item>
      <Menu.Item key="/admin"><Link to="/admin">{t("work.Dashboard")}</Link></Menu.Item>
      <Menu.Item key="/admin/companies"><Link to="/admin/companies"> {t("work.Companies")}</Link></Menu.Item>
      <Menu.Item key="/admin/users"><Link to="/admin/users"> {t("work.Users")}</Link></Menu.Item>
      {
        user.type === 'admin' ?
          <Menu.Item key="/admin/products"><Link to="/admin/products"> {t("work.Products")}</Link></Menu.Item>
          : ''
      }
      <Menu.Item key="/admin/plans"><Link to="/admin/plans"> {t("work.Plans")}</Link></Menu.Item>
      <Menu.Item key="/admin/customers"><Link to="/admin/customers"> {t("work.Patients")}</Link></Menu.Item>
      <Menu.Item key="/admin/inventory"><Link to="/admin/inventory"> {t("work.Inventory")}</Link></Menu.Item>
      <Menu.Item key="/admin/payments"><Link to="/admin/payments"> {t("work.Payments")}</Link></Menu.Item>
      <Menu.Item key="/admin/versions"><Link to="/admin/versions"> {t("work.Versions")}</Link></Menu.Item>
      <Menu.Item key="/admin/setting"><Link to="/admin/setting">{t("work.Settings")}</Link></Menu.Item>
      <Menu.Item key="/admin/invoice-reports"><Link to="/admin/invoice-reports">{t("work.InvoiceReports")}</Link></Menu.Item>
    </Menu>
  )
}

export default AdminSlider;