import { Progress } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { selectUser } from '../../reducers/account/accountSlice';
import { useAppSelector } from '../../app/hooks'

const PaymentResult = () => {
  const user = useAppSelector(selectUser)
  const [percent, setPercent] = useState(100)
  const history = useHistory()
  const { t, i18n } = useTranslation()

  let returnUrl: string
  switch (user.type) {
    case 'admin':
      returnUrl = `/admin/customers`
      break
    case 'normal':
      returnUrl = `/work/customers`
      break
    case 'customer':
      returnUrl = `/index/accounts`
      break
  }

  return (
    <>
      <div style={{
        margin: '100px auto',
        textAlign: 'center'
      }}>
        <Progress type="circle" percent={percent} />
        <p style={{ fontSize: 22, marginTop: 50 }}>
          {t('work.PaymentSuccess')}
        </p>
      </div>
    </>
  )
}

export default PaymentResult;