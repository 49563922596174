import logo from '../assets/logo2.png'
import './SignIn.scss'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Modal } from 'antd'
import { useAppDispatch } from '../app/hooks'
import {
  feachAccountAsync,
  feachAccountByTokenAsync,
  restore,
  restoreUser
} from '../reducers/account/accountSlice';
import ReCAPTCHA from "react-google-recaptcha";
import md5 from 'js-md5'
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const SignIn = () => {
  let siteKey = '6LcxbsIdAAAAAAzKDEM6QpIVAC1RzCUY9ahYeqoo'
  const dispatch = useAppDispatch();
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();
  const recaptchaRef = useRef<any>()
  const onFinish = async (values: any) => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    values.password = md5(values.password)
    values['g-recaptcha-response'] = recaptchaValue
    setLoading(true)
    const result = await dispatch(feachAccountAsync({
      ...values,
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { data, code, msg } = payload
      if (code === 0) {
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        localStorage.setItem('token', data.token)
        localStorage.setItem('tokenExpiredAt', data.tokenExpiredAt.getTime())
        if (data.user.type === 'admin') {
          return history.push('/admin')
        }
        if (data.user.type === 'normal') {
          return history.push('/work/customers')
        }
        history.push('/work/all')
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const signInByToken = async () => {
    const token = localStorage.getItem('token')
    if (token) {
      const result = await dispatch(feachAccountByTokenAsync({}))
      const { payload } = result
      if (payload) {
        const { data, code, msg } = payload
        if (code === 0) {
          dispatch(restore({
            token: data.token,
            tokenExpiredAt: data.tokenExpiredAt.toString()
          }))
          dispatch(restoreUser({ user: data.user }))
          history.push('/admin')
        } else {
        }
      } else {
      }
    } else {
    }
  }

  useEffect(() => {
    signInByToken()
  }, [])
  return (
    <div style={{ margin: '100px auto 0' }}>
      <Form
        className="signin-form"
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="logo-box">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>
            {t('sign.BackendLogin')}
          </h1>
        </div>
        <Form.Item
          className="sign-input"
          label={t('sign.Email')}
          name="email"
          rules={[{ required: true, message: t('sign.Please_input_your_email') }, {
            type: 'email', message: t('work.EmailType')
          }]}
        >
          <Input />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Link to='/forgotpassword' type="link" style={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}>
            {t('sign.Forgot_password')}
          </Link>
          <Form.Item
            className="sign-input"
            label={t('sign.Password')}
            name="password"
            rules={[{ required: true, message: t('sign.Please_input_your_password') }]}
          >
            <Input.Password />
          </Form.Item>
        </div>
        {
          process.env.NODE_ENV === 'production' && !loading ? (
            <Form.Item
              className="sign-input sign-captch"
              label="Captcha"
              rules={[{ required: true, message: t('sign.Please_input_captcha') }]}
            >
              <ReCAPTCHA
                sitekey={siteKey}
                ref={recaptchaRef}
              />
            </Form.Item>) : ''
        }
        <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          <Button style={{ width: '100%', height: '50px' }} loading={loading} type="primary" htmlType="submit">
            {t('sign.Login')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SignIn;
