import { Layout, Space, Button, Popconfirm, Table, Input, Select, Modal, message, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import config from '../../config/config'
import { useAppDispatch } from '../../app/hooks'
import ImportDeviceModal from './ImportDeviceModal'
import {
  getDevicesAsync,
  deleteDeviceAsync,
  exportDevicesAsync,
  getBarcodesAsync
} from '../../reducers/devices/devicesSlice'
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import ExportButton from '../Components/exportButton'
import DeviceModal from './DeviceModal'// 在JS文件中引入这两个插件
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import JsBarcode from 'jsbarcode'
import './Common.scss'
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const Devices = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  let [modelVisible, setModelVisible] = useState(false)
  const [type, setType] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [ownership, setOwnership] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [barcodes, setBarcodes] = useState([])
  const [barcodeVisible, setBarcodeVisible] = useState(false)
  const { t, i18n } = useTranslation()
  const [importVisible, setImportVisible] = useState(false)
  const [searchStr, setsearchStr] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [record, setRecord] = useState({})
  const setCompany = (record: any) => {
    setRecord(record)
    setModelVisible(true)
  }
  useEffect(() => {
    fetchList(pagination)
  }, [])

  const onSearch = async (value: string) => {
    setsearchStr(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList(pagination, value)
  }

  const companyNameChange = (value: string) => {
    setCompanyName(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      companyName: value
    })
  };

  const ownershipChange = (value: string) => {
    setOwnership(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      ownership: value
    })
  };
  const orderNumberChange = (value: string) => {
    setOrderNumber(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      orderNumber: value
    })
  };
  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    params.companyName = params.companyName || companyName
    params.orderNumber = params.orderNumber || orderNumber
    params.ownership = params.ownership || ownership
    params.type = params.type || type
    !params.order && delete params.order
    !params.sort && delete params.sort
    for (let key in params) {
      if (params[key] === 'NONE_STR') {
        params[key] = ''
      }
    }
    const result = await dispatch(getDevicesAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const download = () => {
    const list = []
    var imgList = document.querySelectorAll("#barcode-box img")
    for (let i = 0; i < imgList.length; i++) {
      let img = imgList[i] as any
      list.push({
        src: img.src,
        picName: `${i}_${img.alt}`
      })
    }
    downloadZip(list)
  }
  const getImageBase64 = (image: any) => {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext('2d') as any
    ctx.drawImage(image, 0, 0, image.width, image.height)
    // 获取图片后缀名
    const extension = image.src
      .substring(image.src.lastIndexOf('.') + 1)
      .toLowerCase()
    // 某些图片 url 可能没有后缀名，默认是 png
    return canvas.toDataURL('image/' + extension, 1)
  }

  /**
   * 批量下载图片
   * @param sourceList图像数据
   * @param zipName // 下载时的文件夹名称
   * @param sourceList[i].picName // 每张图片的名称--注意: 需要对数据属性进行转换
   */
  const downloadZip = async (sourceList: any, zipName = 'barcodes') => {
    const zip = new JSZip()
    const fileFolder = zip.folder(zipName) as any // 创建 zipName 文件夹
    const fileList = [] as any
    for (let i = 0; i < sourceList.length; i++) {
      const name = sourceList[i].picName // 注意: 每张图片的名称--需要对数据属性进行转换
      const image = new Image()
      image.setAttribute('crossOrigin', 'Anonymous') // 设置 crossOrigin 属性，解决图片跨域报错
      image.src = sourceList[i].src
      image.onload = async () => {
        const url = await getImageBase64(image)
        fileList.push({ name: name, img: url.substring(22) }) // 截取 data:image/png;base64, 后的数据
        if (fileList.length === sourceList.length) {
          if (fileList.length) {
            for (let k = 0; k < fileList.length; k++) {
              // 往文件夹中，添加每张图片数据
              fileFolder.file(fileList[k].name + '.png', fileList[k].img, {
                base64: true
              })
            }
            zip.generateAsync({ type: 'blob' }).then(content => {
              FileSaver.saveAs(content, zipName + '.zip')
            })
          }
        }
      }
    }
  }


  const exportBarcodes = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    params.companyName = params.companyName || companyName
    params.ownership = params.ownership || ownership
    !params.order && delete params.order
    !params.sort && delete params.sort
    for (let key in params) {
      if (params[key] === 'NONE_STR') {
        params[key] = ''
      }
    }
    const result = await dispatch(getBarcodesAsync(params))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        console.log(data.list)
        setBarcodeVisible(true)
        setBarcodes(data.list)
        setTimeout(() => {
          data.list.map((barcode: any) => {
            console.log(barcode)
            try {
              JsBarcode(`#id${barcode._id}`, `(1)${barcode.udi || ''}(21)${barcode.serial_number || ''}(11)${moment(barcode.createdDate).format('YYMMDD') || ''}`)
            } catch (err) {
              console.log(err)
            }
          })
          setLoading(false)

        }, 0)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const deleteItem = async (id: string,) => {
    setLoading(true)
    const result = await dispatch(deleteDeviceAsync({
      id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({ ...pagination })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination, type })
  }

  const handleChange = (value: string) => {
    setType(value)
    fetchList({
      ...pagination,
      database: 'all',
      type: value
    })
  };


  const columns = [
    {
      title: t('work.Product'),
      dataIndex: 'productName',
      width: '200px',
    },
    {
      title: t('work.Create_Date'),
      dataIndex: 'createdDate',
      width: '200px',
      render: (createdDate: string) => (
        <>
          <span>{moment(createdDate).format('DD/MM/YYYY')}</span>
        </>
      ),
    },
    {
      title: t('work.Serial_Number'),
      dataIndex: 'serial_number',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Type'),
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: t('work.QR_Code'),
      dataIndex: 'qrcode',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Order_Number'),
      dataIndex: 'order_number',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.UDI_Number'),
      dataIndex: 'udi',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Software_Version'),
      dataIndex: 'version',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Company'),
      dataIndex: 'companyName',
      width: '200px',
    },
    {
      title: t('work.Ownership'),
      dataIndex: 'ownershipName',
      width: '200px',
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      width: '300px',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type='primary' size='small' onClick={() => setCompany(record)} key={record._id}>
            {t("work.Edit")}
          </Button>
          {
            record.customerId ?
              <Button disabled={record.customerId} danger size='small' key={`${record._id}delete`}>
                {t("work.Delete")}
              </Button> :
              <Popconfirm
                title="Are you sure to delete this device?"
                onConfirm={() => deleteItem(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={record.customerId} danger size='small' key={`${record._id}delete`}>
                  {t("work.Delete")}
                </Button>
              </Popconfirm>
          }
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className='eyerising-page-header'>
        <div className='header-content' >
          <Row justify="space-between">
            <Col span={4}>
              <Space>
                <Search placeholder={t('work.SearchSerialNumber')} onSearch={onSearch} style={{ width: 200 }} />
                <Search placeholder={t('work.Company_Name')} onSearch={companyNameChange} style={{ width: 200 }} />
                <Search placeholder={t('work.SearchOrderNumber')} onSearch={orderNumberChange} style={{ width: 200 }} />
                <Select placeholder={t('work.FilterByDeviceType')} style={{ width: 250 }} onChange={handleChange} defaultValue={'all'}>
                  <Option value="all">ALL</Option>
                  <Option value="COMMERCIAL">COMMERCIAL</Option>
                  <Option value="TESTING">TESTING</Option>
                  <Option value="DEMO">DEMO</Option>
                  <Option value="CLINICAL">CLINICAL</Option>
                </Select>
                <Button className='table-btn' key="2" onClick={() =>
                  fetchList({
                    ...pagination,
                    type
                  })}> {t("work.Refresh")}</Button>
              </Space>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 10 }}>
            <Space style={{ float: 'right', marginRight: 10 }}>

              <Button icon={<CloudUploadOutlined />} onClick={() => setImportVisible(true)} type='primary'>
                {t('work.Batch_register')}
              </Button>
              <Button icon={<PlusCircleOutlined />} onClick={() => setCompany({})} type='primary'>

                {t("work.Register")}
              </Button>
              <ExportButton exportApi={exportDevicesAsync} exportQuery={{
                searchStr: searchStr,
                ownership,
                orderNumber,
                companyName,
                type
              }} />
              <Button className='table-btn' key="2" onClick={() => {
                exportBarcodes({
                  searchStr: searchStr,
                  ownership,
                  orderNumber,
                  companyName
                })
              }}> {t("work.ExportBarcodes")}</Button>
            </Space>
          </Row>
        </div>
      </div>
      <Modal
        title={t("work.ExportBarcodes")}
        onOk={() => download()}
        confirmLoading={loading}
        visible={barcodeVisible} okText={t('Download')} onCancel={() => setBarcodeVisible(false)}>
        <div className='barcode-box' id='barcode-box' style={{ display: 'none' }}>
          {
            barcodes.map((barcode: any) =>
              <img id={`id${barcode._id}`} className='barcode' alt={barcode.serial_number} key={barcode._id} />
            )
          }
        </div>
        <p>
          {t('work.Total')}: {barcodes.length}
        </p>
      </Modal>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <DeviceModal
        record={record}
        setRecord={setCompany}
        visible={modelVisible}
        setVisible={setModelVisible}
        getList={() => fetchList(pagination)}
      />

      <ImportDeviceModal
        getList={() => fetchList(pagination)}
        visible={importVisible}
        setVisible={setImportVisible}
      />
    </>
  )
}


export default Devices;