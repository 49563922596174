import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  updateCustomer,
  getPayments,
  getAccounts,
  addAccountByAdmin,
  updateAccountExpiredDate,
  getRecords,
  bindDeviceByAdmin,
  unbindDeviceByAdmin,
  deleteAccount,
  getRequests,
  turnOffRenew
} from './customersAPI';

const initialState = {}

export const updateAccountExpiredDateAsync = createApiAsyncThunk<any>('customers/updateAccountExpiredDate', updateAccountExpiredDate)
export const updateCustomerAsync = createApiAsyncThunk<any>('customers/updateCustomer', updateCustomer)
export const turnOffRenewAsync = createApiAsyncThunk<any>('customers/turnOffRenew', turnOffRenew)
export const getAccountsAsync = createApiAsyncThunk<any>('customers/getAccounts', getAccounts)
export const addAccountByAdminAsync = createApiAsyncThunk<any>('customers/addAccountByAdmin', addAccountByAdmin)
export const getRecordsAsync = createApiAsyncThunk<any>('customers/getRecords', getRecords)
export const getRequestsAsync = createApiAsyncThunk<any>('customers/getRequests', getRequests)
export const getPaymentsAsync = createApiAsyncThunk<any>('customers/getPayments', getPayments)
export const bindDeviceByAdminAsync = createApiAsyncThunk<any>('customers/bindDeviceByAdmin', bindDeviceByAdmin)
export const deleteAccountAsync = createApiAsyncThunk<any>('customers/deleteAccount', deleteAccount)
export const unbindDeviceByAdminAsync = createApiAsyncThunk<any>('customers/unbindDeviceByAdmin', unbindDeviceByAdmin)

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {}
})

export default customersSlice.reducer;
