import { Form, Input, Button, Row, Select, Table, message, Space } from 'antd'
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import moment from 'moment'
import { useAppDispatch } from '../../app/hooks'

import {
  getRequestsAsync,
} from '../../reducers/customers/customersSlice'
interface propsObject {
  setVisible: Function;
  record: any;
  form: any;
  setRecord: Function;
  products: any;
  plans: any;
  getList: Function;
}
const { Option } = Select;
const Usage = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [records, setRecords] = useState([])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const fetchList = async (params: any) => {
    setLoading(true)
    params = params || pagination
    !params.order && delete params.order
    !params.sort && delete params.sort
    const result = await dispatch(getRequestsAsync({
      userId: props.record._id,
      ...params
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setRecords(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  useEffect(() => {
    if (props.record._id) {
      fetchList(pagination)
    }
  }, [props.record._id])

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }

  const columns = [
    {
      title: t('work.Product'),
      dataIndex: 'productName',
      width: '200px',
      render: (productName: string) => (
        <>
          <span>{productName ? productName : 'No device'}</span>
        </>
      ),
    },
    {
      title: t('work.Date'),
      dataIndex: 'createdAt',
      sorter: true,
      width: '200px',
      render: (createdAt: string) => (
        <>
          <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
        </>
      ),
    },
    {
      title: t('work.Content'),
      dataIndex: 'content',
      width: '400px',
      render: (content: string) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: content }}></span>
        </>
      ),
    }
  ];
  return (
    <>

      <Row style={{ width: '100%', marginBottom: 50 }}>

        <Table
          bordered
          columns={columns}
          showSorterTooltip={false}
          rowKey={(record: any) => record._id}
          dataSource={records}
          loading={loading}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          onChange={handleTableChange}
        />
      </Row>
    </>
  );
}

export default Usage
