import ReactDOM from 'react-dom';
import './index.scss';
import 'antd/dist/antd.less'; //官方提供的 less 样式入口文件
// import 'your-theme-file.less'; // 用于覆盖上面定义的变量

import { store, history } from './app/store';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Router from './Router'
import './i18n'

import Hotjar from '@hotjar/browser';

const siteId = 3846998;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

declare global {
  interface Window {
    loading: any,
    clipboardData: any
  }
}
window.loading = { status: 'idle' }
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
