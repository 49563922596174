import { useState, useEffect } from "react";
import moment from "moment";
import { Form, Input, Button, Row, Select, Col, Space, Modal, message, Popconfirm } from "antd";
import { useAppDispatch } from "../../app/hooks";
import { addAccountAsync } from "../../reducers/portal/portalSlice";
import md5 from "js-md5";
import { getDoctorsAsync } from "../../reducers/portal/portalSlice";
import { addAccountByAdminAsync } from "../../reducers/customers/customersSlice";
import { useTranslation } from "react-i18next";
import { getPlansAsync } from "../../reducers/portal/portalSlice";
import ReportChart from "../Patients/ReportChart";
import ChargeModal from "./ChargeModal";

interface propsObject {
  record: any;
  visible: Boolean;
  doctors?: any;
  plans?: any;
  from?: string;
  db?: string;
  companyId?: string;
  customerId?: string;
  fetchAccounts?: Function;
  closeModal?: Function;
  setRecord?: Function;
  deleteItem?: Function;
  viewsetRecord?: Function;
  companies?: any;
}
const { Option } = Select;

const AccountModalForm = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [doctors, setDoctors] = useState([]);
  const [plans, setPlans] = useState([]);
  const [chartVisible, setChartVisible] = useState(false);
  const [chargeModalVisible, setChargeModalVisible] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const fetchAllDoctors = async () => {
    setLoading(true);
    const result = await dispatch(getDoctorsAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setDoctors(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchAllPlans = async () => {
    setLoading(true);
    const result = await dispatch(getPlansAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setPlans(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        values.id = props.record._id;
        values.resetPassword = resetPassword;
        setLoading(true);
        try {
          if (values.password) {
            values.password = md5(values.password);
          }
        } catch (err) {
          console.log(err);
        }
        let result = null;
        values.expiredDate = props.record.expiredDate;
        if (props.record.planId && props.record.plan) {
          values.planId = props.record.planId;
        }
        if (props.from === "admin") {
          values.customerId = props.customerId;
          values.db = props.db;
          values.companyId = props.companyId;
          result = await dispatch(addAccountByAdminAsync(values));
        } else {
          result = await dispatch(addAccountAsync(values));
        }
        setLoading(false);

        const { payload } = result;
        if (payload) {
          const { code, data, msg } = payload;
          if (code === 0) {
            message.success("Successful operation");
            data.result && props.setRecord && props.setRecord(data.result);
            props.fetchAccounts && props.fetchAccounts();
            if (!values.id) {
              form.resetFields();
            }
            props.closeModal && props.closeModal();
          } else if (msg) {
            if (code == 4000444) {
              Modal.error({
                title: "Error",
                content: `${t("work.CreateMaxPatientText")}`,
              });
            } else {
              Modal.error({
                title: "Error",
                content: msg,
              });
            }
          }
        }
        if (typeof payload === "string") {
          Modal.error({
            title: "Error",
            content: payload,
          });
        }
      })
      .catch((errorInfo) => {});
  };

  useEffect(() => {
    form.resetFields();
    if (props.from === "admin") {
      setDoctors(props.doctors);
      setPlans(props.plans);
      if (props.record.planId) {
        props.record.plan = props.plans.find((p: any) => p._id === props.record.planId);
      }
    } else {
      fetchAllPlans();
      fetchAllDoctors();
      if (props.record.planId) {
        props.record.plan = plans.find((p: any) => p._id === props.record.planId);
      }
    }
    if (props.record._id) {
      form.setFieldsValue(props.record);
    }
  }, [props.record._id, props.record._now_, props.record.planId, props.visible]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <ReportChart record={props.record} visible={chartVisible} setVisible={setChartVisible} companies={props.companies} />
      <ChargeModal visible={chargeModalVisible} setVisible={setChargeModalVisible} record={props.record} plans={plans} db={props.db} />
      <Form {...layout} form={form} name="control-hooks">
        <Row gutter={20}>
          <Col md={24} xs={24}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label={t("work.firstName")}
                  rules={[
                    { required: true, message: t("work.FirstNameRequiredText") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        var reg = new RegExp(/^[a-zA-Z0-9]+$/);
                        if (reg.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t("work.lettersText")));
                      },
                    }),
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label={t("work.lastName")}
                  rules={[
                    { required: true, message: t("work.LastNameRequiredText") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        var reg = new RegExp(/^[a-zA-Z0-9]+$/);
                        if (reg.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t("work.lettersText")));
                      },
                    }),
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="patientId"
                  label={t("work.PatientID")}
                  rules={[
                    {
                      required: true,
                      message: t("work.Please_input_patientID"),
                    },
                  ]}
                >
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="doctorId"
                  label={t("work.DoctorName")}
                  rules={[
                    {
                      required: true,
                      message: t("work.Please_input_doctorID"),
                    },
                  ]}
                >
                  <Select placeholder="" showSearch>
                    {doctors.map((co: any) => {
                      return (
                        <Option key={co._id} data={co} value={co._id}>
                          {co.fullName}{" "}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {
                <Col span={12}>
                  <Form.Item
                    name="username"
                    label={t("work.DeviceLoginUsername")}
                    rules={[
                      {
                        required: true,
                        message: t("work.Please_input_name"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value.length >= 3) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("work.accountName")));
                        },
                      }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var reg = new RegExp(/^[\d\w]+$/);
                          if (reg.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("work.accountNameText")));
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
              }

              {resetPassword ? (
                <Col span={12}>
                  <Form.Item
                    name="password"
                    label={t("work.DeviceLoginPassword")}
                    rules={[
                      {
                        required: true,
                        message: t("work.password_message"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && value.length >= 8) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("work.passwordlengthText")));
                        },
                      }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
                          if (reg.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("work.passwordText")));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label={t("work.Confirmpassword")}
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: t("work.confirm_message"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("work.The_two_passwords_message")));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              ) : (
                <Form.Item name="password" label={t("work.DeviceLoginPassword")}>
                  <Button onClick={() => setResetPassword(true)}>{t("work.Reset_Password")}</Button>
                </Form.Item>
              )}
            </Row>
            {props.record._id && props.from === "admin" ? (
              <div style={{ marginBottom: 10 }}>
                <h3>{props.record.plan ? props.record.plan.name : "No plan selected"}</h3>
              </div>
            ) : null}
          </Col>
        </Row>
        <Space>
          <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
            {t("work.SavePatient")}
          </Button>
          {props.record._id ? (
            <>
              {props.from === "admin" ? (
                <Col span={12}>
                  <Button key="add-change-plan" onClick={() => setChargeModalVisible(!chargeModalVisible)}>
                    {props.record.planId ? "Change Plan" : "Add Plan"}
                  </Button>
                </Col>
              ) : null}
              <Button onClick={() => setChartVisible(!chartVisible)} key="turnoff">
                {t("work.ComplianceRate")}
              </Button>
              <Popconfirm
                title={t("work.Are_you_sure_to_delete_this_account")}
                onConfirm={() => props.deleteItem && props.deleteItem(props.record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>{t("work.Delete")}</Button>
              </Popconfirm>
            </>
          ) : (
            <Button key="back" onClick={() => props.closeModal && props.closeModal()}>
              {t("work.Cancel")}
            </Button>
          )}
        </Space>
      </Form>
    </>
  );
};

export default AccountModalForm;
