// A mock function to mimic making an async request for data
import api from '../../utils/api'
export function addRelease(payload: any) {
  return api('/api/admin/releases', 'POST', payload)
}

export function getReleases(payload: any) {
  return api('/api/admin/releases', 'GET', payload)
}

export function deleteRelease(payload: any) {
  return api('/api/admin/releases', 'DELETE', payload)
}
export function getAllReleases(payload: any) {
  return api('/api/admin/releases/all', 'GET', payload)
}
