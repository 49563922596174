import { Layout, message, Spin, Breadcrumb, Card } from 'antd';
import './WorkSpace.scss'
import { useAppDispatch } from '../../app/hooks'
import CustomerSilder from './CustomerSilder'
import MyDetail from './MyDetail'
import Accounts from './Accounts'
import PayResult from './PayResult'
import MyDevice from './MyDevice'
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useLocation, Link } from "react-router-dom";
import {
  fetchPortalAccountByTokenAsync,
  restore,
  signout,
  restoreUser
} from '../../reducers/account/accountSlice';
import MobileTabs from './MobileTabs';
import { useEffect, useState } from 'react';
import PaymentResult from '../Payments/PaymentResult';

const { Sider, Content } = Layout;

const WorkSpace = () => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    getUser()
  }, [])
  const getUser = async () => {
    const result = await dispatch(fetchPortalAccountByTokenAsync({}))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        if (data.user.type !== 'customer') {
          localStorage.removeItem('token')
          localStorage.removeItem('tokenExpiredAt')
          dispatch(signout)
          message.error('User type error')
          return history.push('/login')
        }
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          db: data.db,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        i18n.changeLanguage(data.user.language || 'en')
        setLoading(false)
      } else if (msg) {
        message.error(msg)
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        return history.push('/login')
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiredAt')
      dispatch(signout)
      return history.push('/login')
    }
  }
  const breadcrumbNameMap: Record<string, string> = {
    '/index': t('work.Customer'),
    '/index/device': t('work.Device'),
    '/index/details': t('work.Details'),
    '/index/accounts': t('work.Patients'),
    '/index/payments': t('work.Payments'),
    '/index/notification': t('work.Notification'),

  };
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <span>{breadcrumbNameMap[url]}</span>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <>
      <Layout className='portal-layout' style={{ minHeight: '100vh' }}>
        {loading ?
          <Layout style={{ padding: '200px 0' }}>
            <Spin size="large" />
          </Layout> : <>
            <div className="eyerising-header portal-header">
              <MobileTabs />
            </div>
            <Layout style={{
              padding: '0 0'
            }}>
              <Sider className="eyerising-sider portal-sider">
                <CustomerSilder />
              </Sider>
              <Content>
                <div style={{ minHeight: '100vh', padding: '0px 0 0 0' }}>
                  <div style={{
                    height: 50,
                    background: '#fff'
                  }}>
                  </div>
                  <Breadcrumb className='portal-breadcurmb' >{breadcrumbItems}</Breadcrumb>
                  <Switch>
                    <Route path="/index/details">
                      <MyDetail getUser={getUser} />
                    </Route>
                    <Route path="/index/accounts">
                      <Accounts />
                    </Route>
                    <Route path="/index/payment-result">
                      <PaymentResult />
                    </Route>
                    <Route path="/index">
                      <MyDevice getUser={getUser} />
                    </Route>
                  </Switch>
                </div>
              </Content>
            </Layout></>}
      </Layout>
    </>
  )
}


export default WorkSpace;