import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getInvoiceReport,
} from './invoiceReportsAPI';
const initialState = {}

export const getInvoiceReportAsync = createApiAsyncThunk<any>('invoiceReports/getInvoiceReport', getInvoiceReport)

export const invoiceReportsSlice = createSlice({
  name: 'invoiceReports',
  initialState,
  reducers: {}
})

export default invoiceReportsSlice.reducer;
