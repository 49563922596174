import { useState, useEffect } from 'react'
import { Form, Alert, Upload, Modal, Button, Space, message, Select, Table, Tag } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import type { ColumnsType } from 'antd/es/table';
import config from '../../config/config'
import './Common.scss'
import {
  selectUser
} from '../../reducers/account/accountSlice';
import moment from 'moment';
import {
  importListAsync,
  updateManyStatusAsync
} from '../../reducers/devices/devicesSlice'
import {
  getAllCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import { useTranslation } from 'react-i18next';
interface propsObject {
  visible: boolean;
  setVisible: Function;
  getList: Function;
  action: String;
}
const ImportDevicesModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser)
  const [fileList, setFileList] = useState([] as any)
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const [companies, setCompanies] = useState([])
  const [companyName, setCompanyName] = useState('')
  const [companiesMap, setCompaniesMap] = useState([])
  const [tableData, setTableData] = useState([])
  const [form] = Form.useForm()
  const closeModal = () => {
    props.getList()
    props.setVisible(false)
    form.resetFields()
    setFileList([])
  }
  const onFinish = async() => {
    form.validateFields().then(async(values: any) => {
      const data = {
        'toCompanyId': values.toCompanyId,
        ids: tableData,
        action: props.action,
        logging: []
      } as any
      if (props.action === 'transfer') {
        data.logging = ['Company', 'Tocompany']
      }
      if (props.action === 'receive') {
        data.logging = ['Company', 'Tocompany']
      }
      if (props.action === 'recall') {
        data.logging = ['Company']
      }
      setLoading(true)
      const result = await dispatch(updateManyStatusAsync(data))
      const { payload } = result
      if (payload) {
        const { code, data, msg } = payload
        if (code === 0) {
          const list = data.list
          setTableData(list)
        } else if (msg) {
          message.error(msg)
        }
      }
      if (typeof payload === 'string') {
        message.error(payload)
      }
      message.success('Successful operation');
      // props.getList()
      // closeModal()
      setLoading(false)

    })
  }

  useEffect(() => {
    fetchList()
    if (!props.visible) {
      setTableData([])
    }
  }, [props.visible])
  const fetchList = async() => {
    setLoading(true)
    const result = await dispatch(getAllCompaniesAsync({
      other: true
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCompanies(data.list)
        let map = {} as any
        data.list.map((d: any) => {
          map[d._id] = d
        })
        setCompaniesMap(map)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const onChangeName = (e: any, obj: any) => {
    console.log(e, obj)
    form.setFieldsValue({ toCompanyId: e })
    setCompanyName(obj.children)
  };
  const layout = {
    labelCol: { span: 5},
  }
  const uploadProps = {
    onRemove: (del: any) => {
      setFileList(fileList.filter((file: any) => file !== del))
    },
    beforeUpload: async (file: any) => {
      let newFileList: Array<any> = [...fileList, file]
      setFileList(newFileList)

      const data = {
        file: newFileList[0]
      }
      setLoading(true)
      const result = await dispatch(importListAsync(data))
      setLoading(false)
      const { payload } = result
      if (payload) {
        const { code, data, msg } = payload
        if (code === 0) {
          data.list.map((da: any) => {
            if (da.batchStatus === 'waiting') {
              if (props.action === 'transfer' || props.action === 'recall') {
                if (da.status !== 'In stock') {
                  da.batchStatus = 'failed'
                  da.message = 'Not in stock'
                }
              }
              if (props.action === 'receive') {
                if (da.status !== 'Transferring') {
                  da.batchStatus = 'failed'
                  da.message = 'Not in Transferring'
                }
              }

            }
          })
          const list = data.list
          setTableData(list)
        } else if (msg) {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
        if (typeof payload === 'string') {
          message.error(payload)
        }
      }




      return false;
    },
    fileList,
  };

  const columns = [
    {
      title: t('work.Status'),
      dataIndex: 'batchStatus',
      render: (batchStatus: string, row: any) => {
        return (
        <>
         <span>
        { batchStatus ==='waiting' ? <Tag color="yellow">Waiting</Tag> : ''
        }
         {
            batchStatus === 'failed' ? <Tag color="red">Failed</Tag> : ''
          }
          {
            batchStatus === 'succeeded' ? (<Tag color="green">Successed</Tag>): ''
          }
         </span>
        </>
      )},
    },
    {
      title: t('work.Create_Date'),
      dataIndex: 'createdDate',
      render: (createdDate: string) => (
        <>
         <span>{moment(createdDate).format('DD/MM/YYYY')}</span>
        </>
      ),
    },
    {
      title: t('work.Serial_Number'),
      dataIndex: 'serial_number',
    },
    {
      title: t('work.Company'),
      dataIndex: 'companyName',
    },
    {
      title: t('work.Tocompany'),
      dataIndex: 'toCompanyName',
      render: (toCompanyName: string) => (
        <>
         <span>{companyName || toCompanyName}</span>
        </>
      ),
    },
    {
      title: t('work.Message'),
      dataIndex: 'message',
    },
  ];

  return (
    <>
      <Form form={form} {...layout} >
        <Modal
          width={900}
          visible={props.visible}
          title={`Inventory: batch ${props.action}`}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit"  onClick={onFinish} disabled={fileList.length === 0} loading={loading} type="primary">
              {loading ? 'Uploading' : 'Start Upload'}
            </Button>,
            <Button key="back" onClick={closeModal}>
              Cancel
            </Button>
          ]}
        >
        {
          (props.action === 'transfer' || user.type === 'admin')  && props.action !== 'recall' ?
          <Form.Item name="toCompanyId" label="To Company" rules={[{ required: true, message: t('work.Please_select_company') }]}>
            <Select showSearch onChange={onChangeName}
              optionFilterProp="label">
              {
                companies
                .filter((item: any) => item._id !== user.companyId)
                .map((item: any) => <Select.Option key={item._id} label={item.name} value={item._id}>{item.name}</Select.Option>)
              }
            </Select>
          </Form.Item> : ''
        }
        {/* {
          props.action === 'receive' ?
          <Form.Item name="companyId" label="Company" rules={[{ required: true }]}>
            <Select onChange={onChangeName}>
              {
                companies
                .map((item: any) => <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>)
              }
            </Select>
          </Form.Item> : ''
        } */}
          <>
            <Upload {...uploadProps}>
              <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>{t('work.Select_File')}</Button>
            </Upload>
          </>
          <Alert
            style={{margin: '10px 0 0'}}
            showIcon
            message="Note:"
            description={t('work.import_note')}
            type="info"
            action={
              <Space direction="vertical">

                <a href={`${config.domain}/static/inventory_template.csv`} target="view_window">
                  template.csv
                </a>
              </Space>
            }
          />
          <br />

        <Table
            rowClassName={(record) => record.batchStatus}  columns={columns} dataSource={tableData} size="middle"
            pagination={false} />
        </Modal>
      </Form>
    </>
  );
}

export default ImportDevicesModal
