import { useState, useEffect } from "react";
import { Form, Input, Modal, Button, message, Row, Select, Col, Upload, InputNumber, Switch } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { addPlanAsync } from "../../reducers/plans/plansSlice";

import { selectUser } from "../../reducers/account/accountSlice";
import { getAllCatalogsAsync } from "../../reducers/catalogs/catalogsSlice";
import { getAllCompaniesAsync } from "../../reducers/companies/companiesSlice";

import { postAWSSignurlAsync } from "../../reducers/aws/awsSlice";
import s3utils from "../../utils/s3.js";
import { getBaseSettingAsync } from "../../reducers/setting/settingSlice";
import config from "../../config/config";
import { useTranslation } from "react-i18next";
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}

const { Option } = Select;

const PlanModal = (props: propsObject) => {
  const options = config.currencies;
  const user = useAppSelector(selectUser);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [fileName, setFileName] = useState("");
  const [fileList, setFileList] = useState([] as any);
  const [fileName2, setFileName2] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [type, setType] = useState("");
  const [companies, setCompanies] = useState([]);
  const [terms, setTerms] = useState("");
  const [terms2, setTerms2] = useState("");
  const [dbs, setDbs] = useState([]);
  const [products, setProducts] = useState([]);
  const [currencies, setcurrencies] = useState([] as string[]);
  const [billingType, setBillingType] = useState(["RETAIL", "PREPAID", "WHOLESALE", "FREE"]);
  const [paymentGateway, setPaymentGateway] = useState(false);
  const [legacy, setLegacy] = useState(false);

  const closeModal = () => {
    props.setVisible(false);
    props.getList();
  };

  const uploadButton = (
    <div>
      {fileName ? <Input style={{ width: 300 }} value={fileName} placeholder="" disabled /> : ""}
      <Button disabled={loading} icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>
        Upload
      </Button>
    </div>
  );

  const uploadButton2 = (
    <div>
      {fileName2 ? <Input style={{ width: 300 }} value={fileName2} placeholder="" disabled /> : ""}
      <Button disabled={loading} icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>
        Upload
      </Button>
    </div>
  );

  const uploadProps = {
    onRemove: (del: any) => {
      setFileList(fileList.filter((file: any) => file !== del));
    },
    customRequest: async (option: any) => {
      option.method = "PUT";
      option.filename = option.file.fileName;
      option.data = option.file.fields;
      option.onSuccess = async () => {
        setLoading(false);
      };
      await s3utils.postToS3(option);
    },
    beforeUpload: async (file: any, list: any) => {
      const params = {
        fileName: file.uid,
        fileType: file.type,
      };
      setLoading(true);
      setFileName(file.name);
      const result = await dispatch(postAWSSignurlAsync(params));
      const { payload } = result;
      if (payload) {
        const { code, data, msg } = payload;
        if (code === 0) {
          file.fileName = data.fileName;
          file.signUrl = data.signUrl;
          file.publicUrl = data.publicUrl;
          file.fields = data.fields;
          setTerms(file.publicUrl);
        } else if (msg) {
          Modal.error({
            title: "Error",
            content: msg,
          });
        }
      }
      if (typeof payload === "string") {
        Modal.error({
          title: "Error",
          content: payload,
        });
      }
      return true;
    },
    onChange: (option: any) => {
      setFileList(option.fileList);
    },
    fileList,
  };

  const uploadProps2 = {
    onRemove: (del: any) => {
      setFileList(fileList.filter((file: any) => file !== del));
    },
    customRequest: async (option: any) => {
      option.method = "PUT";
      option.filename = option.file.fileName;
      option.data = option.file.fields;
      option.onSuccess = async () => {
        setLoading(false);
      };
      await s3utils.postToS3(option);
    },
    beforeUpload: async (file: any, list: any) => {
      const params = {
        fileName: file.uid,
        fileType: file.type,
      };
      setLoading(true);
      setFileName2(file.name);
      const result = await dispatch(postAWSSignurlAsync(params));
      const { payload } = result;
      if (payload) {
        const { code, data, msg } = payload;
        if (code === 0) {
          file.fileName = data.fileName;
          file.signUrl = data.signUrl;
          file.publicUrl = data.publicUrl;
          file.fields = data.fields;
          setTerms2(file.publicUrl);
        } else if (msg) {
          Modal.error({
            title: "Error",
            content: msg,
          });
        }
      }
      if (typeof payload === "string") {
        Modal.error({
          title: "Error",
          content: payload,
        });
      }
      return true;
    },
    onChange: (option: any) => {
      setFileList(option.fileList);
    },
    fileList,
  };

  const fetchSetting = async () => {
    setLoading(true);
    const result = await dispatch(getBaseSettingAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setDbs(data.dburls);
        setcurrencies(data.currencies);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchList = async () => {
    setLoading(true);
    const result = await dispatch(getAllCompaniesAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setCompanies(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const fetchAllProducts = async () => {
    setLoading(true);
    const result = await dispatch(getAllCatalogsAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setProducts(data.list);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        values.id = props.record._id;
        if (values.valid_months < 0 || values.valid_days < 0) {
          return;
        }
        if (values.renew_end_months && values.renew_end_months < values.valid_months) {
          return message.error(t("work.FrequencyAndRenewal"));
        }
        if (values.experience_months > values.valid_months) {
          return message.error(t("work.mbgText"));
        } else if (values.experience_months === values.valid_months && values.experience_days >= values.valid_days) {
          return message.error(t("work.mbgText"));
        }
        values.termsUrl = terms;
        values.guaranteeUrl = terms2;
        const result = await dispatch(addPlanAsync(values));
        setLoading(false);
        const { payload } = result;
        if (payload) {
          const { code, msg } = payload;
          if (code === 0) {
            message.success("Successful operation");
            closeModal();
          } else if (msg) {
            Modal.error({
              title: "Error",
              content: msg,
            });
          }
        }
        if (typeof payload === "string") {
          Modal.error({
            title: "Error",
            content: payload,
          });
        }
        /*
         */
      })
      .catch((errorInfo) => {});
  };

  useEffect(() => {
    fetchSetting();
    form.resetFields();
    setTerms("");
    setFileList([]);
    setFileName("");
    setFileName2("");
    form.setFieldsValue({
      valid_months: 0,
      valid_days: 0,
      experience_months: 0,
      experience_days: 0,
      status: "enabled",
    });
    if (props.record._id) {
      form.setFieldsValue(props.record);
      console.log(props.record.termsUrl);
      setTerms(props.record.termsUrl);
      setTerms2(props.record.guaranteeUrl);
      setType(props.record.type);
      setPaymentGateway(props.record.paymentGateway);
      setLegacy(props.record.legacy);
    }
    fetchAllProducts();
    fetchList();
  }, [props.record._id, props.visible]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const handleChangeCompany = (value: string) => {
    companies.map((co: any) => {
      if (co._id == value) {
      }
    });
  };

  const isDisabled = user.type !== "admin";
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width="900px"
          title={props.record._id ? t("work.Edit_product_plan") : t("work.CreatePlan")}
          onCancel={closeModal}
          footer={
            user.type === "admin"
              ? [
                  <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
                    {t("work.Save")}
                  </Button>,
                  <Button key="back" onClick={closeModal}>
                    {t("work.Cancel")}
                  </Button>,
                ]
              : [
                  <Button key="back" onClick={closeModal}>
                    {t("work.Cancel")}
                  </Button>,
                ]
          }
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("work.Plan_Name")}
                rules={[
                  {
                    required: true,
                    message: t("work.EnterName"),
                  },
                ]}
              >
                <Input disabled={isDisabled} placeholder="" />
              </Form.Item>
              <Form.Item name="details" label={t("work.PlanDetails")}>
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 10 }} disabled={isDisabled} placeholder="" />
              </Form.Item>
              {user.type === "admin" ? (
                <>
                  <Form.Item name="type" label={t("work.BindType")} rules={[{ required: true, message: t("work.Please_select_type") }]}>
                    <Select placeholder="" disabled={props.record._id && isDisabled} onChange={(v) => setType(v)}>
                      <Option value="database">Database</Option>
                      <Option value="company">Company</Option>
                    </Select>
                  </Form.Item>
                  {type === "database" ? (
                    <Form.Item name="database" label={t("work.databaseUrl")} rules={[{ required: true, message: t("work.SelectDataBase") }]}>
                      <Select placeholder="" disabled={isDisabled}>
                        {dbs.map((co: any) => {
                          return (
                            <Option key={co.key} value={co.key}>
                              {co.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item name="companyId" label={t("work.Company")} rules={[{ required: true, message: t("work.Please_select_company") }]}>
                      <Select placeholder="" disabled={isDisabled} optionFilterProp="label" onChange={handleChangeCompany} showSearch>
                        {companies.map((co: any) => {
                          return (
                            <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>
                              {co.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item name="productId" label={t("work.Product")} rules={[{ required: true, message: t("work.Please_select_product") }]}>
                    <Select placeholder="" showSearch disabled={isDisabled} optionFilterProp="label">
                      {products.map((co: any) => {
                        return (
                          <Option key={co._id} label={co.reference} value={co._id}>
                            {co.reference}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="renew_end_months"
                    label={t("work.AutoRenewEndMonths")}
                    rules={[
                      {
                        pattern: new RegExp(/^[0-9]\d*$/, "g"),
                        message: "Must >= 0",
                      },
                    ]}
                    wrapperCol={{ span: 20 }}
                  >
                    <InputNumber disabled={isDisabled} addonAfter={<span>{t("time.months")}</span>} style={{ width: "100%" }} />
                  </Form.Item>
                  <Row>{t("work.Plan_duration")}</Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="valid_months"
                        label=""
                        labelCol={{ span: 0 }}
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(/^[0-9]\d*$/, "g"),
                            message: "Must >= 0",
                          },
                        ]}
                        wrapperCol={{ span: 20 }}
                      >
                        <InputNumber disabled={isDisabled} addonAfter={<span>{t("time.months")}</span>} style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="valid_days"
                        label=""
                        labelCol={{ span: 0 }}
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(/^[0-9]\d*$/, "g"),
                            message: "Must >= 0",
                          },
                        ]}
                        wrapperCol={{ span: 24 }}
                      >
                        <InputNumber disabled={isDisabled} addonAfter={<span>{t("work.days")}</span>} style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name="status" label={t("work.Status")}>
                    <Select disabled={isDisabled} placeholder="">
                      <Option key="enabled" value="enabled">
                        {t("work.Enabled")}
                      </Option>
                      <Option key="disabled" value="disabled">
                        {t("work.Disabled")}
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="file" label={t("work.TermsAndConditions")}>
                    <Upload accept=".pdf" showUploadList={false} {...uploadProps}>
                      {terms ? <a href={terms}>{terms}</a> : ""}
                      {uploadButton}
                    </Upload>
                  </Form.Item>
                  <Form.Item name="file" label={t("work.MoneyBackGuarantee")}>
                    <Upload accept=".pdf" showUploadList={false} {...uploadProps2}>
                      {terms2 ? <a href={terms2}>{terms2}</a> : ""}
                      {uploadButton2}
                    </Upload>
                  </Form.Item>
                  <Form.Item name="billing_type" label={t("work.BillingType")} rules={[{ required: true, message: t("work.SelectBillingType") }]}>
                    <Select disabled={isDisabled} placeholder="">
                      {billingType.map((opt: any) => (
                        <Option key={opt} value={opt}>
                          {opt}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="currency" label={t("work.Currency")} rules={[{ required: true, message: t("work.SelectCurrency") }]}>
                    <Select disabled={isDisabled} placeholder="">
                      {options
                        .filter((opt: any) => {
                          return currencies.indexOf(opt.value) > -1;
                        })
                        .map((opt: any) => (
                          <Option key={opt.value} value={opt.valu}>
                            {opt.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="price"
                    label={t("work.PlanPrice")}
                    rules={[
                      { required: true, message: t("work.EnterPrice") },
                      {
                        pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                        message: "Must >= 0",
                      },
                    ]}
                  >
                    <InputNumber disabled={isDisabled} placeholder="" />
                  </Form.Item>
                  <Form.Item name="paymentGateway" label={t("planModal.PaymentGateway")}>
                    <Switch
                      disabled={user.type !== "admin" && user.paymentGateway === false}
                      defaultChecked={paymentGateway}
                      onChange={(value) => setPaymentGateway(value)}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                  <Form.Item name="legacy" label={t("planModal.LegacyPlan")}>
                    <Switch disabled={user.type !== "admin"} defaultChecked={legacy} />
                  </Form.Item>

                  {paymentGateway ? (
                    <>
                      <Form.Item
                        name="deposit"
                        label={t("work.Deposit")}
                        rules={[
                          {
                            pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                            message: "Must >= 0",
                          },
                        ]}
                      >
                        <InputNumber disabled={isDisabled} placeholder="" />
                      </Form.Item>
                      <Form.Item
                        name="purchase"
                        label={t("work.Purchase")}
                        rules={[
                          {
                            pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                            message: "Must >= 0",
                          },
                        ]}
                      >
                        <InputNumber disabled={isDisabled} placeholder="" />
                      </Form.Item>
                      <Row>{t("work.Experience_duration")}</Row>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="experience_months"
                            label=""
                            labelCol={{ span: 0 }}
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                message: "Must >= 0",
                              },
                            ]}
                            wrapperCol={{ span: 20 }}
                          >
                            <InputNumber disabled={isDisabled} addonAfter={<span>{t("work.months")}</span>} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="experience_days"
                            label=""
                            labelCol={{ span: 0 }}
                            rules={[
                              {
                                required: true,
                                pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                message: "Must >= 0",
                              },
                            ]}
                            wrapperCol={{ span: 24 }}
                          >
                            <InputNumber disabled={isDisabled} addonAfter={<span>{t("work.days")}</span>} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </>
              ) : null}
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default PlanModal;
