import { Layout, Space, Button, Select, Table, Input, message, Row, Col, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import config from '../../config/config'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getReleasesAsync,
  deleteReleaseAsync,
} from '../../reducers/versions/versionsSlice'
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import ExportButton from '../Components/exportButton'
import PlanModal from './VersionModal'
import {
  selectUser

} from '../../reducers/account/accountSlice';
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const Plans = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { t, i18n } = useTranslation()
  let [modelVisible, setModelVisible] = useState(false)
  const [searchStr, setsearchStr] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [record, setRecord] = useState({})
  const setCompany = (record: any) => {
    setRecord(record)
    setModelVisible(true)
  }
  useEffect(() => {
    fetchList(pagination)
  }, [])

  const onSearch = async (value: string) => {
    setsearchStr(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList(pagination, value)
  }

  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    params.type = params.type || type
    !params.order && delete params.order
    !params.sort && delete params.sort
    const result = await dispatch(getReleasesAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const deleteItem = async (id: string,) => {
    setLoading(true)
    const result = await dispatch(deleteReleaseAsync({
      id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({ ...pagination })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }
  const columns = [
    {
      title: t('work.Name'),
      dataIndex: 'name',
      width: '200px',
    },
    {
      title: t('work.Description'),
      dataIndex: 'description',
      width: '300px',
    },
    {
      title: t('work.Date'),
      dataIndex: 'createdAt',
      width: '200px',
      render: (createdAt: string) => (
        <>
          <span>{moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
        </>
      ),
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type='primary' size='small' onClick={() => setCompany(record)} key="edit">
            {t('work.Edit')}
          </Button>
          <Button type='primary' size='small' onClick={() => window.open(record.url)} key="release">
            {t('work.Release')}
          </Button>
          <Popconfirm
            title={t('work.Are_you_sure_to_delete_this_plan')}
            onConfirm={() => deleteItem(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger size='small' key="3">
              {t('work.Delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [type, setType] = useState('')
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setType(value)
    fetchList({
      ...pagination,
      type: value
    })
  };

  return (
    <>
      <div className='eyerising-page-header'>
        <div className='header-content' >
          <Row justify="space-between">
            <Col span={4}>
              <Space>
                <Search placeholder={t('work.SearchName')} onSearch={onSearch} style={{ width: 200 }} />
                <Button className='table-btn' key="2" onClick={() =>
                  fetchList({
                    ...pagination
                  })}>
                  {t('work.Refresh')}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space style={{ float: 'right', marginRight: 10 }}>

                <Button icon={<PlusCircleOutlined />} onClick={() => setCompany({})} type='primary'>
                  {t('work.Create')}
                </Button>
              </Space>

            </Col>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <PlanModal
        record={record}
        setRecord={setCompany}
        visible={modelVisible}
        setVisible={setModelVisible}
        getList={() => fetchList(pagination)}
      />
    </>
  )
}


export default Plans;