import { Space, Button, Row, Card, Col, Select, Divider, Modal } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Column } from "@ant-design/plots";
import logo2 from "../../assets/logo2.png";
import { calculateComplianceRate } from "../../utils/complianceRate";
import { IntervalGeometryLabelPosition } from "@antv/g2/lib/interface";

const Option = Select.Option;
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord?: Function;
  companies?: any;
}
const Report = (props: propsObject) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [usageData, setUsageData] = useState([] as any);
  const [total, setTotal] = useState(0);
  const [exportMonths, setExportMonths] = useState("4");

  useEffect(() => {
    setTotal(0);
  }, []);

  const closeModal = () => {
    props.setVisible(false);
  };

  useEffect(() => {
    if (props.record._id) {
      setTotal(0);
      getData();
    }
  }, [props.record._id, props.visible]);

  const getData = (mon?: string) => {
    const dateMap = {} as any;
    let months = [];
    let MaxMonths = Number(mon || exportMonths);
    for (let i = 0; i < MaxMonths; i++) {
      months.push(i);
    }
    months = months.reverse();
    const dates: string[] = months.map((num: number) => {
      return moment().tz(props.record.timezone).subtract(num, "month").format("YYYY-MM");
    });
    const records = props.record.records;
    dates.map((date: string) => {
      if (!dateMap[date]) {
        dateMap[date] = {
          count: 0,
        };
      }
      records.map((record: any) => {
        if (moment(record.createdAt).tz(props.record.timezone).format("YYYY-MM") === date) {
          dateMap[date].count++;
        }
      });
    });

    // Date of first treatment session
    let startRecord: any = null;
    if (records.length) {
      startRecord = moment(records[0].createdAt);
    } else {
      return;
    }

    const finalDate = [] as any;

    // Calculate total compliance rate
    // Number of days between now in the patient timezone and the day of the first treatment
    const totalDays = moment().tz(props.record.timezone).startOf("day").diff(startRecord.tz(props.record.timezone).startOf("day"), "days") + 1;
    const totaltemp = calculateComplianceRate(totalDays, records.length);
    setTotal(totaltemp);

    dates.map((dt: string) => {
      let diffDays = 0;
      // If first treatment was in the processed month
      if (startRecord && startRecord.format("YYYY-MM") === dt) {
        // If processed month is current month
        if (moment().tz(props.record.timezone).format("YYYY-MM") === dt) {
          // Number of days between now in the patient timezone and the day of the first treatment
          diffDays = moment().tz(props.record.timezone).startOf("day").diff(startRecord.tz(props.record.timezone).startOf("day"), "days") + 1;
        }
        // If processed month is in the past
        else {
          // Last day of the processed month
          const lastDate = moment(`${dt}-15`).tz(props.record.timezone).endOf("month");
          // Number of days between the last day of the current month and the day of the first treatment
          diffDays = lastDate.startOf("day").diff(startRecord.tz(props.record.timezone).startOf("day"), "days") + 1;
        }
      }
      // If first treatment is NOT in the processed month
      else {
        // If processed month is current month
        if (moment().tz(props.record.timezone).format("YYYY-MM") === dt) {
          // Number of days between the start of the month and now im the patients timezone
          diffDays = moment().tz(props.record.timezone).date();
        }
        // If processed month is in the past
        else {
          // Last day of the processed month
          diffDays = moment(`${dt}-15`).tz(props.record.timezone).endOf("month").date();
        }
      }

      finalDate.push({
        Date: moment(dt).format("MMM"),
        "Compliance Rate": calculateComplianceRate(diffDays, dateMap[dt].count),
      });
    });

    setUsageData(finalDate);
  };

  const dataConfig = {
    data: usageData,
    isGroup: true,
    xField: "Date",
    yField: "Compliance Rate",
    tooltip: {
      formatter: (datum: any) => {
        return { name: t("work.ComplianceRate"), value: datum["Compliance Rate"] + "%" };
      },
    },
    yAxis: {
      title: {
        text: `${t("work.ComplianceRate")}(%)`,
      },
      gridIndex: 0,
      min: 0,
      max: 100,
      interval: 1,
    },
    color: (data: any, record: any) => {
      let color = "red";
      for (let i = 0; i < usageData.length; i++) {
        const uDate = usageData[i];
        if (uDate.Date === data.Date && uDate["Compliance Rate"] >= 75) {
          color = "#0ED1AD";
        }
      }
      return color;
    },
    /** 设置颜色 */

    /** 设置间距 */
    // marginRatio: 0.1,
    label: {
      // 可手动配置 label 数据标签位置
      // 'top', 'middle', 'bottom'
      // 可配置附加的布局方法
      position: "middle" as IntervalGeometryLabelPosition,
      content: function content(_ref: any) {
        return "".concat(_ref["Compliance Rate"], "%");
      },
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: "interval-adjust-position",
        }, // 数据标签防遮挡
        // {
        //   type: "interval-hide-overlap",
        // }, // 数据标签文颜色自动调整
        {
          type: "adjust-color",
        },
      ],
    },
  };

  const savePdf = async () => {
    const width = 210.28;
    const height = 841.89;
    window.scrollTo(0, 0);
    setAccountName(props.record.username);
    setLoading(true);
    setTimeout(() => {
      const target = document.getElementById("target") as any;
      html2canvas(target, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        scale: 2, // 处理模糊问题
        width: target.offsetWidth,
        height: target.offsetHeight,
      }).then((canvas) => {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        var imgWidth = width;
        var imgHeight = (width / contentWidth) * contentHeight;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF();
        pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        pdf.save("export.pdf");
        setLoading(false);
      });
    }, 2000);
  };

  return (
    <>
      <Modal
        className="add-user-modal"
        visible={props.visible}
        width="900px"
        title={`${t("work.ComplianceRate")}: ${props.record.firstName} ${props.record.lastName} (ID: ${props.record.patientId})`}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {t("work.Cancel")}
          </Button>,
        ]}
      >
        <Row>
          <Select
            value={exportMonths}
            style={{ width: 120, marginBottom: 20 }}
            onChange={(value: string) => {
              setExportMonths(value);
              getData(value);
            }}
            className="select-before"
          >
            {["4", "6", "12"].map((code: any) => (
              <Option key={code} value={code}>
                {code} {t("time.monthsPlural")}{" "}
              </Option>
            ))}
          </Select>
          <div style={{ float: "left" }}>
            <Button style={{ float: "right" }} type="primary" onClick={() => savePdf()} loading={loading}>
              <Space>Export</Space>
            </Button>
          </div>
        </Row>
        <div>
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: 10,
                marginLeft: 30,
                background: "#0ED1AD",
                width: 16,
                height: 16,
              }}
            ></div>
            <span style={{ color: "" }}>{t("work.more75")}</span>
          </div>
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: 10,
                marginLeft: 30,
                background: "red",
                width: 16,
                height: 16,
              }}
            ></div>
            <span style={{ color: "" }}>{t("work.redText")}</span>
          </div>
        </div>
        <Divider className="chart-line"></Divider>
        <Column
          style={{
            height: 250,
            marginTop: 50,
          }}
          {...dataConfig}
        />
        <h3 style={{ margin: "20px 0 0" }}>
          {t("work.totalRate")} {total}%
        </h3>
        <div id="target-content">
          <div id="target">
            <div style={{ height: 60 }}>
              <img style={{ float: "right", height: 40 }} src={logo2} alt="" />
            </div>
            <h1 style={{ marginBottom: 0 }}>{t("work.RLRLComplianceReport")}</h1>
            <Row
              style={{
                margin: "0 0",
              }}
            >
              <Col span={24}>
                <div className="report-profile">
                  <h4>
                    {t("work.PatientName")}: {props.record.firstName} {props.record.lastName}
                  </h4>
                  <h4>
                    {t("work.PatientID")}: {props.record.patientId}
                  </h4>
                  <h4>
                    {t("work.Clinic")}:{" "}
                    {props.companies && props.companies.length > 0 && Object.keys(props.record).length !== 0
                      ? props.companies.filter((company: any) => company._id === props.record.companyId)[0].name
                      : ""}
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Card>
                  <h2>{t("work.PatientComplianceRate")}</h2>
                  <div>
                    <div style={{ display: "inline-block" }}>
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginRight: 10,
                          marginLeft: 30,
                          background: "#0ED1AD",
                          width: 16,
                          height: 16,
                        }}
                      ></div>
                      <span style={{ color: "" }}>{t("work.more75")}</span>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginRight: 10,
                          marginLeft: 30,
                          background: "red",
                          width: 16,
                          height: 16,
                        }}
                      ></div>
                      <span style={{ color: "" }}>{t("work.redText")}</span>
                    </div>
                  </div>
                  <Divider className="chart-line" style={{ top: 75 }}></Divider>
                  <Column
                    style={{
                      height: 200,
                    }}
                    {...dataConfig}
                  />
                </Card>
              </Col>
            </Row>
            <br />
            <Row>
              <h3>
                {t("work.totalRate")} {total}% ------
              </h3>
            </Row>
            <Row style={{ marginTop: 0 }}>
              <h3>{t("work.WhatisComplianceRate")}</h3>
              <p style={{ marginBottom: 3 }}>{t("work.WhatisComplianceRateText1")}</p>
              <p style={{ marginBottom: 3 }}>{t("work.WhatisComplianceRateText2")}</p>
              <p style={{ marginBottom: 3 }}>{t("work.WhatisComplianceRateText3")}</p>
              <p>{t("work.WhatisComplianceRateText4")}</p>
            </Row>
            <Row>
              <h3>{t("work.Whycompliancerateissoimportant")}</h3>
              <p>{t("work.WhycompliancerateissoimportantText")}</p>
            </Row>
            <footer>
              <p style={{ marginBottom: 0 }}>Eyerising International Pty Ltd: Suite 2.05, 9-11 Claremont St. South Yarra, Victoria 3141, Australia</p>
              <p>ABN: 98 641 925 206 | www.eyerisinginternational.com | mailto: info@eyerisinginternational.com</p>
            </footer>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Report;
