import { Layout, Space, Button, Popconfirm, Table, Input, message, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import config from '../../config/config'
import { useAppDispatch } from '../../app/hooks'
import { useTranslation } from 'react-i18next';
import {
  getCatalogsAsync,
  deleteCatalogAsync,
  exportCatalogsASync
} from '../../reducers/catalogs/catalogsSlice'

import { PlusCircleOutlined } from '@ant-design/icons';
import ExportButton from '../Components/exportButton'
import CatalogModal from './CatalogModal'
import './Common.scss'
const { Content } = Layout;
const { Search } = Input;
const Catalogs = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  let [modelVisible, setModelVisible] = useState(false)
  const [searchStr, setsearchStr] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [record, setRecord] = useState({})
  const { t, i18n } = useTranslation()
  const setCompany = (record: any) => {
    setRecord(record)
    setModelVisible(true)
  }
  useEffect(() => {
    fetchList(pagination)
  }, [])

  const onSearch = async (value: string) => {
    setsearchStr(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList(pagination, value)
  }

  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    !params.order && delete params.order
    !params.sort && delete params.sort
    const result = await dispatch(getCatalogsAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const deleteItem = async (id: string,) => {
    setLoading(true)
    const result = await dispatch(deleteCatalogAsync({
      id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({ ...pagination })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }
  const columns = [
    {
      title: t('work.Name'),
      dataIndex: 'name',
      sorter: true,
      width: '400px',
    },
    {
      title: t('work.Internal_Reference'),
      dataIndex: 'reference',
      sorter: true,
      width: '400px',
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type='primary' size='small' onClick={() => setCompany(record)} key="1">

            {t('work.Edit')}
          </Button>
          <Popconfirm
            title="Are you sure to delete this catalog?"
            onConfirm={() => deleteItem(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger size='small' key="3">

              {t('work.Delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className='eyerising-page-header'>
        <div className='header-content' >
          <Row justify="space-between">
            <Col span={4}>
              <Space>
                <Search placeholder={t('work.SearchName')} onSearch={onSearch} style={{ width: 200 }} />
                <Button className='table-btn' key="2" onClick={() =>
                  fetchList({
                    ...pagination
                  })}>{t('work.Refresh')}</Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space style={{ float: 'right', marginRight: 10 }}>

                <Button icon={<PlusCircleOutlined />} onClick={() => setCompany({})} type='primary'>
                  {t('work.Create')}
                </Button>
                <ExportButton exportApi={exportCatalogsASync} exportQuery={{
                  searchStr: searchStr
                }} />
              </Space>

            </Col>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <CatalogModal
        record={record}
        setRecord={setCompany}
        visible={modelVisible}
        setVisible={setModelVisible}
        getList={() => fetchList(pagination)}
      />
    </>
  )
}


export default Catalogs;