import {
  Switch,
  Route,
} from "react-router-dom";
import SignIn from './SignIn/SignIn'
import CustomerProtalLogin from './Admin/Portal/CustomerProtalLogin/SignIn'
import SignUp from './Admin/Portal/SignUp/SignUp'
import Result from './Admin/Portal/SignUp/Result'
import Receipt from './Admin/Receipt'
import Email from './ForgotPassword/Email'
import ResetPassword from './ForgotPassword/ResetPassword'
import PortalEmail from './Admin/Portal/Email'
import PortalResetPassword from './Admin/Portal/ResetPassword'
import AdminWorkSpace from "./Admin/Workspace/WorkSpace";
import UserWorkSpace from "./Admin/Workspace/UserWorkSpace";
import PortalWorkspace from "./Admin/Portal/PortalWorkspace";
import SigInRouter from "./Admin/SigInRouter";
import Health from "./Admin/Health/Health";
import PaymentResult from "./Admin/Payments/PaymentResult";

export default function Main() {
  return (
    <Switch>
      <Route path="/health">
        <Health />
      </Route>
      <Route path="/admin">
        <AdminWorkSpace />
      </Route>
      <Route path="/receipt">
        <Receipt />
      </Route>
      <Route path="/work">
        <UserWorkSpace />
      </Route>
      <Route path="/login/forgotpassword">
        <PortalEmail />
      </Route>
      <Route path="/login/resetpassword">
        <PortalResetPassword />
      </Route>
      <Route path="/login/backend">
        <SignIn />
      </Route>
      <Route path="/login">
        <CustomerProtalLogin />
      </Route>
      <Route path="/forgotpassword">
        <Email />
      </Route>
      <Route path="/resetpassword">
        <ResetPassword />
      </Route>
      <Route path="/signup/result">
        <Result />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/index">
        <PortalWorkspace />
      </Route>
      <Route path="/payment-result">
        <PaymentResult />
      </Route>
      <Route path="/">
        <SigInRouter />
      </Route>
    </Switch>
  );
}
