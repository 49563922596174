import { Form, Input, Button, Row, Select, Col, message, Modal, Card, Tooltip, Image } from 'antd'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useEffect, useState, useCallback } from 'react';
import { TimeZoneSelectDialog } from 'react-timezone-map-select';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import md5 from 'js-md5'
import {
  feachAccountByTokenAsync,
  restore,
  signout,
  restoreUser,
  selectUser
} from '../../reducers/account/accountSlice';
import {
  updateProfileAsync
} from '../../reducers/users/usersSlice'
import mobileCountryCodes from '../../config/mobileCountryCodes'

const { Option } = Select;
const Preference = () => {
  const dispatch = useAppDispatch();
  const history = useHistory()
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()
  const [resetPassword, setResetPassword] = useState(false)
  const [timezoneName, setTimezoneName] = useState('America/Los_Angeles');
  const [mobileCountryCode, setMobileCountryCode] = useState('81')
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback((newTimeZoneName: string) => {
    console.log(newTimeZoneName)
    setTimezoneName(newTimeZoneName);
    setOpen(false);
  }, []);

  const selectBefore = (
    <Select value={mobileCountryCode} onChange={(value: string) => setMobileCountryCode(value)} className="select-before">
      {
        mobileCountryCodes.map((code: any) =>
          <Option key={code.code} value={code.code}>{code.code}</Option>)
      }
    </Select>
  );
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  useEffect(() => {
    setTimezoneName(user.timezone)
    form.setFieldsValue({
      fullName: user.fullName,
      email: user.email,
      phone: user.phone,
      language: user.language,
    })
    // form.setFieldsValue(user)
  }, [])
  const changeLanguage = (value: string) => {
    console.log('value', value)
    i18n.changeLanguage(value)
  }
  const getUser = async () => {
    const result = await dispatch(feachAccountByTokenAsync({}))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      console.log('code', code)
      console.log('data', data)
      console.log('msg', msg)
      if (code === 0) {
        if (data.user.type !== 'admin') {
          localStorage.removeItem('token')
          localStorage.removeItem('tokenExpiredAt')
          dispatch(signout)
          message.error('User type error')
          return history.push('/')
        }
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        i18n.changeLanguage(data.user.language || 'en')
        setLoading(false)
      } else if (msg) {
        message.error(msg)
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        return history.push('/')
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiredAt')
      dispatch(signout)
      return history.push('/')
    }
  }
  const onFinish = async () => {
    form.validateFields()
      .then(async values => {
        if (!timezoneName) {
          return message.error(t('work.SelctTimezone'))
        }
        values.timezone = timezoneName
        values.resetPassword = resetPassword
        values.mobileCountryCode = mobileCountryCode
        setLoading(true)
        try {
          if (values.password) {
            values.password = md5(values.password)
          }
          if (values.oldPassword) {
            values.oldPassword = md5(values.oldPassword)
          }
        } catch (err) {
          console.log(err)
        }
        const result = await dispatch(updateProfileAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            message.success('Successful operation')
            getUser()
            if (resetPassword) {
              dispatch(signout)
              localStorage.removeItem('token')
              localStorage.removeItem('tokenExpiredAt')
              if (user.type == 'customer') {
                return history.push('/login')
              }
              history.push('/')
            }
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Card>
        <Form {...layout} form={form}
          name="control-hooks"
          onFinishFailed={onFinishFailed}

          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col span={12}>
              <>
                <Form.Item name="fullName" label={t('work.Name')}
                  rules={[
                    {
                      required: true,

                      message: t('work.Please_input_name'),

                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value.length >= 3) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('work.accountName')));
                      },
                    }),
                  ]}>
                  <Input placeholder="" />
                </Form.Item>
                <Form.Item label={t('work.Phone')} name="phone"
                  rules={[{ required: true, message: t('signup.Please_input_your_phone') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value.length >= 8) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('work.phoneLengthText')));
                    },
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      var reg = new RegExp(/^\d+$/);
                      if (reg.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('work.phoneNumberText')));
                    },
                  })]}>
                  <Input placeholder="" addonBefore={selectBefore} />
                </Form.Item>
                <Form.Item label={t('work.Timezone')} name="timezone">
                  <Input.Group compact>
                    <Input disabled style={{ width: 'calc(100% - 80px)' }} placeholder="" value={timezoneName} />
                    {
                      !user.timezoneUpdateDate || moment(user.timezoneUpdateDate).isBefore(moment()) ?
                        <Button type="primary" onClick={handleOpen} style={{ float: "right" }} >
                          {t('work.Edit')}
                        </Button> :
                        <Tooltip title={`${t('work.editTimezoneText')} ${moment(user.timezoneUpdateDate).format('DD/MM/YYYY')}`}>
                          <Button type="primary" disabled style={{ float: "right" }} >
                            {t('work.Edit')}
                          </Button>
                        </Tooltip>
                    }
                  </Input.Group>
                  <TimeZoneSelectDialog
                    open={open}
                    title={t('work.Timezone')}
                    buttonLabelOk={t('work.Submit')}
                    buttonLabelCancel={t('work.Cancel')}
                    description={t('work.timezoneDescription')}
                    timeZoneName={timezoneName}
                    onClose={handleClose}
                  />
                </Form.Item>
              </>

              <Form.Item label={t('work.Language')} name="language">
                <Select onChange={changeLanguage}>
                  <Option value='en'>English</Option>
                  <Option value='ja'>日本語</Option>
                  <Option value='ms'>Bahasa Melayu</Option>
                  <Option value='tr'>Türkçe</Option>
                  <Option value='pl'>język polski</Option>
                  <Option value='es'>Español</Option>
                  <Option value='de'>Deutsch</Option>
                  <Option value='fr'>Français</Option>
                </Select>
              </Form.Item>
              {
                (resetPassword ?
                  <>
                    <Form.Item
                      name="oldPassword" label={t('work.oldPassword')}
                      rules={[
                        {
                          required: true,
                          message: t('work.password_message'),
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="password" label={t('work.newPassword')}
                      rules={[
                        {
                          required: true,
                          message: t('work.password_message'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value && value.length >= 8) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('work.passwordlengthText')));
                          },
                        }),
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
                            if (reg.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('work.passwordText')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label={t('work.Confirmpassword')}
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: t('work.confirm_message'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('work.The_two_passwords_message')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </>
                  :
                  <Form.Item name="password" label={t('work.Password')} >
                    <Button onClick={() => setResetPassword(true)} >{t('work.Reset_Password')}</Button>
                  </Form.Item>)
              }
              <Form.Item wrapperCol={{ offset: 0, span: 2 }}>
                <Button loading={loading} type="primary" htmlType="submit">
                  {t('work.Save')}
                </Button>
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Card>
    </>
  );
}

export default Preference
