import { Modal, Input, Button, Row, Select, Table, message, Space } from 'antd'
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import moment from 'moment'
import { useAppDispatch } from '../../app/hooks'
import ExportButton from '../Components/exportButton'
import {
  getPaymentsAsync,
  exportPaymentsAsync
} from '../../reducers/portal/portalSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const { Option } = Select;
const Payment = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const [records, setRecords] = useState([])
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const fetchList = async() => {
    setLoading(true)
    const result = await dispatch(getPaymentsAsync({
      id: props.record._id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setRecords(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  useEffect(() => {
    if (props.record._id) {
      fetchList()
    }
  }, [props.record._id])

  const columns = [
    {
      title: t('work.OrderNumber'),
      dataIndex: 'intentId',
      width: '200px'
    },
    // {
    //   title: t('work.OrderType'),
    //   dataIndex: 'type',
    //   width: '200px'
    // },
    {
      title: t('work.Plan_Name'),
      dataIndex: 'planName',
      width: '200px'
    },
    {
      title: t('work.Payment'),
      dataIndex: 'Payment',
      width: '200px',
      render: (newExpiredDate: string, row: any) => (
        <>
         <span>{ row.currency} {row.amount}</span>
        </>
      ),
    },
    {
      title: t('work.Date'),
      dataIndex: 'createdAt',
      width: '200px',
      sorter: true,
      render: (createdAt: string) => (
        <>
         <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
        </>
      ),
    },
  ];
  return (
    <>
      <Modal
        className="orders-modal"
        visible={props.visible}
        width='900px'
        title={props.record._id ? t('work.Orders') : t('work.Orders')}
        onCancel={closeModal}
        footer={
          [
            <ExportButton exportApi={exportPaymentsAsync} exportQuery={{
              id: props.record._id
            }} />,
          <Button key="back" onClick={closeModal}>
            {t("work.Cancel")}
          </Button>
        ]
      }
      >

        <Row style={{width: '100%', marginBottom: 50}}>

        <Table
          bordered
          columns={columns}
          showSorterTooltip={false}
          rowKey={(record: any) => record._id}
          dataSource={records}
          loading={loading}
          pagination={false}
        />
        </Row>
      </Modal>
    </>
  );
}

export default Payment
