import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getDevices,
  deleteDevice,
  exportDevices,
  importDevices,
  importDevicesData,
  addDevice,
  updateDevice,
  updateDeviceStatus,
  getBarcodes,
  importList,
  updateManyStatus,
  getDeviceRecords
} from './devicesAPI';
const initialState = {}

export const deleteDeviceAsync = createApiAsyncThunk<any>('devices/deleteDevice', deleteDevice)


export const updateManyStatusAsync = createApiAsyncThunk<any>('devices/updateManyStatus', updateManyStatus)
export const updateDeviceStatusAsync = createApiAsyncThunk<any>('devices/updateDeviceStatus', updateDeviceStatus)
export const updateDeviceAsync = createApiAsyncThunk<any>('devices/updateDevice', updateDevice)

export const addDeviceAsync = createApiAsyncThunk<any>('devices/addDevice', addDevice)

export const getBarcodesAsync = createApiAsyncThunk<any>('devices/getBarcodes', getBarcodes)
export const getDeviceRecordsAsync = createApiAsyncThunk<any>('devices/getDeviceRecords', getDeviceRecords)
export const getDevicesAsync = createApiAsyncThunk<any>('devices/getDevices', getDevices)
export const exportDevicesAsync = createApiAsyncThunk<any>('devices/exportDevices', exportDevices)
export const importDevicesAsync = createApiAsyncThunk<any>('devices/importDevices', importDevices)
export const importDevicesDataAsync = createApiAsyncThunk<any>('devices/importDevicesData', importDevicesData)
export const importListAsync = createApiAsyncThunk<any>('devices/importList', importList)



export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {}
})

export default devicesSlice.reducer;
