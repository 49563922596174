import { Layout, Space, Button, Table, Input, message, DatePicker, Popover, Popconfirm, Tag } from 'antd';
import { useEffect, useState } from 'react';
import moment, { localeData } from 'moment-timezone'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getAccountsAsync,
  updateAccountExpiredDateAsync,
  turnOffRenewAsync
} from '../../reducers/customers/customersSlice'
import { useTranslation } from 'react-i18next';
import {
  selectUser
} from '../../reducers/account/accountSlice';
import _ from 'lodash'
import ReportChart from './ReportChart';
const { Content } = Layout;
const { Search } = Input;

interface propsObject {
  setVisible: Function;
  accounts: any;
  record: any;
  form: any;
  setRecord: Function;
  getList: Function;
  db: String;
}

const Accounts = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [chartVisible, setChartVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { t } = useTranslation()
  const [localDate, setLocalDate] = useState({} as any)
  const [record, setRecord] = useState({})
  useEffect(() => {
    if (props.record._id) {
      fetchList()
    }
  }, [props.record._id, props.accounts.length])

  const fetchList = async () => {
    setLoading(true)
    const params = {
      id: props.record._id,
      db: props.db
    }
    const result = await dispatch(getAccountsAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const deleteItem = async (id: string) => {
    setLoading(true)
    const result = await dispatch(turnOffRenewAsync({
      accountId: id,
      db: props.db
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList()
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleExipreDateChange = (value: any, account: any) => {
    let newLocalDate = _.cloneDeep(localeData) as any
    newLocalDate[account._id] = moment(value).startOf('day')
    setLocalDate(newLocalDate)
  }

  const updateExpiredDate = async (account: any) => {
    console.log('account', account)
    const values = {
      id: account._id,
      expiredDate: localDate[account._id].toDate(),
      db: props.db
    }
    const result = await dispatch(updateAccountExpiredDateAsync(values))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        message.success('Successful operation');
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  }

  const columns = [
    {
      title: t('work.PatientName'),
      dataIndex: 'fullName',
      sorter: true,
      width: '200px',
      render: (companyId: any, record: any) => (<span>{`${record.firstName || ''} ${record.lastName || ''}`}</span>)
    },
    {
      title: t('work.DeviceLoginUsername'),
      dataIndex: 'username',
      sorter: true,
      width: '250px',
    },
    {
      title: t('work.PatientID'),
      dataIndex: 'patientId',
      sorter: true,
      width: '250px',
    },
    {
      title: t('work.Plan_Name'),
      dataIndex: 'planName',
      width: '200px',
      render: (planName: string, row: any) => (
        <>
          {
            row.subscriptionStatus !== 'active' ? <span>Once-off Payment</span> : <span>{planName}</span>
          }
        </>
      ),
    },
    {
      title: t('work.Auto_renew'),
      dataIndex: 'subscriptionStatus',
      width: '180px',
      render: (subscriptionStatus: string) => (
        <>
          {
            subscriptionStatus === 'active' ? <Tag color="#87d068">Yes</Tag> : <Tag color="lime">No</Tag>
          }
        </>
      ),
    },
    {
      title: t('work.Expired_Date'),
      dataIndex: 'expiredDate',
      width: '400px',
      render: (expiredDate: string, account: any) => {
        return (
          <>
            <DatePicker
              disabled={user.type !== 'admin'}
              allowClear={false}
              onChange={(value: any) => handleExipreDateChange(value, account)}
              defaultValue={expiredDate ? moment(expiredDate) : undefined}
            />
            <Button disabled={!localDate[account._id] || user.type !== 'admin'} onClick={() => updateExpiredDate(account)} type="primary">Save</Button>
            <p style={{ color: 'red' }}>
              Server date: {
                localDate[account._id] ? localDate[account._id].tz('Asia/Tokyo').format('YYYY-MM-DD') :
                  (expiredDate ? moment(expiredDate).tz('Asia/Tokyo').format('YYYY-MM-DD') : '')}
            </p>
            {
              account.experienceDate ?
                <p>Money Back Gurantee expired date: ${moment(account.experienceDate).format('DD/MM/YYYY')}</p> :
                null
            }
          </>
        )
      },
    },
    {
      title: t('work.Status'),
      dataIndex: 'expiredDate',
      width: '150px',
      render: (expiredDate: string) => (expiredDate && moment(expiredDate).isAfter(moment()) ? <Tag color="#2db7f5">{t('work.Active')}</Tag> : <Tag color="#f50">{t('work.Expired')}</Tag>),
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Popconfirm
            title={t('work.TurnoffText')}
            onConfirm={() => deleteItem(record._id)}
            okText="Yes"
            cancelText="No"
          > {
              record.subscriptionStatus === 'active' ?
                <Button danger key="turnoff">
                  {t("work.Turnoffautorenew")}
                </Button> : ""
            }
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Content className="eyerising-content">
        <Table
          bordered
          columns={columns}
          showSorterTooltip={false}
          rowKey={(record: any) => record._id}
          dataSource={data}
          loading={loading}
          pagination={false}
        />
      </Content>
      <ReportChart
        record={record}
        setRecord={setRecord}
        visible={chartVisible}
        setVisible={setChartVisible}
      />
    </>
  )
}

export default Accounts;