// A mock function to mimic making an async request for data
import api from '../../utils/api'

export function getSetting(payload: any) {
  return api('/api/admin/setting', 'GET', payload)
}

export function getBaseSetting(payload: any) {
  return api('/api/admin/setting/base', 'GET', payload)
}


export function addSetting(payload: any) {
  return api('/api/admin/setting', 'POST', payload)
}



export function checkDatabase(payload: any) {
  return api('/api/admin/database/check', 'GET', payload)
}
