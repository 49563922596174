import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  updateCustomerInfo,
  updateCustomerAddress,
  deleteAccount,
  addAccount,
  getAccountsList,
  getOrders,
  getPlans,
  turnOffRenew,
  createPayment,
  exportPayments,
  bindDevice,
  getDoctors,
  getPayments,
  passwordPayload,
  deleteNotifications,
  getNotifications,
  resetPassword,
  forgotPassword,
  setForgotPassword,
  foundDevice,
  getRecords,
  lostDevice,
  getPaymethods,
  readNotifications,
  sendStaffEmail
} from './portalAPI';
const initialState = {}

export const updateCustomerInfoAsync = createApiAsyncThunk<any>('portal/updateCustomerInfo', updateCustomerInfo)

export const updateCustomerAddressAsync = createApiAsyncThunk<any>('portal/updateCustomerAddress', updateCustomerAddress)


export const turnOffRenewAsync = createApiAsyncThunk<any>('portal/turnOffRenew', turnOffRenew)
export const getOrdersAsync = createApiAsyncThunk<any>('portal/getOrders', getOrders)
export const getPlansAsync = createApiAsyncThunk<any>('portal/getPlans', getPlans)


export const getNotificationsAsync = createApiAsyncThunk<any>('portal/getNotifications', getNotifications)
export const readNotificationsAsync = createApiAsyncThunk<any>('portal/readNotifications', readNotifications)
export const deleteNotificationsAsync = createApiAsyncThunk<any>('portal/deleteNotifications', deleteNotifications)
export const getAccountsListAsync = createApiAsyncThunk<any>('portal/getAccountsList', getAccountsList)
export const deleteAccountAsync = createApiAsyncThunk<any>('portal/deleteAccount', deleteAccount)

export const exportPaymentsAsync = createApiAsyncThunk<any>('portal/exportPayments', exportPayments)
export const getPaymentsAsync = createApiAsyncThunk<any>('portal/getPayments', getPayments)
export const bindDeviceAsync = createApiAsyncThunk<any>('portal/bindDevice', bindDevice)
export const createPaymentAsync = createApiAsyncThunk<any>('portal/createPayment', createPayment)
export const getPaymethodsAsync = createApiAsyncThunk<any>('portal/getPaymethods', getPaymethods)
export const addAccountAsync = createApiAsyncThunk<any>('portal/addAccount', addAccount)
export const getDoctorsAsync = createApiAsyncThunk<any>('portal/getDoctors', getDoctors)


export const getRecordsAsync = createApiAsyncThunk<any>('portal/getRecords', getRecords)
export const lostDeviceAsync = createApiAsyncThunk<any>('portal/lostDevice', lostDevice)
export const foundDeviceAsync = createApiAsyncThunk<any>('portal/foundDevice', foundDevice)
export const sendStaffEmailAsync = createApiAsyncThunk<any>('portal/sendStaffEmail', sendStaffEmail)
export const resetPasswordAsync = createApiAsyncThunk<passwordPayload>('portal/resetPassword', resetPassword)
export const forgotPasswordAsync = createApiAsyncThunk<any>('portal/forgotPassword', forgotPassword)
export const setForgotPasswordAsync = createApiAsyncThunk<any>('portal/setForgotPassword', setForgotPassword)
export const logsSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {}
})

export default logsSlice.reducer;
