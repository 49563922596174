import { Layout, message, Spin, Breadcrumb } from 'antd';
import './WorkSpace.scss'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import UserSlider from './UserSlider'
import TopBar from './TopBar'
import Users from '../Users/Users'
import Inventory from '../Inventory/Inventory'
import Plans from '../Plans/Plans'
import { useTranslation } from 'react-i18next';
import Patients from '../Patients/Patients'
import Companies from '../Companies/Companies'
import Payments from '../Payments/Payments'
import Preferences from '../Preferences/Preferences'
import Dashboard from '../Dashboard/Dashboard'
import { Switch, Route, useHistory, useLocation, Link } from "react-router-dom";
import {
  feachAccountByTokenAsync,
  restore,
  selectUser,
  signout,
  restoreUser
} from '../../reducers/account/accountSlice';
import { useEffect, useState } from 'react';
import PaymentResult from '../Payments/PaymentResult';

const { Header, Sider, Content } = Layout;

const WorkSpace = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation();
  const history = useHistory()
  useEffect(() => {
    getUser()
  }, [])
  const getUser = async () => {
    const result = await dispatch(feachAccountByTokenAsync({}))
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        if (data.user.type !== 'normal') {
          localStorage.removeItem('token')
          localStorage.removeItem('tokenExpiredAt')
          dispatch(signout)
          message.error('User type error')
          return history.push('/')
        }
        data.tokenExpiredAt = new Date(data.tokenExpiredAt)
        dispatch(restore({
          token: data.token,
          tokenExpiredAt: data.tokenExpiredAt.toString()
        }))
        dispatch(restoreUser({ user: data.user }))
        i18n.changeLanguage(data.user.language || 'en')
        setLoading(false)
      } else if (msg) {
        message.error(msg)
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiredAt')
        dispatch(signout)
        return history.push('/')
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiredAt')
      dispatch(signout)
      return history.push('/')
    }
  }
  const breadcrumbNameMap: Record<string, string> = {
    '/work': user.company && user.company.name,
    '/work/companies': 'Companies',
    '/work/users': 'Users',
    '/work/inventory': 'Inventory',
    '/work/customers': 'Patients',
    '/work/plans': 'Plans',
    '/work/products': 'Products',
    '/work/payments': 'Payments',
    '/work/preference': 'Preferences',
  };
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <span>{breadcrumbNameMap[url]}</span>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <>
      <Layout className='user-workspace' style={{ minHeight: '100vh' }}>
        {loading ?
          <Layout style={{ padding: '200px 0' }}>
            <Spin size="large" />
          </Layout> :
          <Layout style={{
            padding: '0 0'
          }}>
            <Sider className="eyerising-sider">
              <UserSlider />
            </Sider>
            <Content style={{
              padding: '0 0'
            }}>
              <Header className="eyerising-header">
                <TopBar />
              </Header>
              <Breadcrumb >{breadcrumbItems}</Breadcrumb>
              {
                user.type === 'normal' && user.userType === 'doctor' ?
                  <Switch>
                    <Route path="/work/customers">
                      <Patients />
                    </Route>
                    <Route path="/work/preference">
                      <Preferences />
                    </Route>
                    <Route path="/work/payment-result">
                      <PaymentResult />
                    </Route>
                  </Switch> :
                  <Switch>
                    <Route path="/work/companies">
                      <Companies />
                    </Route>
                    <Route path="/work/customers">
                      <Patients />
                    </Route>
                    <Route path="/work/users">
                      <Users />
                    </Route>
                    <Route path="/work/plans">
                      <Plans />
                    </Route>
                    <Route path="/work/inventory">
                      <Inventory />
                    </Route>
                    <Route path="/work/payments">
                      <Payments />
                    </Route>
                    <Route path="/work/preference">
                      <Preferences />
                    </Route>
                    <Route path="/work/payment-result">
                      <PaymentResult />
                    </Route>
                    <Route path="/work/">
                      <Dashboard />
                    </Route>
                  </Switch>
              }
            </Content>
          </Layout>
        }
      </Layout>
    </>
  )
}


export default WorkSpace;