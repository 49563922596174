import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getAllCompanies,
  getCompanies,
  addCompany,
  putCompany,
  deleteCompany,
  exportCompanies,
  getDashboardData
} from './companiesAPI';
const initialState = {}

export const putCompanyAsync = createApiAsyncThunk<any>('companies/putCompany', putCompany)
export const deleteCompanyAsync = createApiAsyncThunk<any>('companies/deleteCompany', deleteCompany)


export const addCompanyAsync = createApiAsyncThunk<any>('companies/addCompany', addCompany)

export const getAllCompaniesAsync = createApiAsyncThunk<any>('companies/getAllCompanies', getAllCompanies)
export const getCompaniesAsync = createApiAsyncThunk<any>('companies/getCompanies', getCompanies)
export const exportCompaniesAsync = createApiAsyncThunk<any>('companies/exportCompanies', exportCompanies)

export const getDashboardDataAsync = createApiAsyncThunk<any>('companies/getDashboardData', getDashboardData)



export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {}
})

export default companiesSlice.reducer;
