import { Result } from 'antd';
import config from '../../../config/config'
import { useTranslation } from 'react-i18next';

const ResultPage = () => {
  const { t } = useTranslation();

  return (
    <div style={{
      margin: '100px auto'
    }}>
      <Result
        status="success"
        title={t('signup.Success')}
        subTitle={
          [<span>
            {t('signup.NextSteps')} <a href={config.portalDomain}>{config.portalDomain}</a>
          </span>]
        }
        extra={[
        ]}
      />
    </div>
  )
};

export default ResultPage;