// A mock function to mimic making an async request for data
import api from '../../utils/api'

export function updateCustomer(payload: any) {
  return api('/api/admin/customer', 'POST', payload)
}

export function getRecords(payload: any) {
  return api('/api/admin/records', 'GET', payload)
}

export function getRequests(payload: any) {
  return api('/api/admin/requests', 'GET', payload)
}

export function getPayments(payload: any) {
  return api('/api/admin/payments', 'GET', payload)
}

export function updateAccountExpiredDate(payload: any) {
  return api('/api/admin/customers/accounts', 'PUT', payload)
}

export function turnOffRenew(payload: any) {
  return api('/api/admin/subscrition', 'DELETE', payload)
}

export function getAccounts(payload: any) {
  return api('/api/admin/customer/accounts', 'GET', payload)
}

export function addAccountByAdmin(payload: any) {
  return api('/api/admin/accounts', 'POST', payload)
}

export function bindDeviceByAdmin(payload: any) {
  return api('/api/admin/devices/bind', 'post', payload)
}

export function unbindDeviceByAdmin(payload: any) {
  return api('/api/admin/devices/unbind', 'post', payload)
}

export function deleteAccount(payload: any) {
  return api('/api/admin/accounts', 'DELETE', payload)
}
