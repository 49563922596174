const _ = require('lodash')

var config = {
  // Generic
  domain: 'http://localhost:3000',
  portalDomain: 'http://localhost:3000',

  // Airwallex
  aw_env: process.env.REACT_APP_AW_ENV,
  currencies: [
    { label: 'AUD', value: 'aud' },
    { label: 'NZD', value: 'nzd' },
    { label: "JPY", value: "jpy" },
    { label: "GBP", value: "gbp" },
    { label: "SGD", value: "sgd" },
    { label: "NOK", value: "nok" },
    { label: "EUR", value: "eur" },
    { label: "CHF", value: "chf" },
    { label: "MYR", value: "myr" },
    { label: "PHP", value: "php" },
    { label: "TWD", value: "twd" },
    { label: "VND", value: "vnd" },
  ],

  // Countries
  countries: [
    {
      name: 'Australia',
      key: 'Australia',
      timezone: 'Australia/Sydney',
      mobileCountryCode: '61',
    },
    {
      name: 'Japan',
      key: 'Japan',
      timezone: 'Asia/Tokyo',
      mobileCountryCode: '81',
    },
    {
      name: 'New Zealand',
      key: 'New Zealand',
      timezone: 'Pacific/Auckland',
      mobileCountryCode: '64',
    },
    {
      name: 'Poland',
      key: 'Poland',
      timezone: 'Europe/Warsaw',
      mobileCountryCode: '48',
    },
    {
      name: 'Singapore',
      key: 'Singapore',
      timezone: 'Asia/Singapore',
      mobileCountryCode: '65',
    },
    {
      name: 'Turkey',
      key: 'Turkey',
      timezone: 'Europe/Istanbul',
      mobileCountryCode: '90',
    },
    {
      name: 'United Kingdom',
      key: 'United Kingdom',
      timezone: 'Europe/London',
      mobileCountryCode: '44',
    },
    {
      name: 'Norway',
      key: 'Norway',
      timezone: 'Europe/Oslo',
      mobileCountryCode: '47',
    },
    {
      name: 'France',
      key: 'France',
      timezone: 'Europe/Paris',
      mobileCountryCode: '33',
    },
    {
      name: 'Spain',
      key: 'Spain',
      timezone: 'Europe/Madrid',
      mobileCountryCode: '34',
    },
    {
      name: 'Italy',
      key: 'Italy',
      timezone: 'Europe/Rome',
      mobileCountryCode: '39',
    },
    {
      name: 'Germany',
      key: 'Germany',
      timezone: 'Europe/Berlin',
      mobileCountryCode: '49',
    },
    {
      name: 'Portugal',
      key: 'Portugal',
      timezone: 'Europe/Lisbon',
      mobileCountryCode: '351',
    },
    {
      name: 'Luxembourg',
      key: 'Luxembourg',
      timezone: 'Europe/Luxembourg',
      mobileCountryCode: '352',
    },
    {
      name: 'Belgium',
      key: 'Belgium',
      timezone: 'Europe/Brussels',
      mobileCountryCode: '32',
    },
    {
      name: 'Austria',
      key: 'Austria',
      timezone: 'Europe/Vienna',
      mobileCountryCode: '43',
    },
    {
      name: 'Switzerland',
      key: 'Switzerland',
      timezone: 'Europe/Zurich',
      mobileCountryCode: '41',
    },
    {
      name: 'Malaysia',
      key: 'Malaysia',
      timezone: 'Asia/Manila',
      mobileCountryCode: '60',
    },
    {
      name: 'Philippines',
      key: 'Philippines',
      timezone: 'Asia/Kuala_Lumpur',
      mobileCountryCode: '63',
    },
    {
      name: 'Taiwan',
      key: 'Taiwan',
      timezone: 'Asia/Taipei',
      mobileCountryCode: '886',
    },
    {
      name: 'Vietnam',
      key: 'Vietnam',
      timezone: 'Asia/Ho_Chi_Minh',
      mobileCountryCode: '84',
    }
  ],
}

if (process.env.NODE_ENV === 'production') {
  config.aw_env = 'prod'
  config.domain = 'https://back.eyerisinginternational.com'
  config.backDomain = 'https://back.eyerisinginternational.com'
  config.portalDomain = 'https://portal.eyerisinginternational.com'
}
if (window.location.host.indexOf('test') > -1) {
  config.aw_env = 'demo'
  config.portalDomain = config.portalDomain.replace('portal.', 'portal.test.')
  config.domain = config.domain.replace('back.', 'back.test.')
  config.backDomain = config.domain
}
if (window.location.host.indexOf('uat') > -1) {
  config.aw_env = 'demo'
  config.portalDomain = config.portalDomain.replace('portal.', 'portal.uat.')
  config.domain = config.domain.replace('back.', 'back.uat.')
  config.backDomain = config.domain
}

module.exports = config