import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getAllUsers,
  getUsers,
  addUser,
  userPayload,
  signupPayload,
  createMeeting,
  meetingPayload,
  signup,
  updateProfile,
  passwordPayload,
  deleteCustomer,
  sentUserEmail,
  lockCustomer,
  unlockCustomer,
  exportUsers,
  closeRquest,
  debugCustomer,
  closeDebugCustomer,
  getUserBaseInfo,
  getUserToken,
  deleteUser,
  sendStaffEmail,
  forgotPassword,
  getAllDoctors,
  setForgotPassword,
  getCustomers,
  exportCustomers,
} from './usersAPI';
const initialState = {}

export const addUserAsync = createApiAsyncThunk<userPayload>('users/addUser', addUser)
export const deleteUserAsync = createApiAsyncThunk<any>('users/deleteUser', deleteUser)
export const deleteCustomerAsync = createApiAsyncThunk<any>('users/deleteCustomer', deleteCustomer)
export const lockCustomerAsync = createApiAsyncThunk<any>('users/lockCustomer', lockCustomer)
export const unlockCustomerAsync = createApiAsyncThunk<any>('users/unlockCustomer', unlockCustomer)
export const closeRquestAsync = createApiAsyncThunk<any>('users/closeRquest', closeRquest)
export const exportUserAsync = createApiAsyncThunk<any>('users/exportUser', exportUsers)
export const debugCustomerAsync = createApiAsyncThunk<any>('users/debugCustomer', debugCustomer)
export const closeDebugCustomerAsync = createApiAsyncThunk<any>('users/closeDebugCustomer', closeDebugCustomer)
export const sendAdminStaffEmailAsync = createApiAsyncThunk<any>('portal/sendStaffEmail', sendStaffEmail)
export const exportCustomersAsync = createApiAsyncThunk<any>('users/exportCustomers', exportCustomers)
export const signupAsync = createApiAsyncThunk<signupPayload>('users/signup', signup)
export const createMeetingAsync = createApiAsyncThunk<meetingPayload>('users/meeting', createMeeting)
export const getUsersAsync = createApiAsyncThunk<any>('users/getUsers', getUsers)
export const getAllDoctorsAsync = createApiAsyncThunk<any>('users/getAllDoctors', getAllDoctors)
export const getCustomersAsync = createApiAsyncThunk<any>('users/getCustomers', getCustomers)
export const getAllUsersAsync = createApiAsyncThunk<any>('users/getAllUsers', getAllUsers)
export const sentUserEmailAsync = createApiAsyncThunk<any>('users/sentUserEmail', sentUserEmail)
export const getUserTokenAsync = createApiAsyncThunk<any>('users/getUserToken', getUserToken)
export const getUserBaseInfoAsync = createApiAsyncThunk<any>('users/getUserBaseInfo', getUserBaseInfo)
export const updateProfileAsync = createApiAsyncThunk<passwordPayload>('users/updateProfile', updateProfile)
export const forgotPasswordAsync = createApiAsyncThunk<any>('users/forgotPassword', forgotPassword)
export const setForgotPasswordAsync = createApiAsyncThunk<any>('users/setForgotPassword', setForgotPassword)
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {}
})

export default usersSlice.reducer;
