import { Layout, Space, Button, Popconfirm, Table, Input, Dropdown, Menu, message, Row, Col, Select, Badge, Tooltip } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import { useEffect, useState } from 'react';
import config from '../../config/config'

import BatchModal from './BatchModal'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getDevicesAsync,
  deleteDeviceAsync,
  exportDevicesAsync,
} from '../../reducers/devices/devicesSlice'
import { useTranslation } from 'react-i18next';
import {
  selectUser
} from '../../reducers/account/accountSlice';
import moment from 'moment'
import { CloudUploadOutlined } from '@ant-design/icons';
import { addSettingAsync, getBaseSettingAsync } from '../../reducers/setting/settingSlice'

import ExportButton from '../Components/exportButton'
import InventoryModal from './InventoryModal'
import InventoryMoreModal from './InventoryMoreModal'
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const Inventorys = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)

  const user = useAppSelector(selectUser)
  const [dbs, setDbs] = useState([])
  const [db, setDb] = useState('jp')
  const [importVisible, setImportVisible] = useState(false)
  const [action, setAction] = useState('')
  const [data, setData] = useState([])
  const [status, setStatus] = useState('all')
  const [modelVisible, setModelVisible] = useState(false)
  const [moreVisble, setMoreVisible] = useState(false)
  const [searchStr, setsearchStr] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [record, setRecord] = useState({})
  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
        // form.setFieldsValue(data.setting)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setDb(value)
    // fetchList({
    //   ...pagination,
    //   db: value
    // })
  };
  const handleRequestingChange = (value: string) => {
    setStatus(value)
    fetchList({
      ...pagination,
      status: value
    })
  };
  const handleEmailChange = (value: string) => {
    setCustomerEmail(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      customerEmail: value
    })
  };
  const companyNameChange = (value: string) => {
    setCompanyName(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList({
      ...pagination,
      companyName: value
    })
  };
  const setItem = (record: any) => {
    setRecord(record)
    setModelVisible(true)
  }
  const setItemMore = (record: any, action: string) => {
    setAction(action)
    setRecord(record)
    setMoreVisible(true)
  }
  useEffect(() => {
    fetchSetting()
    fetchList(pagination)
  }, [])

  const onSearch = async (value: string) => {
    setsearchStr(value)
    if (!value) {
      value = 'NONE_STR'
    }
    fetchList(pagination, value)
  }
  const onSearchEmail = async (value: string) => {
    setCustomerEmail(value)
    fetchList(pagination, value)
  }

  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.searchStr = search || searchStr
    params.status = params.status || status
    params.customerEmail = params.customerEmail || customerEmail
    params.companyName = params.companyName || companyName
    params.db = params.db || db
    for (let key in params) {
      if (params[key] === 'NONE_STR') {
        params[key] = ''
      }
    }
    !params.order && delete params.order
    !params.sort && delete params.sort
    const result = await dispatch(getDevicesAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const deleteItem = async (id: string,) => {
    setLoading(true)
    const result = await dispatch(deleteDeviceAsync({
      id
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        fetchList({ ...pagination })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const statusMap: any = {
    'inStock': 'In stock'
  }
  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }
  const menu = (rec: any) => {
    const menuBtns: any = [
      {
        key: 'Distribute',
        label: (
          <Button disabled={['In stock'].indexOf(rec.status) == -1} type='text' onClick={() => setItemMore(rec, 'distribute')}>
            {t('work.Distribute')}
          </Button>
        ),
      },
      {
        key: 'Collect',
        label: (
          <Button disabled={['Not in use', 'In use', 'Recalled', 'Discarded'].indexOf(rec.status) == -1} type='text' onClick={() => setItemMore(rec, 'collect')}>
            {t('work.Collect')}
          </Button>
        ),
      },
      {
        type: 'divider', // Must have
      },
      {
        key: 'Suspend',
        label: (
          <Button disabled={['In use'].indexOf(rec.status) == -1} type='text' onClick={() => setItemMore(rec, 'suspend')}>
            {t('work.Suspend')}
          </Button>
        ),
      },
      {
        key: 'Resume',
        label: (
          <Button disabled={['Not in use'].indexOf(rec.status) == -1} type='text' onClick={() => setItemMore(rec, 'resume')}>
            {t('work.Resume')}
          </Button>
        ),
      },
      {
        type: 'divider', // Must have
      },
      {
        key: 'Transfer',
        label: (
          <Button disabled={['In stock'].indexOf(rec.status) == -1} type='text' onClick={() => setItemMore(rec, 'transfer')}>
            {t('work.Transfer')}
          </Button>
        ),
      },
      {
        key: 'Receive',
        label: (
          <Button disabled={
            (['Transferring'].indexOf(rec.status) == -1 || (rec.toCompanyId !== user.companyId && user.type !== 'admin'))
          } type='text' onClick={() => setItemMore(rec, 'receive')}>
            {t('work.Receive')}
          </Button>
        ),
      },
      {
        type: 'divider', // Must have
      },
      {
        key: 'Repair',
        label: (
          <Button disabled={['Servicing', 'In stock'].indexOf(rec.status) == -1} type='text' onClick={() => setItemMore(rec, 'repair')}>
            {t('work.Repair')}
          </Button>
        ),
      },
      {
        key: 'Accept',
        label: (
          <Button type='text' disabled={['Repairing'].indexOf(rec.status) == -1} onClick={() => setItemMore(rec, 'accept')}>
            Accept
          </Button>
        ),
      },
      {
        type: 'divider', // Must have
      },
      {
        key: 'Service',
        label: (
          <Button type='text' disabled={['In stock'].indexOf(rec.status) == -1} onClick={() => setItemMore(rec, 'service')}>
            Service
          </Button>
        ),
      },
      {
        key: 'Refresh',
        label: (
          <Button type='text' disabled={['Servicing'].indexOf(rec.status) == -1} onClick={() => setItemMore(rec, 'refresh')}>
            {t('work.Refresh')}
          </Button>
        ),
      },
      {
        type: 'divider', // Must have
      },
      {
        key: 'Lose',
        label: (
          <Button type='text' disabled={['Not in use', 'In use'].indexOf(rec.status) == -1} onClick={() => setItemMore(rec, 'lose')}>

            {t('work.Lose')}
          </Button>
        ),
      },
      {
        key: 'Find',
        label: (
          <Button type='text' disabled={['Lost'].indexOf(rec.status) == -1} onClick={() => setItemMore(rec, 'find')}>
            {t('work.Find')}
          </Button>
        ),
      },
      {
        type: 'divider', // Must have
      }, ,]
    if (user.type === 'admin') {
      menuBtns.push(
        {
          key: 'Discard',
          label: (
            <Button type='text' disabled={['Repairing', 'In stock', 'Servicing'].indexOf(rec.status) == -1} onClick={() => setItemMore(rec, 'discard')}>

              {t('work.Discard')}
            </Button>
          ),
        })
      menuBtns.push(
        {
          key: 'Recall',
          label: (
            <Button type='text' disabled={['In stock'].indexOf(rec.status) == -1} onClick={() => setItemMore(rec, 'recall')}>

              {t('work.Recall')}
            </Button>
          ),
        })
    }
    return (<Menu items={menuBtns} />)
  }

  const columns = [
    {
      title: t('work.Product'),
      dataIndex: 'productName',
      sorter: true,
      width: '200px',
      render: (productName: string, row: any) => (
        <>
          <span>{productName}</span>
        </>
      ),
    },
    {
      title: t('work.Status'),
      dataIndex: 'status',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Serial_Number'),
      dataIndex: 'serial_number',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Lastupdate'),
      dataIndex: 'updatedAt',
      width: '200px',
      render: (updatedAt: string) => (
        <>
          <span>{moment(updatedAt).format('DD/MM/YYYY HH:mm')}</span>
        </>
      ),
    },
    {
      title: t('work.Customer'),
      dataIndex: 'customerEmail',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.CurrentCompany'),
      dataIndex: 'companyName',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Tocompany'),
      dataIndex: 'toCompanyName',
      sorter: true,
      width: '200px',
    },
    {
      title: t('work.Actions'),
      key: 'actions',
      fixed: 'right' as 'right',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type='primary' size='small' onClick={() => setItem(record)} key="1">
            View
          </Button>
          <Dropdown overlay={menu(record)} placement="bottomLeft">
            <Button size='small'>More</Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const selectBefore = (<Select defaultValue={db} style={{ width: 105 }} placeholder="" onChange={handleChange}>

    {
      dbs.map((co: any) => {
        return (
          <Option key={co.key} value={co.key}>{co.name}</Option>)
      })
    }
  </Select>)
  return (
    <>
      <div className='eyerising-page-header'>
        <div className='header-content' >
          <Row justify="space-between">
            <Col span={24}>
              <Space>
                <Search placeholder={t('work.SearchSerialNumber')} onSearch={onSearch} style={{ width: 200 }} />
                <Select defaultValue={status} style={{ width: 200 }} placeholder={t('work.filterStatus')} onChange={handleRequestingChange}>
                  <Option key='all' value='all'>All</Option>
                  <Option key='In use' value='In use'>In use</Option>
                  <Option key='Not in use' value='Not in use'>Not in use</Option>
                  <Option key='In stock' value='In stock'>In stock</Option>
                  <Option key='Lost' value='Lost'>Lost</Option>
                  <Option key='Discarded' value='Discarded'>Discarded</Option>
                  <Option key='Recalled' value='Recalled'>Recalled</Option>
                  <Option key='Transferring' value='Transferring'>Transferring</Option>
                  <Option key='Servicing' value='Servicing'>Servicing</Option>
                  <Option key='Repairing' value='Repairing'>Repairing</Option>
                </Select>
                {user.type === 'admin' ?
                  <Search addonBefore={selectBefore} placeholder={t('work.CustomerEmail')} onSearch={handleEmailChange} style={{ width: 300 }} />
                  :
                  <Search placeholder={t('work.CustomerEmail')} onSearch={handleEmailChange} style={{ width: 200 }} />}
                <Search placeholder={t('work.searchByCompany')} onSearch={companyNameChange} style={{ width: 300 }} />
                <Button className='table-btn' key="2" onClick={() =>
                  fetchList({
                    ...pagination
                  })}>{t('work.Refresh')}</Button>
              </Space>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Space>
                <Button icon={<CloudUploadOutlined />} onClick={() => {
                  setAction('transfer')
                  setImportVisible(true)
                }} type='primary'>
                  {t('work.Batchtransfer')}
                </Button>
                <Button icon={<CloudUploadOutlined />} onClick={() => {
                  setAction('receive')
                  setImportVisible(true)
                }} type='primary'>
                  {t('work.Batchreceive')}
                </Button>
                {
                  user.type === 'admin' ?
                    <Button icon={<CloudUploadOutlined />} onClick={() => {
                      setAction('recall')
                      setImportVisible(true)
                    }} type='primary'>
                      {t('work.Batchrecall')}
                    </Button>
                    : ''
                }
                <ExportButton exportApi={exportDevicesAsync} exportQuery={{
                  searchStr: searchStr,
                  status,
                  customerEmail,
                  companyName,
                  db,
                  isInventory: true
                }} />
              </Space>

            </Col>
          </Row>
        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <InventoryModal
        record={record}
        setRecord={setItem}
        visible={modelVisible}
        setVisible={setModelVisible}
        getList={() => fetchList(pagination)}
      />
      <InventoryMoreModal
        action={action}
        record={record}
        setRecord={setItemMore}
        visible={moreVisble}
        setVisible={setMoreVisible}
        getList={() => fetchList(pagination)}
      />

      <BatchModal
        action={action}
        getList={() => fetchList(pagination)}
        visible={importVisible}
        setVisible={setImportVisible}
      />
    </>
  )
}


export default Inventorys;