import { Space, Button, Row, Card, Col, Select, Dropdown, Menu, Divider } from 'antd'
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone'
import { useState } from 'react';
import { useAppSelector } from '../../app/hooks'
import html2canvas from 'html2canvas'
import { DownOutlined } from '@ant-design/icons';
import { jsPDF } from "jspdf";
import { Column } from '@ant-design/plots';
import logo2 from '../../assets/logo2.png'
import { calculateComplianceRate } from '../../utils/complianceRate'


import {
  selectUser
} from '../../reducers/account/accountSlice';
const Option = Select.Option
const Report = () => {
  const user = useAppSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const [accountName, setAccountName] = useState('')
  const [accountId, setAccountId] = useState('')
  const { t, i18n } = useTranslation()
  const [usageData, setUsageData] = useState([] as any)
  const [total, setTotal] = useState(0)
  const [exportMonths, setExportMonths] = useState('4')

  const getData = (accountId?: string) => {
    const dateMap = {} as any
    let months = []
    let MaxMonths = Number(exportMonths)
    for (let i = 0; i < MaxMonths; i++) {
      months.push(i)
    }
    months = months.reverse()
    const dates: string[] = months.map((num: number) => {
      return moment().tz(user.timezone).subtract(num, 'month').format('YYYY-MM')
    })
    const records = user.device && user.device.records
      .filter((record: any) => record.accountId === accountId)

    dates.map((date: string) => {
      if (!dateMap[date]) {
        dateMap[date] = {
          count: 0
        }
      }
      records.map((record: any) => {
        if (moment(record.createdAt).tz(user.timezone).format('YYYY-MM') === date) {
          dateMap[date].count++
        }
      })
    })

    // Date of first treatment session
    let startRecord: any = null
    if (records.length) {
      startRecord = moment(records[0].createdAt)
    } else {
      return
    }

    const finalDate = [] as any

    // Calculate total compliance rate
    // Number of days between now in the patient timezone and the day of the first treatment
    const totalDays = (moment().tz(user.timezone).startOf('day').diff(startRecord.tz(user.timezone).startOf('day'), 'days') + 1)
    const totaltemp = calculateComplianceRate(totalDays, records.length)
    setTotal(totaltemp)

    dates.map((dt: string) => {
      let diffDays = 0
      // If first treatment was in the processed month
      if (startRecord && dt === startRecord.format('YYYY-MM')) {
        // If processed month is current month
        if (moment().tz(user.timezone).format('YYYY-MM') === dt) {
          // Number of days between now in the patient timezone and the day of the first treatment
          diffDays = moment().tz(user.timezone).startOf('day').diff(startRecord.tz(user.timezone).startOf('day'), 'days') + 1
        }
        // If processed month is in the past
        else {
          // Last day of the processed month
          const lastDate = moment(`${dt}-15`).tz(user.timezone).endOf('month')
          // Number of days between the last day of the current month and the day of the first treatment
          diffDays = lastDate.startOf('day').diff(startRecord.tz(user.timezone).startOf('day'), 'days') + 1
        }
      }
      // If first treatment is NOT in the processed month
      else {
        // If processed month is current month
        if (moment().tz(user.timezone).format('YYYY-MM') === dt) {
          // Number of days between the start of the month and now im the patients timezone
          diffDays = moment().tz(user.timezone).date()
        }
        // If processed month is in the past
        else {
          // Last day of the processed month
          diffDays = moment(`${dt}-15`).tz(user.timezone).endOf('month').date()
        }
      }

      finalDate.push({
        'Date': moment(dt).format('MMM'),
        'Compliance': calculateComplianceRate(diffDays, dateMap[dt].count)
      })
    })

    setUsageData(finalDate)
  }

  const dataConfig = {
    data: usageData,
    isGroup: true,
    xField: 'Date',
    yField: 'Compliance',
    yAxis: {
      title: {
        text: `${t('work.ComplianceRate')}(%)`
      },
      gridIndex: 0,
      min: 0,
      max: 100,
      interval: 1
    },
    color: (data: any, record: any) => {
      let color = "red"
      for (let i = 0; i < usageData.length; i++) {
        const uDate = usageData[i]
        if (uDate.Date === data.Date && uDate['Compliance'] >= 75) {
          color = '#0ED1AD'
        }
      }
      return color
    },
    /** 设置颜色 */

    /** 设置间距 */
    // marginRatio: 0.1,
    label: {
      // 可手动配置 label 数据标签位置
      // 'top', 'middle', 'bottom'
      // 可配置附加的布局方法
      content: function content(_ref: any) {
        return ''.concat(_ref.Compliance, '%');
      },
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: 'interval-adjust-position',
        }, // 数据标签防遮挡
        {
          type: 'interval-hide-overlap',
        }, // 数据标签文颜色自动调整
        {
          type: 'adjust-color',
        },
      ],
    },
  };
  const savePdf = async (account: any) => {
    const width = 210.28
    const height = 841.89
    window.scrollTo(0, 0)
    setTotal(0)
    setAccountName(`${account.firstName} ${account.lastName}`)
    setAccountId(account.patientId)
    getData(account._id)
    setLoading(true)
    setTimeout(() => {
      const target = document.getElementById('target') as any
      console.log(target.offsetWidth)
      html2canvas(target, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        scale: 2, // 处理模糊问题
        width: target.offsetWidth,
        height: target.offsetHeight
      }).then((canvas) => {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        var imgWidth = width
        var imgHeight = width / contentWidth * contentHeight;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        var pdf = new jsPDF();
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        pdf.save('export.pdf');
        setLoading(false)
      })
    }, 2000)
  }

  return (
    <>
      {
        <div style={{ width: '100%' }}>
          <Row style={{
            height: 100
          }}>
            <Col md={15} xs={0}>
            </Col>
            <Col md={9} xs={24}>
              <Select value={exportMonths} style={{ width: 120, float: 'left' }} onChange={(value: string) => setExportMonths(value)} className="select-before">
                {
                  ['4', '6', '12'].map((code: any) =>
                    <Option key={code} value={code}>{code} {t('time.monthsPlural')} </Option>)
                }
              </Select>
              <div style={{ float: 'left' }} >
                <Dropdown overlay={
                  <Menu >
                    {
                      user.accounts && user.accounts.map((account: any) => {
                        return <Menu.Item key={account._id} onClick={() => savePdf(account)}> {account.firstName} {account.lastName} </Menu.Item>
                      }
                      )
                    }
                  </Menu>}
                  trigger={['click']}
                >
                  <Button style={{ float: 'right' }} type='primary' loading={loading}>
                    <Space>
                      {t('work.exportPDF')}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
            </Col>
          </Row>

          <div id="target-content">
            <div id="target">
              <div style={{ height: 60 }}>
                <img style={{ float: 'right', height: 40 }} src={logo2} alt="" />
              </div>
              <h1 style={{ marginBottom: 0 }}>
                {t('work.RLRLComplianceReport')}
              </h1>
              <Row style={{
                margin: '0 0'
              }}>
                <Col span={24}>
                  <div className='report-profile'>
                    <h4>
                      {t('work.PatientName')}: {accountName}
                    </h4>
                    <h4>
                      {t('work.PatientID')}: {accountId}
                    </h4>
                  </div>

                </Col>
              </Row>
              <Row>

                <Col span={24}>
                  <Card>
                    <h2>
                      {t('work.PatientComplianceRate')}
                    </h2>
                    <div>
                      <div style={{ display: 'inline-block' }}>
                        <div style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          marginRight: 10,
                          marginLeft: 30,
                          background: '#0ED1AD',
                          width: 16,
                          height: 16
                        }}></div>
                        <span style={{ color: '' }}>
                          {t('work.more75')}
                        </span>
                      </div>
                      <div style={{ display: 'inline-block' }}>
                        <div style={{
                          display: 'inline-block',
                          verticalAlign: 'middle',
                          marginRight: 10,
                          marginLeft: 30,
                          background: 'red',
                          width: 16,
                          height: 16
                        }}></div>
                        <span style={{ color: '' }}>
                          {t('work.redText')}
                        </span>
                      </div>
                    </div>
                    <Divider className='chart-line' style={{ top: 75 }}>
                    </Divider>
                    <Column
                      style={{
                        height: 200
                      }}
                      {...dataConfig} />
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <h3>
                  {t('work.totalRate')} {total}%
                </h3>
              </Row>
              <Row style={{ marginTop: 3 }}>
                <h3>
                  {t('work.WhatisComplianceRate')}
                </h3>
                <p style={{ marginBottom: 3 }}>
                  {t('work.WhatisComplianceRateText1')}
                </p>
                <p style={{ marginBottom: 3 }}>
                  {t('work.WhatisComplianceRateText2')}
                </p>
                <p style={{ marginBottom: 3 }}>
                  {t('work.WhatisComplianceRateText3')}
                </p>
                <p>
                  {t('work.WhatisComplianceRateText4')}
                </p>
              </Row>
              <Row>
                <h3>
                  {t('work.Whycompliancerateissoimportant')}
                </h3>
                <p>
                  {t('work.WhycompliancerateissoimportantText')}
                </p>
              </Row>
              <footer>
                <p style={{ marginBottom: 0 }}>
                  Eyerising International Pty Ltd: Suite 2.05, 9-11 Claremont St. South Yarra, Victoria 3141, Australia
                </p>
                <p>
                  ABN: 98 641 925 206   |   www.eyerisinginternational.com     |     mailto: info@eyerisinginternational.com
                </p>
              </footer>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Report
