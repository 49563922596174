import { useState, useEffect } from 'react'
import { Form, Tabs, Input, Modal, Button, message, Row, Select, Col, DatePicker, InputNumber } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import _ from 'lodash'
import moment from 'moment'
import {
  addDeviceAsync,
} from '../../reducers/devices/devicesSlice'
import {
  getAllCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import {
  getAllReleasesAsync,
} from '../../reducers/versions/versionsSlice'
import {
  getAllCatalogsAsync
} from '../../reducers/catalogs/catalogsSlice'
import JsBarcode from 'jsbarcode'
import './Common.scss'
import { useTranslation } from 'react-i18next';
import DeviceUsage from './DeviceUsage'

interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}

const deviceTypes = ['TESTING', 'DEMO', 'CLINICAL', 'COMMERCIAL']
const { Option } = Select;

const DeviceModal = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [releases, setReleases] = useState([])
  const [form] = Form.useForm()
  const [companies, setCompanies] = useState([])
  const [products, setProducts] = useState([])
  const { t, i18n } = useTranslation()
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }
  const fetchAllProducts = async () => {
    setLoading(true)
    const result = await dispatch(getAllCatalogsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setProducts(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const fetchAllVersions = async () => {
    setLoading(true)
    const result = await dispatch(getAllReleasesAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setReleases(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const fetchList = async () => {
    setLoading(true)
    const result = await dispatch(getAllCompaniesAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCompanies(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const onFinish = async () => {
    form.validateFields()
      .then(async values => {
        values.id = props.record._id
        values.createdDate = values.createdDate.startOf('day').toDate()
        const result = await dispatch(addDeviceAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            message.success('Successful operation');
            closeModal()
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  useEffect(() => {
    form.resetFields()
    if (props.record._id) {
      let record = props.record
      record.createdDate = moment(record.createdDate)
      record.serviceDate = record.serviceDate ? moment(record.serviceDate) : null
      record.discardDate = record.discardDate ? moment(record.discardDate) : null
      form.setFieldsValue(record)
      try {
        JsBarcode("#barcode_product", `(1)${record.udi || ''}(21)${record.serial_number || ''}(11)${moment(record.createdDate).format('YYMMDD')}`);
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        JsBarcode("#barcode_product", '(1)(21)(11)');
      } catch (err) {
      }
    }
    fetchAllVersions()
    fetchAllProducts()
    fetchList()
    // or with jQuery
  }, [props.record._id, props.visible])
  const changeBarcode = () => {
    try {
      JsBarcode("#barcode_product", `(1)${form.getFieldValue('udi') || ''}(21)${form.getFieldValue('serial_number' || '')}(11)${moment(form.getFieldValue('createdDate')).format('YYMMDD') || ''}`);
    } catch (err) {
      console.log(err)
    }
  }
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const items = [
    {
      key: '1',
      label: 'Details',
      children: <>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="productId"
              label={t('work.Product')}
              rules={[{ required: true, message: 'Please select Product!' }]}
            >
              <Select placeholder="" showSearch optionFilterProp="label">
                {
                  products.map((co: any) => {
                    return (
                      <Option key={co._id} label={co.reference} value={co._id}>{co.reference}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="type"
              label={t('work.Type')}
              rules={[{ required: true, message: 'Please select Type!' }]}
            >
              <Select placeholder="" showSearch optionFilterProp="label">
                {
                  deviceTypes.map((t: string) => {
                    return (
                      <Option key={t} label={t} value={t}>{t}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label={t('work.Create_Date')} rules={[{ required: true, message: t('work.ManufacturingRequired') }]} name="createdDate"
            >
              <DatePicker onChange={(value: any) => {
                if (value) {
                  let nextDate = _.cloneDeep(value)
                  let discardDate = _.cloneDeep(value)
                  nextDate = moment(nextDate)
                  form.setFieldValue('serviceDate', nextDate.add(2, 'years'))
                  form.setFieldValue('discardDate', discardDate.add(5, 'years'))
                  changeBarcode()
                }
              }} />
            </Form.Item>
            <Form.Item label={t('work.serviceDate')} name="serviceDate" >
              <DatePicker />
            </Form.Item>
            <Form.Item label={t('work.discardDate')} name="discardDate" >
              <DatePicker />
            </Form.Item>
            <Form.Item name="serial_number" label={t('work.Serial_Number')} rules={[{ required: true, message: t('work.serialNumberRequired') }]}>
              <Input onChange={changeBarcode} placeholder="" />
            </Form.Item>
            <Form.Item name="qrcode" label={t('work.QR_Code')} rules={[{ required: true, message: t('work.QRcodeRequired') }]}>
              <Input placeholder="" />
            </Form.Item>
            <Form.Item name="comments" label={t('work.Comments')}>
              <Input placeholder="" />
            </Form.Item>
            <img style={{ width: '100%' }} id="barcode_product" className='barcode'></img>
          </Col>
          <Col span={12}>
            <Form.Item name="order_number" label={t('work.Order_Number')} rules={[{ required: true, message: t('work.ONRequired') },
            ]}>
              <Input placeholder="" />
            </Form.Item>
            <Form.Item name="udi" label={t('work.UDI_Number')} rules={[{ required: true, message: t('work.UDIRequired') },
            ]}>
              <Input onChange={changeBarcode} placeholder="" />
            </Form.Item>
            <Form.Item
              name="versionId"
              label={t('work.Version')}
            >
              <Select placeholder="" showSearch optionFilterProp="label">
                <Option key='' label='None' value=''>None</Option>
                {
                  releases.map((co: any) => {
                    return (
                      <Option key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="companyId"
              label={t("work.Company")}
              rules={[{ required: true, message: t('work.Please_select_company') }]}
            >
              <Select placeholder="" showSearch optionFilterProp="label">

                {
                  companies.map((co: any) => {
                    return (
                      <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="ownership"
              label={t("work.Ownership")}
            >
              <Select placeholder="" showSearch optionFilterProp="label">

                {
                  companies.map((co: any) => {
                    return (
                      <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item name="duration"
              label={t('work.Duration')}
              rules={[{
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
              }]}
            >
              <InputNumber placeholder="" />
            </Form.Item>
            <Form.Item name="intervals"
              label={t('work.Intervals')}
              rules={[{
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
              }]}
            >
              <InputNumber placeholder="" />
            </Form.Item>
            <Form.Item name="timesPerDay"
              label={t('work.TimesperDay')}
              rules={[{
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
              }]}
            >
              <InputNumber placeholder="" />
            </Form.Item>
            <Form.Item name="timesPerWeek"
              label={t('work.TimesperWeek')}
              rules={[{
                pattern: new RegExp(/^(([1-9]\d*)|0)(\.\d{0,2}?)?$/, "g"),
                message: 'Must >= 0'
              }]}
            >
              <InputNumber placeholder="" />
            </Form.Item>
          </Col>
        </Row>
      </>,
    },
    {
      key: '2',
      label: 'Usage',
      children: <>
        <DeviceUsage record={props.record} />
      </>,
    }
  ]


  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width='1200px'
          title={props.record._id ? t('work.Edit_product_device') : t('work.Create_product_device')}
          onCancel={closeModal}
          footer={[
            <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
              {t('work.Save')}
            </Button>,
            <Button key="back" onClick={closeModal}>
              {t('work.Cancel')}
            </Button>
          ]}
        >
          <Tabs defaultActiveKey="1" items={items} type="card" />
        </Modal>
      </Form>
    </>
  );
}

export default DeviceModal
