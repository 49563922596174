import { useState, useEffect } from 'react'
import { Form, Input, Modal, Button, message, Row, Select, Col, Table, DatePicker } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import moment from 'moment'
import {
  updateDeviceAsync
} from '../../reducers/devices/devicesSlice'
import {
  getAllCatalogsAsync
} from '../../reducers/catalogs/catalogsSlice'
import {
  getAllCompaniesAsync
} from '../../reducers/companies/companiesSlice'
import {
  getAllUsersAsync
} from '../../reducers/users/usersSlice'
import JsBarcode from 'jsbarcode'
import { useTranslation } from 'react-i18next';
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const { Option } = Select;
const InventoryModal = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [companies, setCompanies] = useState([])
  const [products, setProducts] = useState([])
  const { t, i18n } = useTranslation()
  const [customers, setCustomers] = useState([])
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }

  const fetchCustomersList = async() => {
    setLoading(true)
    let obj = {
      companyId: form.getFieldValue('companyId')
    }
    const result = await dispatch(getAllUsersAsync(obj))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCustomers(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  const fetchList = async() => {
    setLoading(true)
    const result = await dispatch(getAllCompaniesAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setCompanies(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const fetchAllProducts = async() => {
    setLoading(true)
    const result = await dispatch(getAllCatalogsAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setProducts(data.list)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const onFinish = async() => {
    form.validateFields()
      .then(async values => {
        values.id = props.record._id
        const result = await dispatch(updateDeviceAsync(values))
        setLoading(false)
        const { payload } = result
        if (payload) {
          const { code, msg } = payload
          if (code === 0) {
            message.success('Successful operation');
            closeModal()
          } else if (msg) {
            Modal.error({
              title: 'Error',
              content: msg,
            });
          }
        }
        if (typeof payload === 'string') {
          Modal.error({
            title: 'Error',
            content: payload
          })
        }
        /*
      */
      })
      .catch(errorInfo => {
      });
  }
  useEffect(() => {
    const record = props.record
    form.resetFields()
    if (props.record._id) {
      record.createdDate = moment(record.createdDate)
      record.serviceDate = record.serviceDate ? moment(record.serviceDate) : null
      record.discardDate = record.discardDate ? moment(record.discardDate) : null
      form.setFieldsValue(record)
      try {
        JsBarcode("#barcode", `(1)${record.udi || ''}(21)${record.serial_number || ''}(11)${moment(record.createdDate).format('YYMMDD')}`);
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        JsBarcode("#barcode_product", '(1)(21)(11)');
      } catch (err) {
      }
    }
    fetchCustomersList()
    fetchAllProducts()
    fetchList()
  }, [props.record._id, props.visible])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const columns = [
    {
      title: t('work.Date'),
      dataIndex: 'date',
      width: '200px',
      render: (updatedAt: string) => (
        <>
         <span>{moment(updatedAt).format('DD/MM/YYYY HH:mm')}</span>
        </>
      ),
    },
    {
      title: t('work.Action'),
      dataIndex: 'action',
      width: '100px',
    },
    {
      title: t('work.Logging'),
      dataIndex: 'logging',
      width: '400px',
    },
    {
      title: t('work.Notes'),
      dataIndex: 'notes',
      width: '400px',
    },
  ];
  return (
    <>
      <Form {...layout} form={form} name="control-hooks">
        <Modal
          className="add-user-modal"
          visible={props.visible}
          width='1200px'
          title={props.record._id ? `Inventory: view ` :"Inventory: view"}
          onCancel={closeModal}
          footer={[
            // <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
            //   Save
            // </Button>,
            <Button key="back" onClick={closeModal}>
              {t("work.Cancel")}
            </Button>
          ]}
        >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="productId"
              label={t('work.Product')}
            >
              <Select placeholder="" disabled>

                {
                  products.map((co: any) => {
                    return (
                      <Option key={co._id} value={co._id}>{co.reference}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item name="qrcode" label={t('work.QR_Code')}>
              <Input placeholder="" disabled />
            </Form.Item>
            <Form.Item name="udi" label={t('work.UDI_Number')}>
              <Input placeholder="" disabled />
            </Form.Item>
            <Form.Item name="serial_number" label={t('work.Serial_Number')}>
              <Input placeholder="" disabled />
            </Form.Item>
            <Form.Item name="status"
              label={t('work.Status')}>
              <Input placeholder="" disabled />
            </Form.Item>
            <img style={{width: '100%'}} id="barcode" className='barcode'></img>
          </Col>
          <Col span={12}>
            <Form.Item label={t('work.Create_Date')} name="createdDate">
              <DatePicker disabled />
            </Form.Item>
            <Form.Item label={t('work.serviceDate')} name="serviceDate">
              <DatePicker disabled />
            </Form.Item>
            <Form.Item label={t('work.discardDate')} name="discardDate">
              <DatePicker disabled/>
            </Form.Item>
            <Form.Item
              name="customerId"
              label={t('work.Customer')}
            >
              <Select placeholder="" disabled>

                {
                  customers.map((co: any) => {
                    return (
                      <Option key={co._id} value={co._id}>{co.firstName} {co.middleName} {co.lastName}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="companyId"
              label={t('work.Company')}
            >
              <Select placeholder="" disabled>

                {
                  companies.map((co: any) => {
                    return (
                      <Option disabled={co.disabled}  key={co._id} value={co._id}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="toCompanyId"
              label={t('work.Tocompany')}
            >
              <Select placeholder="" disabled>

                {
                  companies.map((co: any) => {
                    return (
                      <Option disabled={co.disabled} key={co._id} value={co._id}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{width: '100%'}}>

          <Table
            bordered
            columns={columns}
            showSorterTooltip={false}
            rowKey={(record: any) => record._id}
            dataSource={props.record.logs}
            loading={loading}
            pagination={false}
          />
        </Row>
        </Modal>
      </Form>
    </>
  );
}

export default InventoryModal
