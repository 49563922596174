import { Form, Input, Button, Row, Select, Table, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { useAppDispatch } from '../../app/hooks'

import {
  getRecordsAsync,
} from '../../reducers/portal/portalSlice'
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  setRecord: Function;
  getList: Function;
}
const { Option } = Select;
const Usage = (props: propsObject) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const closeModal = () => {
    props.setVisible(false)
    props.getList()
  }
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)
  const [records, setRecords] = useState([])
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const fetchList = async (params: any) => {
    setLoading(true)
    params = params || pagination
    !params.order && delete params.order
    !params.sort && delete params.sort
    const result = await dispatch(getRecordsAsync({
      accountId: props.record._id,
      ...params
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setRecords(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  useEffect(() => {
    if (props.record._id) {
      fetchList(pagination)
    }
  }, [props.record._id])

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }

  const columns = [
    {
      title: t('work.Product'),
      dataIndex: 'productName',
      width: '200px'
    },

    {
      title: t('work.Timezone'),
      dataIndex: 'timezone',
      width: '200px',
    },

    {
      title: t('work.Date'),
      sorter: true,
      dataIndex: 'createdAt',
      width: '200px',
      render: (createdAt: string, record: any) => (
        <>
          <span>{moment(createdAt).tz(record.timezone).format('DD/MM/YYYY')}</span>
        </>
      ),
    },
    {
      title: t('work.StartTime'),
      dataIndex: 'happenedTime',
      width: '200px',
      render: (happenedTime: string, record: any) => (
        <>
          <span>{moment(happenedTime).tz(record.timezone).format('HH:mm')}</span>
        </>
      ),
    }
  ];
  return (

    <Modal
      className="orders-modal"
      visible={props.visible}
      width='900px'
      title={props.record._id ? t('work.Usage') : t('work.Usage')}
      onCancel={closeModal}
      footer={
        [
          <Button key="back" onClick={closeModal}>
            {t("work.Cancel")}
          </Button>
        ]
      }
    >
      <Row style={{ width: '100%', marginBottom: 50 }}>

        <Table
          bordered
          columns={columns}
          showSorterTooltip={false}
          rowKey={(record: any) => record._id}
          dataSource={records}
          loading={loading}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          onChange={handleTableChange}
        />
      </Row>
    </Modal>
  );
}

export default Usage
