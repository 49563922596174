// A mock function to mimic making an async request for data
import api from '../../utils/api'

export interface userPayload {
  name: string;
  password: string;
}

export function addUser(payload: userPayload) {
  return api('/api/admin/users', 'POST', payload)
}

export function deleteUser(payload: userPayload) {
  return api('/api/admin/users', 'DELETE', payload)
}

export function deleteCustomer(payload: userPayload) {
  return api('/api/admin/customers', 'DELETE', payload)
}
export function lockCustomer(payload: userPayload) {
  return api('/api/admin/customers/lock', 'PUT', payload)
}

export function unlockCustomer(payload: userPayload) {
  return api('/api/admin/customers/unlock', 'PUT', payload)
}
export function debugCustomer(payload: userPayload) {
  return api('/api/admin/customers/debug', 'PUT', payload)
}
export function closeRquest (payload: userPayload) {
  return api('/api/admin/customers/requesting/close', 'PUT', payload)
}

export function sendStaffEmail (payload: any) {
  return api('/api/admin/device/reasons', 'post', payload)
}

export function closeDebugCustomer(payload: userPayload) {
  return api('/api/admin/customers/closedebug', 'PUT', payload)
}
export function getUsers(payload: any) {
  return api('/api/admin/users', 'GET', payload)
}
export function exportUsers (payload: any) {
  return api('/api/admin/users/export', 'GET', payload)
}
export function exportCustomers (payload: any) {
  return api('/api/admin/customers/export', 'GET', payload)
}
export function getAllUsers(payload: any) {
  return api('/api/admin/users/all', 'GET', payload)
}

export function getAllDoctors(payload: any) {
  return api('/api/admin/doctors', 'GET', payload)
}

export function getCustomers(payload: any) {
  return api('/api/admin/customers', 'GET', payload)
}


export interface signupPayload {
  fullName: string;
  email: string;
  password: string;
  companyId: string;
  'g-recaptcha-respons': string;
  phone: string;
}

export function signup(payload: signupPayload) {
  return api('/api/portal/users', 'POST', payload)
}


export interface meetingPayload {
  fullName: string;
  email: string;
  eventAt: string;
  userId: string;
  invite: Boolean;
  mobile?: string;
}

export function createMeeting(payload: meetingPayload) {
  return api('/api/meetings', 'POST', payload)
}

export interface idPayload {
  id: string;
}


export function getUserBaseInfo(payload: idPayload) {
  return api('/api/user/info', 'GET', payload)
}

export function getUserToken(payload: idPayload) {
  return api('/api/admin/user/token', 'GET', payload)
}
export function sentUserEmail(payload: idPayload) {
  return api('/api/admin/user/installation/email', 'POST', payload)
}
export interface passwordPayload {
  newPassword: string;
  oldPassord: string
}
export function updateProfile(payload: any) {
  return api('/api/user/profile', 'PUT', payload)
}
export function forgotPassword(payload: any) {
  return api('/api/user/forgotpassword', 'POST', payload)
}
export function setForgotPassword(payload: any) {
  return api('/api/user/forgotpassword', 'PUT', payload)
}