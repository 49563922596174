import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Modal, Result } from 'antd'
import { useAppDispatch } from '../app/hooks'
import { useTranslation } from 'react-i18next';
import {
  forgotPasswordAsync
} from '../reducers/users/usersSlice';
import ReCAPTCHA from "react-google-recaptcha";

import { accountPayload } from '../reducers/account/accountAPI'
import { useState, useEffect, useRef } from 'react';
const SignIn = () => {
  let siteKey = '6LcxbsIdAAAAAAzKDEM6QpIVAC1RzCUY9ahYeqoo'
  const dispatch = useAppDispatch();
  const history = useHistory()

  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const recaptchaRef = useRef<any>()
  const onFinish = async (values: accountPayload) => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    values['g-recaptcha-response'] = recaptchaValue
    setLoading(true)
    const result = await dispatch(forgotPasswordAsync({
      ...values,
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { data, code, msg } = payload
      if (code === 0) {
        setSent(true)
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
  }, [])
  return (
    <div style={{ margin: '100px auto 0'}}>

    {
      !sent ?
    <Form
      className="signin-form"
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
     <div className="logo-box">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>
            {
              t('work.Forgot_password')
            }
        </h1>
     </div>
      <Form.Item
        className="sign-input"
        label={t('work.Email')}
        name="email"
        rules={[{ required: true, message: t('sign.Please_input_your_email') }, {
          type: 'email', message: t('work.EmailType')
        }]}
      >
        <Input />
      </Form.Item>
      {
        process.env.NODE_ENV  === 'production' && !loading ? (
          <Form.Item
            className="sign-input sign-captch"
            label="Captcha"
            // name="captcha"
            rules={[{ required: true, message: 'Please input captcha!' }]}
          >
            <ReCAPTCHA
              sitekey={siteKey}
              ref={recaptchaRef}
            />
          </Form.Item>): ''
      }

      <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
        <Button style={{width: '100%', height: '50px'}} loading={loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form> :
      <Result
        status="success"
        title={t('work.MailSentText')}
        subTitle={
          [<span>
          </span>]
        }
        extra={[
        ]}
      />
    }
    </div>
  )
}

export default SignIn;
