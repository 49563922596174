import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
//引入需要实现国际化的简体、繁体、英文三种数据的json文件
import en from './locales/en.json'
import ms from './locales/ms.json'
import ja from './locales/ja.json'
import pl from './locales/pl.json'
import tr from './locales/tr.json'
import es from './locales/es.json'
import de from './locales/de.json'
import fr from './locales/fr.json'

const resources = {
  en: {
    translation: en
  },
  ms: {
    translation: ms
  },
  ja: {
    translation: ja
  },
  pl: {
    translation: pl
  },
  tr: {
    translation: tr
  },
  es: {
    translation: es
  },
  de: {
    translation: de
  },
  fr: {
    translation: fr
  }
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    }
  })

export default i18n