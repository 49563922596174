
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

const SignInRouter = () => {
  const history = useHistory()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (window.location.host.includes('back')) {
        history.push('/login/backend')
      }
      if (window.location.host.includes('portal')) {
        history.push('/login')
      }
    } else {
      history.push('/login/backend')
    }
  }, [])

  return (
    <>
    </>
  )
}

export default SignInRouter;