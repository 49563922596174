import { createSlice } from '@reduxjs/toolkit';
import { createApiAsyncThunk } from '../thunk'
import {
  getPlans,
  deletePlan,
  exportPlans,
  addPlan,
  getAllPlans
} from './plansAPI';
const initialState = {}

export const deletePlanAsync = createApiAsyncThunk<any>('plans/deletePlan', deletePlan)


export const addPlanAsync = createApiAsyncThunk<any>('plans/addPlan', addPlan)

export const getPlansAsync = createApiAsyncThunk<any>('plans/getPlans', getPlans)
export const exportPlansAsync = createApiAsyncThunk<any>('plans/exportPlans', exportPlans)

export const getAllPlansAsync = createApiAsyncThunk<any>('plans/getAllPlans', getAllPlans)


export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {}
})

export default plansSlice.reducer;
