import { Tabs } from 'antd';
import Catalogs from  './Catalogs'
import Devices from  './Devices'
import { useTranslation } from 'react-i18next';
const { TabPane } = Tabs;

const onChange = (key: string) => {
  console.log(key);
};

const Products = () => {
  const { t, i18n } = useTranslation()
  return (
  <Tabs onChange={onChange} type="card">
    <TabPane tab={t('work.Catalogs')} key="1">
      <Catalogs />
    </TabPane>
    <TabPane tab={t('work.Devices')} key="2">
      <Devices></Devices>
    </TabPane>
  </Tabs>
);}

export default Products;