
import logo from '../../assets/logo2.png'

import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { selectUser } from '../../reducers/account/accountSlice';
import { useTranslation } from 'react-i18next';

const AdminSider = () => {
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const { t, } = useTranslation();

  return (
    <Menu
      defaultSelectedKeys={[location.pathname]}
      mode="inline"
    >
      <Menu.Item disabled className="sider-title" key="title" style={{
        cursor: 'auto'
      }}>
        <img style={{ maxHeight: '35px' }} src={logo} alt="" />
      </Menu.Item>
      {
        user.type === 'normal' && user.userType === 'doctor' ? <>
          <Menu.Item key="/work/customers" ><Link to="/work/customers"> {t("work.Patients")}</Link></Menu.Item>
        </> : <>
          <Menu.Item key="/work" ><Link to="/work"> {t("work.Dashboard")}</Link></Menu.Item>
          <Menu.Item key="/work/companies" ><Link to="/work/companies"> {t("work.Companies")}</Link></Menu.Item>
          <Menu.Item key="/work/users" ><Link to="/work/users"> {t("work.Users")}</Link></Menu.Item>
          {
            user.type === 'admin' ?
              <Menu.Item key="/work/products" ><Link to="/work/products"> {t("work.Products")}</Link></Menu.Item>
              : ''
          }
          <Menu.Item key="/work/plans" ><Link to="/work/plans"> {t("work.Plans")}</Link></Menu.Item>
          <Menu.Item key="/work/customers" ><Link to="/work/customers"> {t("work.Patients")}</Link></Menu.Item>
          <Menu.Item disabled={!user.useInventory} key="/work/inventory" >
            {user.useInventory ?
              <Link to="/work/inventory"> {t("work.Inventory")}</Link> : <span>{t("work.Inventory")}</span>}
          </Menu.Item>
          <Menu.Item key="/work/payments" ><Link to="/work/payments"> {t("work.Payments")}</Link></Menu.Item>
        </>
      }
    </Menu>
  )
}

export default AdminSider;