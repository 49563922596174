import './SignUp.scss'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Modal, Layout, Select, Checkbox, message } from 'antd'
import { useAppDispatch } from '../../../app/hooks'

import { signupAsync } from '../../../reducers/users/usersSlice';
import config from '../../../config/config'

import ReCAPTCHA from "react-google-recaptcha";
import md5 from 'js-md5'
import mobileCountryCodes from '../../../config/mobileCountryCodes'
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../../utils/query';

const { Sider, Content } = Layout
const { Option } = Select
const siteKey = '6LcxbsIdAAAAAAzKDEM6QpIVAC1RzCUY9ahYeqoo'
const SignUp = () => {
  const dispatch = useAppDispatch();
  const recaptchaRef = useRef<any>()
  const history = useHistory()
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false)
  const query: any = useQuery()
  const [mobileCountryCode, setMobileCountryCode] = useState(query.mcc || '81')
  const selectBefore = (
    <Select value={mobileCountryCode} onChange={(value: string) => setMobileCountryCode(value)} className="select-before">
      {
        mobileCountryCodes.map((code: any) =>
          <Option key={code.code} value={code.code}>{code.code}</Option>)
      }
    </Select>
  );
  const onFinish = async (values: any) => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    values.password = md5(values.password)
    values.companyId = query.id
    if (!values.agree) {
      return message.error(t('work.PolicyText'))
    }
    values.mobileCountryCode = mobileCountryCode
    values['g-recaptcha-response'] = recaptchaValue
    setLoading(true)
    const result = await dispatch(signupAsync({
      ...values
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { data, code, msg } = payload
      if (code === 0) {
        const state = {
          email: values.email,
          fullName: values.fullName,
          phone: values.phone,
          id: data.id
        }

        history.push({
          pathname: '/signup/result',
          state
        }
        )
      } else if (msg) {
        if (code === 4010005) {
          Modal.error({
            title: 'The link is invalid, please contact the administrator'
          })
        } else {
          Modal.error({
            title: 'Error',
            content: msg,
          });
        }
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Layout className='sign-layout'>
      <Sider className='sign-sider' width={"40%"}>
        <div className="AuthLeftBlock_blue__g24c9">
          <div>
            <h2 className="AuthLeftBlock_h2__38SEX">{t('signup.Title')}</h2>
          </div>
        </div>
      </Sider>
      <Content className='sign-content'>
        <Form
          className="sign-form"
          name="basic"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="mobile-location">
            <div>
              <h2 className="AuthLeftBlock_h2__38SEX">{t('signup.Title')}</h2>
            </div>
          </div>
          <Form.Item name="firstName"
            className="sign-input" label={t('signup.firstName')} rules={[{ required: true, message: t('work.FirstNameRequiredText') },
            ]}>
            <Input placeholder="" />
          </Form.Item>
          <Form.Item name="middleName"
            className="sign-input" label={t('signup.middleName')}>
            <Input placeholder="" />
          </Form.Item>
          <Form.Item name="lastName"
            className="sign-input" label={t('signup.lastName')} rules={[{ required: true, message: t('work.LastNameRequiredText') }]}>
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            className="sign-input"
            label={t('signup.email')}
            name="email"
            rules={[{ required: true, message: t('signup.Please_input_your_email') }, {
              type: 'email', message: t('work.EmailType')
            }]}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Form.Item
            className="sign-input"
            label={t('signup.Confirm_email')}
            name="confirmEmail"
            rules={[{ required: true, message: t('signup.Please_input_your_email') }, {
              type: 'email', message: t('work.EmailType')
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`${t('signup.twoEmailTsext')}`));
              },
            }),]}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Form.Item
            className="sign-input"
            label={t('signup.Phone')}
            rules={[{ required: true, message: t('signup.Please_input_your_phone') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value.length >= 8) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('work.phoneLengthText')));
              },
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                var reg = new RegExp(/^\d+$/);
                if (reg.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('work.phoneNumberText')));
              },
            })]}
            name="phone"
          >
            <Input placeholder="" addonBefore={selectBefore} />
          </Form.Item>
          <Form.Item
            className="sign-input"
            label={t('signup.password')}
            name="password"
            rules={[{ required: true, message: t('signup.Please_input_your_password') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && value.length >= 8) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('work.passwordlengthText')));
              },
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
                if (reg.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('work.passwordText')));
              },
            })]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t('signup.Confirm_password')}
            className="sign-input"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: t('signup.Please_input_your_password')

              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`${t('signup.twoPasswordText')}`));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label=''
            name="agree"
            valuePropName="checked"
            rules={[{ required: true, message: t('work.PolicyText') }]}
          >
            <Checkbox>
              {t('work.Readandagree')}  <a href={`${config.domain}/static/${i18n.language.slice(0, 2)}/PrivacyPolicy.pdf`} target="view_window">
                {t('work.PrivacyPolicy')}
              </a></Checkbox>
          </Form.Item>
          {
            process.env.NODE_ENV === 'production' && !loading ? (
              <Form.Item
                className="sign-input sign-captch"
                label="Captcha"
                // name="captcha"
                rules={[{ required: true, message: t('sign.Please_input_captcha') }]}
              >
                <ReCAPTCHA
                  sitekey={siteKey}
                  ref={recaptchaRef}
                />
              </Form.Item>) : ''
          }
          <Form.Item
            className='sign-submit'
            label="submit">
            <Button block loading={loading} type="primary" htmlType="submit">
              {t('signup.done')}
            </Button>
          </Form.Item>
        </Form></Content>
    </Layout>

  )
}

export default SignUp;
