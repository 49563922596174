import { useState, useEffect } from "react";
import { Form, Input, Button, Row, Select, Col, Radio, Modal, Checkbox, message, Popconfirm, Divider } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { createPaymentAsync, getPaymethodsAsync, getPlansAsync, turnOffRenewAsync } from "../../reducers/portal/portalSlice";
import Airwallex from "../Components/Airwallex/Airwallex";

import config from "../../config/config";
import { selectUser, selectAccount } from "../../reducers/account/accountSlice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { createPaymentAdminAsync, getPaymethodsAdminAsync } from "../../reducers/payments/paymentsSlice";
interface propsObject {
  visible: boolean;
  setVisible: Function;
  record: any;
  updateItem?: any;
  plans?: any;
  db?: string;
}
const { Option } = Select;

const ChargeModal = (props: propsObject) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const account = useAppSelector(selectAccount);
  const [plans, setPlans] = useState([]);
  const [payType, setPayType] = useState("");
  const [payMethod, setPayMethod] = useState("card");
  const [plan, setPlan] = useState([] as any);
  const [card, setCard] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [airwallexVisible, setAirwallexVisible] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [currency, setCurrency] = useState("");
  const [intentId, setItentId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [autoRenew, setAutoRenew] = useState("");
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const closeModal = () => {
    props.setVisible(false);
  };

  const handlePlanChange = (planId: string) => {
    plans.map((p: any) => {
      if (p._id === planId && p.paymentGateway === true) {
        setPlan(p);
        form.setFieldValue("agree", false);
        form.setFieldValue("details", p.details);
        form.setFieldValue("price", `${p.currency.toUpperCase()} ${p.price} per ${p.valid_months} month${p.valid_months > 1 ? "s" : ""}`);
        if (p.renew_end_months || p.deposit || p.experience_months || p.experience_days) {
          form.setFieldValue("auto_renew", "Yes");
          setAutoRenew("Yes");
        } else {
          form.setFieldValue("auto_renew", "");
          setAutoRenew("");
        }
      } else {
        setPlan(plans.find((p: any) => p._id === planId));
      }
    });
  };

  const deleteItem = async () => {
    setLoading(true);
    const result = await dispatch(
      turnOffRenewAsync({
        accountId: props.record._id,
      })
    );
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const requestAirwallex = async (values: any) => {
    values.accountId = props.record._id;

    if (!values.terms && plan.termsUrl) {
      return message.error(t("work.ConditionsText"));
    }
    if (!values.agree && plan.guaranteeUrl && !props.record.paid && (plan.experience_days || plan.experience_months)) {
      return message.error(t("work.MoneyBackGuaranteeText"));
    }

    setLoading(true);
    values.auto_renew = values.auto_renew || autoRenew;
    values.method = payMethod;

    let result;
    if (user.type !== "customer") {
      values.db = props.db;
      result = await dispatch(createPaymentAdminAsync(values));
    } else {
      result = await dispatch(createPaymentAsync(values));
    }

    setLoading(false);
    const { payload } = result;

    if (payload) {
      const { code, msg, data } = payload;
      if (code === 0 && data.method === "card") {
        setAirwallexVisible(true);
        setCurrency(data.currency);
        setCustomerId(data.customerId);
        setItentId(data.intentId);
        setClientSecret(data.clientSecret);
      }

      if (code === 0 && data.method === "link") {
        message.success(t("work.LinkCreated"));
        closeModal();
      }

      if (code !== 0 && msg) {
        Modal.error({
          title: "Error",
          content: msg,
        });
      }
    }

    if (typeof payload === "string") {
      Modal.error({
        title: "Error",
        content: payload,
      });
    }
  };

  const ChangeCard = async () => {
    form
      .validateFields()
      .then(async (values) => {
        values.type = "change";
        setPayType("change");
        setTimeout(async () => {
          await requestAirwallex(values);
        }, 100);
      })
      .catch((errorInfo) => {});
  };

  const onFinish = async () => {
    // If the user is selecting a plan that DOESN'T REQUIRE using Airwallex
    if (plan.paymentGateway === false) {
      props.record.plan = plan;
      props.record.planId = plan._id;
      if (props.updateItem) {
        props.updateItem(props.record);
      }
      closeModal();
    } else {
      // If the user is selecting a plan that REQUIRES using Airwallex
      form
        .validateFields()
        .then(async (values) => {
          values.auto_renew = values.auto_renew ? values.auto_renew : "No";
          setPayType("normal");
          setTimeout(async () => {
            await requestAirwallex(values);
          }, 100);
        })
        .catch((errorInfo) => {});
    }
  };

  const fetchAllPlans = async () => {
    setLoading(true);
    const result = await dispatch(getPlansAsync({}));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        // filter out plans that are legacy then set plan
        setPlans(data.list.filter((plan: any) => plan.legacy !== true));
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const getPaymethods = async () => {
    setLoading(true);
    let result;
    if (user.type !== "customer") {
      result = await dispatch(
        getPaymethodsAdminAsync({
          accountId: props.record._id,
          db: props.db,
        })
      );
    } else {
      result = await dispatch(
        getPaymethodsAsync({
          accountId: props.record._id,
        })
      );
    }

    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setCard(data.card);
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      details: "",
      auto_renew: "",
      price: "",
    });
    if (props.record._id) {
      if (props.record.subscriptionStatus === "active") {
        getPaymethods();
      }
      form.setFieldsValue({
        auto_renew: props.record.auto_renew || "",
        planId: props.record.planId || undefined,
      });
      if (props.record.planId && props.record.plan && props.record.plan.paymentGateway === true) {
        form.setFieldValue("details", props.record.plan.details);
        form.setFieldValue(
          "price",
          `${props.record.plan.currency.toUpperCase()} ${props.record.plan.price} / ${props.record.plan.valid_months} months`
        );
        setPlan({
          _id: props.record.planId,
          currency: props.record.plan.currency,
          price: props.record.plan.price,
          deposit: props.record.plan.deposit,
        });
        form.setFieldValue("auto_renew", "Yes");
        setAutoRenew("Yes");
      }
      if (props.plans) {
        // filter out plans that are legacy then set plan
        setPlans(props.plans.filter((plan: any) => plan.legacy !== true));
        props.record.plan = props.plans.find((p: any) => p._id === props.record.planId);
      } else {
        fetchAllPlans();
      }
    }
  }, [props.record._id, props.visible]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const expiredDate = moment(props.record.expiredDate).startOf("day");
  const now = moment().startOf("day");

  return (
    <>
      <Modal
        className="add-user-modal"
        open={props.visible}
        width="900px"
        title={props.record._id ? t("work.Charge") : t("work.Charge")}
        onCancel={closeModal}
        footer={
          props.record.subscriptionStatus !== "active"
            ? [
                <Button htmlType="submit" key="submit" loading={loading} type="primary" onClick={onFinish}>
                  {t("work.Submit")}
                </Button>,
                <Button key="back" onClick={closeModal}>
                  {t("work.Cancel")}
                </Button>,
              ]
            : [
                <Button key="back2" onClick={closeModal}>
                  {t("work.Cancel")}
                </Button>,
              ]
        }
      >
        <Form {...layout} form={form} name="control-hooks">
          <Row>
            <Col md={24} xs={24}>
              <Form.Item
                labelCol={{ span: i18n.language === "ja" ? 5.5 : 3 }}
                name="planId"
                label={t("work.YourPlan")}
                rules={[{ required: true, message: t("work.Please_select_plan") }]}
              >
                <Select
                  placeholder={t("work.SelectText")}
                  onChange={(v) => handlePlanChange(v)}
                  disabled={props.record.subscriptionStatus === "active"}
                >
                  {plans
                    .filter((co: any) => {
                      if (props.record.subscriptionStatus !== "active") {
                        if (props.record.paid) {
                          if (!co.experience_days && !co.experience_months) {
                            return co;
                          }
                        } else {
                          return co;
                        }
                      } else {
                        return co;
                      }
                      return false;
                    })
                    .map((co: any) => {
                      return (
                        <Option key={co._id} data={co} value={co._id}>
                          {co.name}{" "}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {plan && plan.paymentGateway ? (
            <>
              <Row gutter={20}>
                <Col md={12} xs={24}>
                  <div style={{ marginBottom: 10 }}>
                    <span>{t("work.Remaining")} </span>
                    <strong>
                      {!props.record.expiredDate || expiredDate.diff(now, "days") < 0 ? 0 : expiredDate.diff(now, "days")}
                      <span> </span>
                      {expiredDate.diff(now, "days") < 2 ? "day" : "days"}
                    </strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={24}>
                  {!props.record.experiencing && props.record.subscriptionStatus === "active" ? (
                    <Form.Item label={t("work.Auto_renew")} name="auto_renew">
                      <span>{props.record.subscriptionStatus}</span>
                      {props.record.renewHasEnd ? (
                        <Button disabled={props.record.renewHasEnd} style={{ marginLeft: 30 }} danger key="turnoff">
                          {t("work.Turnoffautorenew")}
                        </Button>
                      ) : (
                        <Popconfirm title={t("work.TurnoffText")} onConfirm={() => deleteItem()} okText="Yes" cancelText="No">
                          <Button disabled={props.record.renewHasEnd} style={{ marginLeft: 30 }} danger key="turnoff">
                            {t("work.Turnoffautorenew")}
                          </Button>
                        </Popconfirm>
                      )}
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  {card && props.record.subscriptionStatus === "active" ? (
                    <Form.Item label={t("work.CardNumber")} name="card_number">
                      <Input style={{ width: "calc(100% - 100px)" }} disabled defaultValue={`**** ${card.last4}`}></Input>
                      <Button type="primary" htmlType="submit" onClick={ChangeCard} style={{ float: "right" }}>
                        {t("work.Change")}
                      </Button>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  {card && props.record.subscriptionStatus === "active" ? (
                    <Form.Item label={t("work.Expiration")} name="Expiration">
                      <Input disabled defaultValue={`${card.expiry_month}/${card.expiry_year}`}></Input>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  <Form.Item label={t("work.Price")} name="price">
                    <Input disabled></Input>
                  </Form.Item>
                  <Form.Item label={t("work.PlanDetails")} name="details">
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 10 }} disabled></Input.TextArea>
                  </Form.Item>
                  {props.record.subscriptionStatus !== "active" && plan.guaranteeUrl ? (
                    <Form.Item label="" name="agree" valuePropName="checked" rules={[{ required: true, message: t("work.MoneyBackGuaranteeText") }]}>
                      <Checkbox>
                        {t("work.Readandagree")}{" "}
                        <a href={plan.guaranteeUrl} target="view_window">
                          {t("work.MoneyBackGuarantee")}
                        </a>
                      </Checkbox>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  {props.record.subscriptionStatus !== "active" && plan.termsUrl ? (
                    <Form.Item label="" name="terms" valuePropName="checked" rules={[{ required: true, message: t("work.ConditionsText") }]}>
                      <Checkbox>
                        {t("work.Readandagree")}{" "}
                        <a href={plan.termsUrl ? plan.termsUrl : `${config.domain}/static/TermsandConditions.pdf`} target="view_window">
                          {t("work.TermsAndConditions")}
                        </a>
                      </Checkbox>
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {props.record.subscriptionStatus !== "active" && (props.record.paid || (!plan.experience_days && !plan.experience_months)) ? (
                    <Form.Item
                      rules={[{ required: plan.paymentGateway, message: t("work.Please_autorenew") }]}
                      label={t("work.Auto_renew")}
                      name="auto_renew"
                    >
                      <Radio.Group disabled={plan.renew_end_months && plan.renew_end_months > 0} onChange={(e) => setAutoRenew(e.target.value)}>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
              {user.type !== "customer" && autoRenew === "No" ? (
                <Row>
                  <Col md={12}>
                    <Form.Item rules={[{ required: true, message: "Select payment method" }]} label={"Method"} name="method">
                      <Radio.Group onChange={(e) => setPayMethod(e.target.value)}>
                        <Radio value="card">Credit card details</Radio>
                        <Radio value="link">Payment link via e-mail</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              ) : null}
              <Row style={{ marginBottom: "10px", fontSize: "12px" }}>{t("work.ChargeWarning")}</Row>
            </>
          ) : null}
        </Form>
      </Modal>
      <Modal className="add-user-modal" open={airwallexVisible} onCancel={() => setAirwallexVisible(false)} width={550} footer={false}>
        <div id={clientSecret} key={clientSecret}>
          <Airwallex
            db={props.db ?? account.db}
            account={props.record}
            user={user}
            plan={plan}
            payType={payType}
            company={props.record.company}
            auto_renew={form.getFieldValue("auto_renew")}
            intentId={intentId}
            currency={currency}
            customerId={customerId}
            clientSecret={clientSecret}
          />
        </div>
      </Modal>
    </>
  );
};

export default ChargeModal;
