// A mock function to mimic making an async request for data
import api from '../../utils/api'
export function postAWSSignurl(payload: any) {
  return api('/api/admin/aws/signurl', 'POST', payload)
}

export function putAWSSignurl(payload: any) {
  return api('/api/admin/aws/signurl', 'PUT', payload)
}

export function userPostAWSSignurl(payload: any) {
  return api('/api/portal/aws/signurl', 'POST', payload)
}

export function userPutAWSSignurl(payload: any) {
  return api('/api/portal/aws/signurl', 'PUT', payload)
}
