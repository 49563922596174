import './Common.scss'
import { Form, Input, Button, Row, Select, Col, DatePicker, Switch, Divider, Modal, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next';
import { TimeZoneSelectDialog } from 'react-timezone-map-select';
import mobileCountryCodes from '../../config/mobileCountryCodes'
import config from '../../config/config';
import { useAppDispatch } from '../../app/hooks'
import AddressSelect from '../Components/AddressSelect'

import { useState } from 'react'
import _ from 'lodash'

import {
  bindDeviceByAdminAsync,
  unbindDeviceByAdminAsync
} from '../../reducers/customers/customersSlice'
interface propsObject {
  setVisible: Function;
  record: any;
  companies: any;
  form: any;
  setRecord: Function;
  getList: Function;
  resetPassword: boolean;
  handleClose: any;
  db: string;
  handleOpen: any;
  doctors: any;
  getDoctors: Function;
  timezoneName: string;
  mobileCountryCode: string,
  setMobileCountryCode: Function,
  setTimezoneName: Function,
  open: boolean;
  setCenter: Function;
  setAddress: Function;
  center: any;
  address: string;
  setResetPassword: Function
}
const { Option } = Select;

const PatientModalForm = (props: propsObject) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snr, setSNR] = useState('')
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false)
  const [directShippingRequired, setDirectShippingRequired] = useState(false)
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const onFinish = async () => {
    setLoading(true)
    if (!snr) {
      return Modal.error({
        title: 'Error',
        content: t('work.Input_Serial_Number'),
      });
    }
    const result = await dispatch(bindDeviceByAdminAsync({
      serial_number: snr,
      db: props.db,
      customerId: props.record._id,
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg, data } = payload
      if (code === 0) {
        setIsModalOpen(false);
        const record = _.cloneDeep(props.record)
        record.device = data.device
        props.setRecord(record)
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const unBind = async () => {
    setLoading(true)
    const result = await dispatch(unbindDeviceByAdminAsync({
      db: props.db,
      customerId: props.record._id,
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, msg, data } = payload
      if (code === 0) {
        const record = _.cloneDeep(props.record)
        delete record.device
        props.setRecord(record)
        setIsModalOpen(false);
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const selectBefore = (
    <Select value={props.mobileCountryCode} onChange={(value: string) => props.setMobileCountryCode(value)} className="select-before">
      {
        mobileCountryCodes.map((code: any) =>
          <Option key={code.code} value={code.code}>{code.code}</Option>)
      }
    </Select>
  );
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  return (
    <>
      <TimeZoneSelectDialog
        open={props.open}
        title={t('work.Timezone')}
        buttonLabelOk={t('work.Submit')}
        buttonLabelCancel={t('work.Cancel')}
        description={t('work.timezoneDescription')}
        timeZoneName={props.timezoneName}
        onClose={props.handleClose}
      />
      <Form {...layout} form={props.form} name="control-hooks">
        <Row gutter={20}>
          {
            props.record._id ?
              (props.record.device ?
                <Input value={props.record.device.serial_number} placeholder="" disabled
                  addonAfter={
                    <Popconfirm
                      title={t('work.Are_you_sure_to_unbind')}
                      onConfirm={() => unBind()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button style={{ border: 0, background: '#fafafa', color: '#333', boxShadow: 'none' }} size='small' type="primary" >
                        {t('work.Unbind')}
                      </Button>
                    </Popconfirm>}
                />
                :
                <div>
                  <Button style={{ float: 'left' }} size='small' type="primary" onClick={showModal}>
                    {t('work.Bind_a_device')}
                  </Button>
                  <Modal confirmLoading={loading} title="Bind device" open={isModalOpen} onOk={onFinish} onCancel={handleCancel}>
                    <Input onChange={(v: any) => setSNR(v.target.value)} placeholder={t('work.Device_Serial_Number')} />
                  </Modal>
                </div>
              )
              : null
          }
          <Col span={24}>
            <Divider orientation="left" >{t('work.ParenGuardian')}</Divider>
          </Col>
          <Col span={12}>
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item name="firstName" label={t('work.firstName')} rules={[{ required: true, message: t('work.FirstNameRequiredText') }]}>
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="middleName" label={t('work.middleName')}>
                  <Input placeholder="" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="lastName" label={t('work.lastName')} rules={[{ required: true, message: t('work.LastNameRequiredText') },]}>
                  <Input placeholder="" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label={t('work.Username_email')} name="email"
              rules={[{ required: true, message: t('signup.Please_input_your_email') },
              {
                type: 'email', message: t('work.EmailType')
              }
              ]}>
              <Input placeholder="" disabled={props.record._id} />
            </Form.Item>
            {
              props.record._id ?
                (props.resetPassword ?
                  <>
                    <Form.Item
                      name="password" label={t('work.Password')}
                      rules={[
                        {
                          required: true,
                          message: t('work.password_message'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value && value.length >= 8) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('work.passwordlengthText')));
                          },
                        }),
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            var reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
                            if (reg.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('work.passwordText')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label={t('work.Confirmpassword')}
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: t('work.confirm_message'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('work.The_two_passwords_message')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </> :
                  <>
                    <Form.Item name="password" label={t('work.Password')} >
                      <Button onClick={() => props.setResetPassword(true)}>{t('work.Reset_Password')}</Button>
                    </Form.Item>
                  </>)
                : ''

            }
            <Form.Item
              rules={[{ required: true, message: t('work.Country_Text') }]}
              name="country" label={t("work.Country")}  >
              <Select placeholder={t("work.Country")}
                onChange={(value) => {
                  config.countries.map((cou: any) => {
                    if (cou.key === value) {
                      console.log(cou)
                      props.setMobileCountryCode(cou.mobileCountryCode)
                      props.setTimezoneName(cou.timezone)
                    }
                  })
                }
                }
              >
                {config.countries.map((cou: any) =>
                  <Option key={cou.key} value={cou.key}>{cou.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item label={t('work.Timezone')} name="timezone">
              <Input.Group compact>
                <Input disabled style={{ width: 'calc(100% - 80px)' }} placeholder="" value={props.timezoneName} />
                <Button type="primary" onClick={props.handleOpen} style={{ float: "right" }} >
                  {t('work.Edit')}
                </Button>
              </Input.Group>
            </Form.Item>
            <Form.Item label={t('work.Phone')} name="phone"
              rules={[{ required: true, message: t('signup.Please_input_your_phone') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value.length >= 8) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('work.phoneLengthText')));
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  var reg = new RegExp(/^\d+$/);
                  if (reg.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('work.phoneNumberText')));
                },
              })]}>
              <Input placeholder="" addonBefore={selectBefore} />
            </Form.Item>
            <Form.Item
              name="companyId"
              label={`${t('work.Company')} (${t('work.DistributorClinic')})`}
              rules={[{ required: true, message: t('work.Please_select_company') }]}
            >
              <Select optionFilterProp="label" placeholder="" showSearch
                disabled={props.record._id}
                onChange={(value: string) => {
                  props.form.setFieldValue('doctorId', '')
                  props.getDoctors(value)
                }
                }
              >
                {
                  props.companies.map((co: any) => {
                    return (
                      <Option disabled={co.disabled} key={co._id} label={co.name} value={co._id}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            {
              props.record._id ?
                <Form.Item label={t('work.Register_Date')} name="registerDate">
                  <DatePicker disabled />
                </Form.Item> : ''
            }
          </Col>
          <Col span={12}>
            <Form.Item name="notes" label={t('work.Notes')}>
              <Input.TextArea showCount autoSize={{ minRows: 8 }} />
            </Form.Item>
            <Form.Item name="needRequired" label={t("work.DirectShippingRequired")} >
              <Switch checked={directShippingRequired} onChange={(value) => setDirectShippingRequired(value)} checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Form.Item name="zip"
              rules={[
                {
                  required: directShippingRequired, message: t('work.zipRequired')
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    var reg = new RegExp(/^\d+$/);
                    if (reg.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('work.zipText')));
                  },
                })]} label={t("work.Zip")} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Input placeholder={t("work.Zip")} />
            </Form.Item>
            <AddressSelect
              center={props.center}
              required={directShippingRequired}
              address={props.address}
              setCenter={props.setCenter}
              setAddress={props.setAddress}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default PatientModalForm
