import { Layout, Space, Button, Select, Table, Input, message, Row, Col, DatePicker, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, } from '../../app/hooks'
import { ExportOutlined } from '@ant-design/icons';

import moment from 'moment'
import { useTranslation } from 'react-i18next';


import { getBaseSettingAsync } from '../../reducers/setting/settingSlice'
import { getInvoiceReportAsync } from '../../reducers/invoiceReports/invoiceReportsSlice';
const { Content } = Layout;
const { Option } = Select;

interface MonthObject {
  name: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const createMonthObjects = (today: moment.Moment): MonthObject[] => {
  const monthObjects: MonthObject[] = [];
  const currentMonth = today.month()
  const currentYear = today.year()
  for (let i = 12; i >= 1; i--) {
    let previousMonth
    let previousYear
    if (currentMonth - (12 - i) >= 0) {
      previousMonth = currentMonth - (12 - i)
      previousYear = currentYear
    } else {
      previousMonth = currentMonth + i;
      previousYear = currentYear - 1
    }
    const monthObject: MonthObject = {
      name: `${moment().month(previousMonth).format('MMMM')} ${moment().year(previousYear).format('YYYY')}`,
      startDate: moment().month(previousMonth).year(previousYear).startOf('month'),
      endDate: moment().month(previousMonth).year(previousYear).endOf('month')
    };
    monthObjects.push(monthObject);
  }

  return monthObjects;
}

const InvoiceReports = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { t } = useTranslation()
  const [dbs, setDbs] = useState([])
  const [db, setDb] = useState('jp')
  const invoiceReportMonths = createMonthObjects(moment())
  const [invoiceReportMonth, setInvoiceReportMonth] = useState<MonthObject>(invoiceReportMonths[0])
  const [startDate, setStartDate] = useState<Date>(invoiceReportMonth.startDate.toDate())
  const [endDate, setEndDate] = useState<Date>(invoiceReportMonth.endDate.toDate())
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any)

  const convertArrayToCSV = (data: {}[]) => {
    // Create a header row with the names of the object keys.
    const headerRow = Object.keys(data[0]).join(',');

    // Create a CSV string by iterating over the array of objects and appending each row to the string.
    const csvString = [headerRow, ...data.map(object => Object.values(object).join(','))].join('\n');

    return csvString;
  }

  const downloadCSV = () => {
    if (data.length > 0) {
      const csvString = convertArrayToCSV(data);
      const blob = new Blob([csvString], { type: 'text/csv' });
      const objectURL = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = objectURL;
      anchor.download = `${db}-${invoiceReportMonth.name}.csv`.replace(' ', '-').toLowerCase();
      anchor.click();
      URL.revokeObjectURL(objectURL);
    }
  };


  useEffect(() => {
    fetchSetting()
    fetchList(pagination)
  }, [])

  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleDbChange = (value: string) => {
    setDb(value)
    fetchList({
      db: value,
      ...pagination,
      startDate,
      endDate,
    })
  }
  const handleMonthChange = (value: any) => {
    const ir = invoiceReportMonths[value]
    setInvoiceReportMonth(ir)
    setStartDate(ir.startDate.toDate())
    setEndDate(ir.endDate.toDate())
    fetchList({
      ...pagination,
      startDate: ir.startDate.toDate(),
      endDate: ir.endDate.toDate(),
    })
  }

  const fetchList = async (params: any, search?: string) => {
    setLoading(true)
    params = params || pagination
    params.db = params.db || db
    params.startDate = params.startDate || startDate
    params.endDate = params.endDate || endDate
    !params.order && delete params.order
    !params.sort && delete params.sort

    for (let key in params) {
      if (params[key] === 'NONE_STR') {
        params[key] = ''
      }
    }
    const result = await dispatch(getInvoiceReportAsync(params))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setData(data.list)
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total
        })
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field
    changePagination.order = sorter.order
    fetchList({ ...changePagination })
  }
  const columns = [
    {
      title: t('invoiceReport.CompanyName'),
      dataIndex: 'companyName',
      sorter: true,
      render: (companyName: string) => (
        <>
          <span>{companyName}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.ParentCompanyName'),
      dataIndex: 'parentCompanyName',
      sorter: true,
      render: (parentCompanyName: string) => (
        <>
          <span>{parentCompanyName}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.ClinicianName'),
      dataIndex: 'clinician',
      sorter: true,
      render: (clinician: string) => (
        <>
          <span>{clinician}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.DeviceSerialNumber'),
      dataIndex: 'deviceSerialNumber',
      render: (deviceSerialNumber: string) => (
        <>
          <span>{deviceSerialNumber}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.ParentName'),
      dataIndex: 'parentName',
      render: (parentName: boolean) => (
        <>
          <span>{parentName}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.ParentEmail'),
      dataIndex: 'parentEmail',
      render: (parentEmail: boolean) => (
        <>
          <span>{parentEmail}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.PatientName'),
      dataIndex: 'patientName',
      render: (patientName: boolean) => (
        <>
          <span>{patientName}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.PlanAssignedAt'),
      dataIndex: 'planAssignedAt',
      width: '200px',
      render: (planAssignedAt: string) => (
        <>
          <span>{planAssignedAt ? moment(planAssignedAt).format("DD-MM-YYYY") : null}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.PlanName'),
      dataIndex: 'planName',
      width: '200px',
      render: (planName: string) => (
        <>
          <span>{planName}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.PlanPrice'),
      dataIndex: 'planPrice',
      render: (planPrice: string) => (
        <>
          <span>{planPrice}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.PlanCurrency'),
      dataIndex: 'planCurrency',
      render: (planCurrency: string) => (
        <>
          <span>{planCurrency}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.Prepaid'),
      dataIndex: 'prepaid',
      render: (prepaid: string) => (
        <>
          <span>{prepaid ? 'YES' : 'NO'}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.Wholesale'),
      dataIndex: 'wholesale',
      render: (wholesale: string) => (
        <>
          <span>{wholesale ? 'YES' : 'NO'}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.Paid'),
      dataIndex: 'paid',
      render: (paid: string) => (
        <>
          <span>{paid ? 'YES' : 'NO'}</span>
        </>
      ),
    },
    {
      title: t('invoiceReport.PaidAt'),
      dataIndex: 'paidAt',
      render: (paidAt: string) => (
        <>
          <span>{paidAt ? moment(paidAt).format("DD-MM-YYYY") : null}</span>
        </>
      ),
    },
  ];

  return (
    <>
      <div className='eyerising-page-header'>
        <div className='header-content' >
          <Row justify="space-between">
            <Col span={10}>
              <Space>
                <Select defaultValue={db} style={{ width: 200 }} placeholder="" onChange={handleDbChange}>
                  {
                    dbs.map((co: any) => {
                      return (
                        <Option key={co.key} value={co.key}>{co.name}</Option>)
                    })
                  }
                </Select>
                <Select defaultValue={invoiceReportMonth.name} style={{ width: 200 }} placeholder="" onChange={handleMonthChange}>
                  {
                    invoiceReportMonths.map((ir: MonthObject, index: number) => {
                      return (
                        <Option key={index} value={index}>{ir.name}</Option>)
                    })
                  }
                </Select>
                <Button icon={<ExportOutlined />} onClick={() => downloadCSV()} key="3" type='primary' ghost>
                  {t('invoiceReport.ExportReport')}
                </Button>
              </Space>
            </Col>

          </Row>

        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={data}
          pagination={{
            position: ['bottomLeft'],
            ...pagination,
            showTotal: (total) => {
              return `${t('work.Total')}: ${total}`
            }
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
    </>
  )
}


export default InvoiceReports;