import { useHistory } from 'react-router-dom'
import { Form, Input, Button, Modal, Card, Select, message, Result } from 'antd'
import { useAppDispatch } from '../../app/hooks'
import { useTranslation } from 'react-i18next';
import {
  forgotPasswordAsync
} from '../../reducers/portal/portalSlice';
import ReCAPTCHA from "react-google-recaptcha";

import { getBaseSettingAsync } from '../../reducers/setting/settingSlice'
import { accountPayload } from '../../reducers/account/accountAPI'
import { useState, useEffect, useRef } from 'react';
const { Option } = Select
const SignIn = () => {
  let siteKey = '6LcxbsIdAAAAAAzKDEM6QpIVAC1RzCUY9ahYeqoo'
  const dispatch = useAppDispatch();
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const recaptchaRef = useRef<any>()
  const [dbs, setDbs] = useState([])
  const { t, i18n } = useTranslation()

  const onFinish = async (values: accountPayload) => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    values['g-recaptcha-response'] = recaptchaValue
    setLoading(true)
    const result = await dispatch(forgotPasswordAsync({
      ...values,
    }))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { data, code, msg } = payload
      if (code === 0) {
        setSent(true)
      } else if (msg) {
        Modal.error({
          title: 'Error',
          content: msg,
        });
      }
    }
    if (typeof payload === 'string') {
      Modal.error({
        title: 'Error',
        content: payload
      })
    }
  }
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const fetchSetting = async () => {
    setLoading(true)
    const result = await dispatch(getBaseSettingAsync({}))
    setLoading(false)
    const { payload } = result
    if (payload) {
      const { code, data, msg } = payload
      if (code === 0) {
        setDbs(data.dburls)
      } else if (msg) {
        message.error(msg)
      }
    }
    if (typeof payload === 'string') {
      message.error(payload)
    }
  };
  useEffect(() => {
    fetchSetting()
  }, [])
  return (
    <div className='portal-signin'>
      {
        !sent ?
          <Form
            className="signin-form"
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="logo-box">
              {/* <img src={logo} className="App-logo" alt="logo" /> */}
              <h1>
                {
                  t('work.Forgot_password')
                }
              </h1>
            </div>
            <Form.Item
              className="sign-input"
              label={t('work.Country')}
              name="db"
              rules={[{ required: true }]}
            >
              <Select placeholder="" >
                {
                  dbs.map((co: any) => {
                    return (
                      <Option key={co.key} value={co.key}>{co.name}</Option>)
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              className="sign-input"
              label={t('work.Email')}
              name="email"
              rules={[{ required: true, message: t('sign.Please_input_your_email') }, {
                type: 'email', message: t('work.EmailType')
              }]}
            >
              <Input />
            </Form.Item>
            {
              process.env.NODE_ENV === 'production' && !loading ? (
                <Form.Item
                  className="sign-input sign-captch"
                  label="Captcha"
                  // name="captcha"
                  rules={[{ required: true, message: 'Please input captcha!' }]}
                >
                  <ReCAPTCHA
                    sitekey={siteKey}
                    ref={recaptchaRef}
                  />
                </Form.Item>) : ''
            }

            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button className="sign-submit" loading={loading} type="primary" htmlType="submit">
                {t('work.Submit')}
              </Button>
            </Form.Item>
          </Form> :
          <Result
            status="success"
            title={t('work.MailSentText')}
            subTitle={
              [<span>
              </span>]
            }
            extra={[
            ]}
          />
      }

    </div>
  )
}

export default SignIn;
